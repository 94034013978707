<template>
	<div>
		 <v-app id="inspire" v-if="imagenesArtistas != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-star"></i><strong> Listado de</strong> Imagenes por artista</h2>
                            <div class="text-right">
                            <button @click="setNullImagenArtista" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalImagenArtista">NUEVO REGISTRO</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="imagenesArtistas" :fields="json_fields" worksheet="Listado de imagenes por artista" name="imagenesArtistas.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='imagenesArtistas'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado imagenes por artista...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id_artista }}</td>
      <td class="text-xs-center">{{ props.item.artist.nombre }}</td>
      <td class="text-xs-center"><img :title="props.item.imagen.nombre" :src="props.item.imagen.url" width="80px" class="img-table-artista"></td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewImagenArtista(props.item.id_artista,props.item.id_imagen)"
           data-toggle="modal" data-target="#modalImagenArtista">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteImagenArtista(props.item.id_artista)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalImagenArtista" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalImagenArtista }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                          <label for="artistaImagen" v-if="artistaImagen != null">Artista</label>
                          <div class="form-group" v-if="artistaImagen != null">
                            <div class="form-line">
                                <input type="text" id="artistaImagen" class="form-control"
                                    placeholder="Artista" :value="artistaImagen">
                            </div>
                        </div>
                        <div class="form-group" v-if="artistaImagen == null">
                            <div class="form-line">
                                <input type="text" id="searchArtistas" class="form-control"
                                    placeholder="Buscar artistas..." v-model="keyArtistas" @keyup.enter="searchArtistas">
                            </div>
                        </div>
                        <div class="form-group" v-if="((artistas && artistas.length > 0) && artistaImagen == null)">
                        <v-select
                          :items="artistas"
                           item-text="nombre"
                           item-value="id"
                           v-model="artista"
                          label="Seleccione un artista"
                          @change="searchImagenesArtista(artista)"
                        ></v-select>
                    </div>
                    <template v-if="artistaImagen != null">
                        <div class="form-group text-center">
                        <h5>Imagen actual: {{ imagenActual.nombre }}</h5>
                            <img :src="imagenActual.url" width="300px" class="imagenActual" :title="imagenActual.nombre">
                        </div>
                    </template>
                    <template v-if="((artista != null || artistaImagen != null) && imagenesArtista != null)">
                    <h4 v-if="artistaImagen == null">Añadir una imagen al artista</h4>
                    <h4 v-if="artistaImagen != null">Cambiar esta imagen del artista</h4>
                    <Imagenes :imagenesActivas="imagenesArtista"></Imagenes>
                </template>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info waves-effect" @click="saveImagenArtista">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

import Imagenes from './utils/Imagenes.vue';

	export default {
        components:{
            Imagenes,
        },
        beforeCreate(){
           this.$store.dispatch('imagenartista/listImagenesArtistas', 1);
        },
		created(){
           document.title = 'Imagenes por artista - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('imagenartista/listImagenesArtistas', 1);
        },
        data(){
        	return {
        		titleModalImagenArtista: null,
        		search: '',
                artistaImagen: null,
                imagenActual: null,
                artista: null,
                keyArtistas: null,
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id_artista'
		          },
		          {text: 'Artista', value: 'artist.nombre',align: 'center' },
                  {text: 'Imagen', value: 'imagen.nombre',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id_artista',
            'Artista': 'artist.nombre',
            'Imagen': 'imagen.nombre',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('imagenartista/listImagenesArtistas', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	imagenesArtistas(){
        		return this.$store.getters['imagenartista/listImagenesArtistas'];
        	},
        	imagenartista(){
        		return this.$store.getters['imagenartista/imagenartista'];
        	},
		    pagination(){
        		return this.$store.getters['imagenartista/pagination'];
		      },
		     pages () {
		        return this.$store.getters['imagenartista/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['imagenartista/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['imagenartista/loading'];
		      },
               artistas(){
                return this.$store.getters['artista/listArtistas'];
              },
               imagenesArtista(){
                return this.$store.getters['imagenartista/imagenesArtista'];
              },
        },
        methods: {
        	deleteImagenArtista(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar el registro?",
			        text: "El registro se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarlo!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('imagenartista/deleteImagenArtista',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	saveImagenArtista(){
        		if((this.artistaImagen != null || this.artista != null) &&
                    ($("input[name='imagen-select']:checked").val() != undefined && $("input[name='imagen-select']:checked").val() != "" && $("input[name='imagen-select']:checked").val() != null)){ 
        		if(this.imagenartista){
                    var imagenUpdate = null;
                    if($("input[name='imagen-select']:checked").val() != undefined && $("input[name='imagen-select']:checked").val() != "" && $("input[name='imagen-select']:checked").val() != null){
                        imagenUpdate = $("input[name='imagen-select']:checked").val();
                    }else{
                        imagenUpdate = this.imagenartista.id_imagen;
                    }
        			this.$store.dispatch('imagenartista/updateImagenArtista',
                    {
                        id: this.imagenartista.id_artista,
                        id_imagen_old: this.imagenartista.id_imagen,
                        id_imagen_new: imagenUpdate
                    })
        			//console.log('estamos editando un imagenartista');
        			$('#modalImagenArtista').modal('hide');
        		}else{	
        			this.$store.dispatch('imagenartista/newImagenArtista',
                        {
                            id_artista: this.artista,
                            id_imagen: $("input[name='imagen-select']:checked").val()
                        });
        			//console.log('estamos guardando un imagenartista');
        			$('#modalImagenArtista').modal('hide');
        		}
        	}else{
                swal("Error", "Selecciona una imagen!", "error");
            }
        		
        	},
        	viewImagenArtista(id_artista,id_imagen){
                this.searchImagenesArtista(id_artista);
        	    this.$store.commit('imagenartista/viewImagenArtista',{id_artista,id_imagen});
        	    this.artistaImagen = this.imagenartista.artist.nombre;
                this.imagenActual = this.imagenartista.imagen;
                this.artista = null;
                this.keyArtistas = null;
        	    this.titleModalImagenArtista = `Editar Registro:`;
        	},
        	setNullImagenArtista(){
        		this.$store.commit('imagenartista/viewImagenArtista',{id_artista:null,id_imagen:null});
        		this.artistaImagen = null;
                this.imagenActual = null;
                this.artista = null;
                this.keyArtistas = null;
        		this.titleModalImagenArtista = 'Nuevo Registro';
        	},
	         changePage(page){
    		  this.$store.commit('imagenartista/changePage',page)
	          this.$store.dispatch('imagenartista/listImagenesArtistas',page);
	        },
            searchArtistas(){
                this.$store.dispatch('artista/searchArtistas', this.keyArtistas);
            },
            searchImagenesArtista(artista){
                this.$store.dispatch('imagenartista/searchImagenesArtista',artista);
            }
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
img.imagenActual {
    box-shadow: 0px 0px 10px #d0d0d0;
    border-radius: 10px;
    max-width: 200px;
}

</style>