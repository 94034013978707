<template>
    <div>
         <v-app id="inspire" v-if="usuarios != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-users"></i><strong> Listado de</strong> Usuarios</h2>
                            <div class="text-right">
                            <button @click="setNullUser" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalUser">NUEVO USUARIO</button>
                        </div>
                        <div>
                             <export-excel style="width: fit-content;" :data="usuarios" :fields="json_fields" worksheet="Listado de usuarios" name="usuarios.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
              multi-sort
              :sort-by="['tipo_identificacion', 'identificacion','nombre','telefono','email','rol','direccion','ciudad','departamento','ciudad']"
            :items='usuarios'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado usuarios...'"
            >
    <template v-slot:items="props">
      <td class="text-xs-center"><span v-if="props.item.tipo_identificacion_all">{{ props.item.tipo_identificacion_all.descripcion }}</span><span v-else></span></td>
      <td class="text-xs-center">{{ props.item.identificacion }}</td>
      <td class="text-xs-center">{{ props.item.nombre }}</td>
      <td class="text-xs-center">{{ props.item.telefono }}</td>
      <td class="text-xs-center">{{ props.item.email }}</td>
      <td class="text-xs-center"><span v-if="props.item.rol">{{ props.item.rol.nombre }}</span> <span v-else>Ninguno</span></td>
      <td class="text-xs-center">{{ props.item.direccion }}</td>
      <td class="text-xs-center">{{ props.item.ciudad }}</td>
      <td class="text-xs-center">{{ props.item.departamento }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewUser(props.item.email)"
           data-toggle="modal" data-target="#modalUser">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteUser(props.item.email)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade bd-example-modal-lg" id="modalUser" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="saveUser">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titlemodalUser }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <v-divider></v-divider>
                <div class="modal-body row">
                    <div class="col-md-8 col-lg-8 col-sm-8 col-xs-12">
                    <label for="nombre">Nombre</label>
                    <div class="form-group">
                        <div class="form-line">
                            <input type="text" id="nombre" class="form-control"
                                placeholder="Nombre" required="" v-model="nombre">
                        </div>
                    </div>
                </div>
                   <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                    <v-select
                    v-model="rol"
                    item-value="id"
                    item-text="nombre"
                    :items="roles"
                    label="Rol del usuario"
                    prepend-icon="person"
                    persistent-hint
                    hint="Rol del usuario"
                    required
                  ></v-select>
                </div>
                <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                  <v-select
                    v-model="tipoIdentificacion"
                    item-value="id"
                    item-text="code"
                    :items="tipoIdentificaciones"
                    label="Tipo de identificacion (*)"
                    prepend-icon="person"
                    persistent-hint
                    :hint="descripcionTipoIdentificacion"
                    required
                  ></v-select>
             </div>
             <div class="col-md-8 col-lg-8 col-sm-8 col-xs-12">
               <label for="identificacion">Identificacion</label>
                    <div class="form-group">
                        <div class="form-line">
                            <input type="text" id="identificacion" class="form-control"
                                placeholder="Identificacion" required="" v-model="identificacion">
                        </div>
                    </div>
             </div>
                 <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                    <label for="email">Correo electronico</label>
                    <div class="form-group">
                        <div class="form-line">
                            <input type="email" id="email" class="form-control"
                                placeholder="Correo electronico" :readonly="usuario" v-model="email">
                        </div>
                    </div>
                </div>
                    <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                    <label for="telefono">Telefono</label>
                    <div class="form-group">
                        <div class="form-line">
                            <input type="tel" id="telefono" class="form-control"
                                placeholder="Telefono" v-model="telefono">
                        </div>
                    </div>
                </div>
                  <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                    <label for="departamento">Departamento</label>
                    <div class="form-group">
                        <div class="form-line">
                            <input type="text" id="departamento" class="form-control"
                                placeholder="Departamento" v-model="departamento">
                        </div>
                    </div>
                </div>
                   <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                    <label for="ciudad">Ciudad</label>
                    <div class="form-group">
                        <div class="form-line">
                            <input type="text" id="ciudad" class="form-control"
                                placeholder="Ciudad" v-model="ciudad">
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12">
                    <label for="direccion">Direccion</label>
                    <div class="form-group">
                        <div class="form-line">
                            <input type="text" id="direccion" class="form-control"
                                placeholder="Direccion" v-model="direccion">
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12" v-if="!usuario">
                    <label for="password">Contraseña</label>
                    <div class="form-group">
                        <div class="form-line">
                            <input type="password" id="password" class="form-control"
                                placeholder="Contraseña" v-model="password">
                        </div>
                    </div>
                </div>
                  <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12" v-if="!usuario">
                    <label for="c_password">Confirmar contraseña</label>
                    <div class="form-group">
                        <div class="form-line">
                            <input type="password" id="c_password" class="form-control"
                                placeholder="Confirmar contraseña" v-model="c_password">
                        </div>
                    </div>
                </div>
            </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-info waves-effect">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
    </div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

    export default {
        async beforeCreate(){
           await this.$store.dispatch('user/listTipoIdentificaciones');
           await this.$store.dispatch('user/listRoles');
           await this.$store.dispatch('user/listUsuarios', 1);
        },
        created(){
           document.title = 'Usuarios - TicketShop';
        },
        async mounted(){
           //adminApp() 
           this.$store.dispatch('user/listUsuarios', 1);
        },
        data(){
            return {
                titlemodalUser: null,
                nombre: null,
                identificacion: null,
                tipoIdentificacion: 1,
                direccion: null,
                email: null,
                telefono: null,
                departamento: null,
                ciudad: null,
                rol: 1,
                password: null,
                c_password: null,
                search: '',
                emailRules: [
                  v => /.+@.+/.test(v) || 'Direccion de correo electronico incorrecta'
                ],
                headers: [
                  {
                    text: 'Tipo Identificacion',
                    align: 'left',
                    sortable: true,
                    value: 'tipo_identificacion_all.descripcion'
                  },
                  {text: 'Identificacion', value: 'identificacion',align: 'center' ,sortable: true},
                  {text: 'Nombre', value: 'nombre',align: 'center' },
                  {text: 'Telefono', value: 'telefono',align: 'center' },
                  {text: 'Email', value: 'email',align: 'center' },
                  {text: 'Rol', value: 'rol.nombre',align: 'center' },
                  {text: 'Direccion', value: 'direccion',align: 'center' },
                  {text: 'Ciudad', value: 'ciudad',align: 'center' },
                  {text: 'Departamento', value: 'departamento',align: 'center' },
                  {text: 'Acciones',value: '', align: 'right'}
             ],
             json_fields: {
            'Tipo Identificacion': 'tipo_identificacion_all.descripcion',
            'Identificacion': 'identificacion',
            'Nombre': 'nombre',
            'Telefono': 'telefono',
            'Email': 'email',
            'Direccion': 'direccion',
            'Ciudad': 'ciudad',
            'Departamento': 'departamento',
            'Rol': 'rol.nombre',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
            }
        },
        watch: {
            pagination: {
              async handler () {
                this.$store.dispatch('user/listUsuarios', this.pagination.page);
              },
              deep: true
            }
          },
        computed: {
            usuarios(){
                return this.$store.getters['user/listUsuarios'];
            },
            roles(){
                return this.$store.getters['user/listRoles'];
            },
            usuario(){
                return this.$store.getters['user/usuario'];
            },
            pagination(){
                return this.$store.getters['user/pagination'];
              },
             pages () {
                return this.$store.getters['user/pages'];
              },
              totalItemCount(){
                return this.$store.getters['user/totalItemCount'];
              },
              loading(){
                    return this.$store.getters['user/loading'];
              },
              tipoIdentificaciones(){
                return this.$store.getters['user/listTipoIdentificaciones'];
              },
              descripcionTipoIdentificacion(){
                if(this.usuario){
                var descripcion = this.tipoIdentificaciones.filter((item) => item.id === this.usuario.tipo_identificacion)[0];
                if(descripcion){
                  return descripcion.descripcion;
                }else{
                  return 'Ninguna';
                }
              }
              }
        },
        methods: {
            deleteUser(email){
                var self = this;
                    swal({
                    title: "¿Estas seguro de eliminar el usuario?",
                    text: "El usuario se eliminara permanentemente!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Si, Eliminarlo!",
                    cancelButtonText: "No, cancelar!",
                    closeOnConfirm: false,
                    closeOnCancel: false
                }, function (isConfirm) {
                    if (isConfirm) {
                        self.$store.dispatch('user/deleteUser',email);
                    } else {
                        swal("Cancelado", "Se ha cancelado la operacion.", "error");
                    }
                });
            },
            saveUser(){
                if(this.nombre != '' || this.nombre != null &&
                    this.identificacion != '' || this.identificacion != null &&
                    this.tipoIdentificacion != '' || this.tipoIdentificacion != null &&
                    this.rol != '' || this.rol != null &&
                    this.email != '' || this.email != null){ 
                if(this.usuario){
                    this.$store.dispatch('user/updateUser',{nombre: this.nombre,email: this.email, identificacion: this.identificacion, tipo_identificacion: this.tipoIdentificacion, ciudad: this.ciudad, departamento: this.departamento, rol: this.rol, direccion: this.direccion, telefono: this.telefono})
                    //console.log('estamos editando un user');
                    $('#modalUser').modal('hide');
                }else{  
                  if(this.password === this.c_password){ 
                    this.$store.dispatch('user/newUser',{nombre: this.nombre, email: this.email, identificacion: this.identificacion, tipo_identificacion: this.tipoIdentificacion, ciudad: this.ciudad, departamento: this.departamento, id_rol: this.rol, direccion: this.direccion, telefono: this.telefono,password: this.password,c_password: this.c_password});
                    //console.log('estamos guardando un user');
                    $('#modalUser').modal('hide');
              }else{
                swal("Error!","Las contraseñas deben ser iguales!", "error");
              }
                }
            }
                
            },
            viewUser(email){
                this.$store.commit('user/viewUsuario',email);
                this.nombre = this.usuario.nombre;
                this.identificacion = this.usuario.identificacion;
                this.tipoIdentificacion = this.usuario.tipo_identificacion;
                this.ciudad = this.usuario.ciudad;
                this.departamento = this.usuario.departamento;
                this.rol = this.usuario.id_rol;
                this.direccion = this.usuario.direccion;
                this.email = this.usuario.email;
                this.telefono = this.usuario.telefono;
                this.titlemodalUser = `Editar Usuario: ${this.usuario.nombre}`;
            },
            setNullUser(){
                this.$store.commit('user/viewUsuario',null);
                this.nombre = null;
                this.identificacion = null;
                this.tipoIdentificacion = 1;
                this.ciudad = null;
                this.departamento = null;
                this.rol = 1;
                this.email = null;
                this.direccion = null;
                this.telefono = null;
                this.titlemodalUser = 'Nuevo Usuario';
            },
             changePage(page){
              this.$store.commit('user/changePage',page)
              this.$store.dispatch('user/listUsuarios',page);
            },
        }
    };
</script>
<style scoped>
.changes{
    margin-right: 5px;
    margin-bottom: 12px;
}
</style>