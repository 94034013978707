import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       puntosEventos: [],
       puntoEvento: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listPuntosEventos: (state,getters,rootState) => {
          return state.puntosEventos;
    },
    puntoEvento: (state,getters,rootState) => {
        return state.puntoEvento;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listPuntosEventos: (state,puntosEventos) => {
          state.puntosEventos = puntosEventos.data;
          state.pagination.totalItems = puntosEventos.total;
          state.pagination.page = puntosEventos.current_page;
          state.pagination.rowsPerPage = puntosEventos.per_page;
       },
      viewPuntoEvento(state,payload){
        let auxPuntoEvento = state.puntosEventos.filter((item) => 
          (item.id_evento == payload.id_evento && item.id_puntoventa == payload.id_puntoventa));
        state.puntoEvento = auxPuntoEvento[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listPuntosEventos({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}puntoventaevento?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listPuntosEventos',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los puntos de venta por evento!", "error");
        console.error(error);
      });

    },
  newPuntoEvento({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}puntoventaevento`,
        {
          id_evento: payload.id_evento,
          id_puntoventa: payload.id_puntoventa,
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo el punto por evento correctamente!", "success");
        store.dispatch('puntoevento/listPuntosEventos',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear el punto por evento!", "error");
        console.error(error);
      });
  },
  updatePuntoEvento({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}puntoventaevento/${payload.id}`,
        {
          id_puntoventa_old: payload.id_puntoventa_old,
          id_puntoventa_new: payload.id_puntoventa_new,
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo el punto por evento correctamente!", "success");
        store.dispatch('puntoevento/listPuntosEventos',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar el punto por evento!", "error");
        console.error(error);
      });
  },
  deletePuntoEvento({state,getters,commit,rootState},puntoEvento){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}puntoventaevento/${puntoEvento}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino el punto por evento correctamente!", "success");
        store.dispatch('puntoevento/listPuntosEventos',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar el punto por evento!","error");
        console.log(error);
     });
  },
  },
}