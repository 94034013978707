import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       tasas: [],
       tasa: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listTasas: (state,getters,rootState) => {
          return state.tasas;
    },
    tasa: (state,getters,rootState) => {
        return state.tasa;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listTasas: (state,tasas) => {
          state.tasas = tasas.data;
          state.pagination.totalItems = tasas.total;
          state.pagination.page = tasas.current_page;
          state.pagination.rowsPerPage = tasas.per_page;
       },
      listTasasSearch: (state,tasas) => {
            if(tasas != null){ 
                state.tasas = tasas;
            }else{
              state.tasas = null;
            }
       },
      viewTasa(state,id){
        let auxTasa = state.tasas.filter((item) => item.id == id);
        state.tasa = auxTasa[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listTasas({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}tasa?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listTasas',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener las tasas!", "error");
        console.error(error);
      });

    },
  newTasa({state,getters,commit,rootState},tasa){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}tasa`,
        {
            codigo_moneda_alta: tasa.codigo_moneda_alta,
            codigo_moneda_baja: tasa.codigo_moneda_baja,
            activo: tasa.activo,
            valor: tasa.valor
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo la tasa correctamente!", "success");
        store.dispatch('tasa/listTasas',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear la tasa!", "error");
        console.error(error);
      });
  },
  updateTasa({state,getters,commit,rootState},tasa){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}tasa/${tasa.id}`,
        {
            codigo_moneda_alta: tasa.codigo_moneda_alta,
            codigo_moneda_baja: tasa.codigo_moneda_baja,
            activo: tasa.activo,
            valor: tasa.valor
        },
        {headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo la tasa correctamente!", "success");
        store.dispatch('tasa/listTasas',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar la tasa!", "error");
        console.error(error);
      });
  },
  deleteTasa({state,getters,commit,rootState},tasa){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}tasa/${tasa}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino la tasa correctamente!", "success");
        store.dispatch('tasa/listTasas',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar la tasa!","error");
        console.log(error);
     });
  },
    searchTasas({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarTasa?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listTasasSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener las tasas!", "error");
        console.error(error);
      });

    },
  },
}