<template>
	<div>
		 <v-app id="inspire" v-if="localidades != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-directions"></i><strong> Listado de</strong> Localidades</h2>
                            <div class="text-right">
                            <button @click="setNullLocalidad" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalLocalidad">NUEVA LOCALIDAD</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="localidades" :fields="json_fields" worksheet="Listado de localidades" name="localidades.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='localidades'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado localidades...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td class="text-xs-center">{{ props.item.nombre }}</td>
      <td class="text-xs-center">{{ props.item.tribuna.nombre }}</td>
      <td class="text-xs-center">{{ props.item.puerta_acceso }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewLocalidad(props.item.id)"
           data-toggle="modal" data-target="#modalLocalidad">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteLocalidad(props.item.id)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade bd-example-modal-lg" id="modalLocalidad" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalLocalidad }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                         <label for="nameLocalidad">Nombre</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="nameLocalidad" class="form-control"
                                    placeholder="Nombre de la localidad" required="" v-model="nameLocalidad">
                            </div>
                        </div>
                           <label for="puertaAcceso">Puerta acceso</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="puertaAcceso" class="form-control"
                                    placeholder="Puerta de acceso" required="" v-model="puertaAcceso">
                            </div>
                        </div>
                      <label for="tribunaLocalidad" v-if="tribunaLocalidad != null">Tribuna</label>
                          <div class="form-group" v-if="tribunaLocalidad != null">
                            <div class="form-line">
                                <input type="text" id="tribunaLocalidad" class="form-control"
                                    placeholder="Tribuna de la localidad" :value="tribunaLocalidad">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchTribunas" class="form-control"
                                    placeholder="Buscar tribunas..." v-model="keyTribunas" @keyup.enter="searchTribunas">
                            </div>
                        </div>
                        <div class="form-group" v-if="(tribunas && tribunas.length > 0)">
                        <v-select
                          :items="tribunas"
                           item-text="nombre"
                           item-value="id"
                           v-model="tribuna"
                          label="Seleccione una tribuna"
                        ></v-select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" v-if="(nameLocalidad != null && puertaAcceso != null && (tribuna != null || tribunaLocalidad != null))" class="btn btn-info waves-effect" @click="saveLocalidad">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('localidad/listLocalidades', 1);
        },
		created(){
           document.title = 'Localidades - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('localidad/listLocalidades', 1);
        },
        data(){
        	return {
        		titleModalLocalidad: null,
        		nameLocalidad: null,
                tribunaLocalidad: null,
                puertaAcceso: null,
                tribuna: null,
                keyTribunas: null,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id'
		          },
		          {text: 'Nombre', value: 'nombre',align: 'center' },
                  {text: 'Tribuna', value: 'tribuna.nombre',align: 'center' },
                  {text: 'Puerta acceso', value: 'puerta_acceso',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id',
            'Nombre': 'nombre',
            'Tribuna': 'tribuna.nombre',
            'Puerta acceso': 'puerta_acceso',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('localidad/listLocalidades', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	localidades(){
        		return this.$store.getters['localidad/listLocalidades'];
        	},
        	localidad(){
        		return this.$store.getters['localidad/localidad'];
        	},
		    pagination(){
        		return this.$store.getters['localidad/pagination'];
		      },
		     pages () {
		        return this.$store.getters['localidad/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['localidad/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['localidad/loading'];
		      },
               tribunas(){
                return this.$store.getters['tribuna/listTribunas'];
              },
        },
        methods: {
        	deleteLocalidad(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar la localidad?",
			        text: "La localidad se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarla!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('localidad/deleteLocalidad',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	saveLocalidad(){
        		if(this.nameLocalidad != '' || this.nameLocalidad != null && 
                    ((this.tribuna != '' || this.tribuna != null) ||
                     (this.tribunaLocalidad != '' || this.tribunaLocalidad != null)) &&
                    this.puertaAcceso != '' || this.puertaAcceso != null){ 
        		if(this.localidad){
                    var tribunaUpdate = null;
                    if(this.tribuna != null || this.tribuna != ''){
                        tribunaUpdate = this.tribuna;
                    }else{
                        tribunaUpdate = this.localidad.id_tribuna;
                    }
        			this.$store.dispatch('localidad/updateLocalidad',{id: this.localidad.id,nombre: this.nameLocalidad, id_tribuna: tribunaUpdate, puerta_acceso: this.puertaAcceso})
        			//console.log('estamos editando un localidad');
        			$('#modalLocalidad').modal('hide');
        		}else{	
        			this.$store.dispatch('localidad/newLocalidad',{nombre: this.nameLocalidad, id_tribuna: this.tribuna,puerta_acceso: this.puertaAcceso});
        			//console.log('estamos guardando un localidad');
        			$('#modalLocalidad').modal('hide');
        		}
        	}
        		
        	},
        	viewLocalidad(id){
        	    this.$store.commit('localidad/viewLocalidad',id);
        	    this.nameLocalidad = this.localidad.nombre;
                this.tribunaLocalidad = this.localidad.tribuna.nombre;
                this.puertaAcceso = this.localidad.puerta_acceso;
        	    this.titleModalLocalidad = `Editar Localidad: ${this.localidad.nombre}`;
        	},
        	setNullLocalidad(){
        		this.$store.commit('localidad/viewLocalidad',null);
        		this.nameLocalidad = null;
                this.tribunaLocalidad = null;
                this.keyTribunas = null;
                this.tribuna = null;
                this.puertaAcceso = null;
        		this.titleModalLocalidad = 'Nueva Localidad';
        	},
	         changePage(page){
    		  this.$store.commit('localidad/changePage',page)
	          this.$store.dispatch('localidad/listLocalidades',page);
	        },
            searchTribunas(){
                this.$store.dispatch('tribuna/searchTribunas', this.keyTribunas);
            },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>