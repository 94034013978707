<template>
	<div>
		 <v-app id="inspire" v-if="costosEventos != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-dollar-sign"></i><strong> Listado de</strong> Costos por Eventos</h2>
                            <div class="text-right">
                            <button @click="setNullCostoEvento" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalCostoEvento">NUEVO COSTO EVENTO</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="costosEventos" :fields="json_fields" worksheet="Listado de costos por eventos" name="costosEventos.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='costosEventos'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado costos por eventos...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td class="text-xs-center">{{ props.item.descripcion }}</td>
      <td class="text-xs-center">{{ props.item.valor }}</td>
      <td class="text-xs-center">{{ props.item.evento.nombre }}</td>
      <td class="text-xs-center">{{ props.item.tipo_costo.descripcion }}</td>
      <td class="text-xs-center">{{ props.item.codigo_moneda.codigo_moneda }} - {{ props.item.codigo_moneda.descripcion }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewCostoEvento(props.item.id)"
           data-toggle="modal" data-target="#modalCostoEvento">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteCostoEvento(props.item.id)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalCostoEvento" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalCostoEvento }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                    <div class="col-lg-6 col-md-6">
                        <label for="descripcionCostoEvento">Descripcion</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="descripcionCostoEvento" class="form-control"
                                    placeholder="Descripcion" required="" v-model="descripcionCostoEvento">
                            </div>
                        </div>
                    </div>
                     <div class="col-lg-6 col-md-6">
                        <label for="valorCostoEvento">Valor</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" step="any" id="valorCostoEvento" class="form-control"
                                    placeholder="Valor" required="" v-model="valorCostoEvento">
                            </div>
                        </div>
                    </div>
                        <div class="col-lg-12 col-md-12">
                              <label for="eventoActual" v-if="eventoActual != null">Evento actual</label>
                          <div class="form-group" v-if="eventoActual != null">
                            <div class="form-line">
                                <input type="text" id="eventoActual" class="form-control"
                                    placeholder="Evento actual" :value="eventoActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchEventos" class="form-control"
                                    placeholder="Buscar eventos..." v-model="keyEventos" @keyup.enter="searchEventos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(eventos && eventos.length > 0)">
                        <v-select
                          :items="eventos"
                           item-text="nombre"
                           item-value="id"
                           v-model="evento"
                          label="Seleccione un evento"
                        ></v-select>
                    </div>
                       </div>
                       <div class="col-md-12 col-lg-12">
                            <label for="tipoCostoActual" v-if="tipoCostoActual != null">Tipo costo actual</label>
                          <div class="form-group" v-if="tipoCostoActual != null">
                            <div class="form-line">
                                <input type="text" id="tipoCostoActual" class="form-control"
                                    placeholder="Tipo costo actual" :value="tipoCostoActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchTiposCosto" class="form-control"
                                    placeholder="Buscar tipos de costo..." v-model="keyTiposCosto" @keyup.enter="searchTiposCosto">
                            </div>
                        </div>
                        <div class="form-group" v-if="(tiposCosto && tiposCosto.length > 0)">
                        <v-select
                          :items="tiposCosto"
                           item-text="descripcion"
                           item-value="id"
                           v-model="tipoCosto"
                          label="Seleccione un tipo de costo"
                        ></v-select>
                    </div>
                       </div>
                       <div class="col-md-12 col-lg-12">
                            <label for="monedaActual" v-if="monedaActual != null">Moneda actual</label>
                          <div class="form-group" v-if="monedaActual != null">
                            <div class="form-line">
                                <input type="text" id="monedaActual" class="form-control"
                                    placeholder="Moneda actual" :value="monedaActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchMonedas" class="form-control"
                                    placeholder="Buscar monedas..." v-model="keyMonedas" @keyup.enter="searchMonedas">
                            </div>
                        </div>
                        <div class="form-group" v-if="(monedas && monedas.length > 0)">
                        <v-select
                          :items="monedas"
                           item-text="descripcion"
                           item-value="codigo_moneda"
                           v-model="moneda"
                          label="Seleccione una moneda"
                        ></v-select>
                    </div>
                       </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info waves-effect" @click="saveCostoEvento">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('costoevento/listCostosEventos', 1);
        },
		created(){
           document.title = 'Costo por evento - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('costoevento/listCostosEventos', 1);
        },
        data(){
        	return {
        		titleModalCostoEvento: null,
        		descripcionCostoEvento: null,
                valorCostoEvento: null,
                eventoActual: null,
                keyEventos: null,
                evento: null,
                tipoCostoActual: null,
                keyTiposCosto: null,
                tipoCosto: null,
                monedaActual: null,
                keyMonedas: null,
                moneda: null,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id'
		          },
		          {text: 'Descripcion', value: 'descripcion',align: 'center' },
                  {text: 'Valor', value: 'valor',align: 'center' },
                  {text: 'Evento', value: 'evento.nombre',align: 'center' },
                  {text: 'Tipo de costo', value: 'tipo_costo.descripcion',align: 'center' },
                  {text: 'Moneda', value: 'codigo_moneda.codigo_moneda',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id',
            'Descripcion': 'descripcion',
            'Valor': 'valor',
            'Evento': 'evento.nombre',
            'Tipo de costo': 'tipo_costo.descripcion',
            'Moneda': 'codigo_moneda.codigo_moneda',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('costoevento/listCostosEventos', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	costosEventos(){
        		return this.$store.getters['costoevento/listCostosEventos'];
        	},
        	costoevento(){
        		return this.$store.getters['costoevento/costoevento'];
        	},
		    pagination(){
        		return this.$store.getters['costoevento/pagination'];
		      },
		     pages () {
		        return this.$store.getters['costoevento/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['costoevento/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['costoevento/loading'];
		      },
                 eventos(){
                return this.$store.getters['evento/listEventos'];
              },
                tiposCosto(){
                return this.$store.getters['tipocosto/listTiposCosto'];
              },
                monedas(){
                return this.$store.getters['moneda/listMonedas'];
              },
        },
        methods: {
        	deleteCostoEvento(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar el costo por evento?",
			        text: "El costo por evento se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarlo!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('costoevento/deleteCostoEvento',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	saveCostoEvento(){
        		if(this.descripcionCostoEvento != '' || this.descripcionCostoEvento != null &&
                    this.valorCostoEvento != '' || this.valorCostoEvento != null &&
                    (this.eventoActual != null || this.evento != null) &&
                    (this.tipoCostoActual != null || this.tipoCosto != null) &&
                    (this.monedaActual != null || this.moneda != null)){ 
        		if(this.costoevento){
                        var eventoUpdate = null;
                    if(this.evento != null && this.evento != ''){
                        eventoUpdate = this.evento;
                    }else{
                        eventoUpdate = this.costoevento.id_evento;
                    }
                       var tipoCostoUpdate = null;
                    if(this.tipoCosto != null && this.tipoCosto != ''){
                        tipoCostoUpdate = this.tipoCosto;
                    }else{
                        tipoCostoUpdate = this.costoevento.id_tipo_costo;
                    }
                      var monedaUpdate = null;
                    if(this.moneda != null && this.moneda != ''){
                        monedaUpdate = this.moneda;
                    }else{
                        monedaUpdate = this.costoevento.codigo_moneda.codigo_moneda;
                    }
        			this.$store.dispatch('costoevento/updateCostoEvento',
                    {
                        id: this.costoevento.id,
                        descripcion: this.descripcionCostoEvento,
                        valor: this.valorCostoEvento,
                        id_evento: eventoUpdate,
                        id_tipo_costo: tipoCostoUpdate,
                        codigo_moneda: monedaUpdate
                    });
        			//console.log('estamos editando un costoevento');
        			$('#modalCostoEvento').modal('hide');
        		}else{	
        			this.$store.dispatch('costoevento/newCostoEvento',
                        {
                            descripcion: this.descripcionCostoEvento,
                            valor: this.valorCostoEvento,
                            id_evento: this.evento,
                            id_tipo_costo: this.tipoCosto,
                            codigo_moneda: this.moneda
                        });
        			//console.log('estamos guardando un costoevento');
        			$('#modalCostoEvento').modal('hide');
        		}
        	}
        		
        	},
        	viewCostoEvento(id){
        	    this.$store.commit('costoevento/viewCostoEvento',id);
        	    this.descripcionCostoEvento = this.costoevento.descripcion;
                this.valorCostoEvento = this.costoevento.valor;
                this.eventoActual = this.costoevento.evento.nombre;
                this.keyEventos = null;
                this.evento = null;
                this.tipoCostoActual = this.costoevento.tipo_costo.descripcion;
                this.keyTiposCosto = null;
                this.tipoCosto = null;
                this.monedaActual = `${this.costoevento.codigo_moneda.codigo_moneda} ${this.costoevento.codigo_moneda.descripcion}`;
                this.keyMonedas = null;
                this.moneda = null;
        	    this.titleModalCostoEvento = `Editar Costo Evento: ${this.costoevento.descripcion}`;
        	},
        	setNullCostoEvento(){
        		this.$store.commit('costoevento/viewCostoEvento',null);
        		this.descripcionCostoEvento = null;
                this.valorCostoEvento = null;
                this.eventoActual = null;
                this.keyEventos = null;
                this.evento = null;
                this.tipoCostoActual = null;
                this.keyTiposCosto = null;
                this.tipoCosto = null;
                this.monedaActual = null;
                this.keyMonedas = null;
                this.moneda = null;
        		this.titleModalCostoEvento = 'Nuevo Costo Evento';
        	},
	         changePage(page){
    		  this.$store.commit('costoevento/changePage',page)
	          this.$store.dispatch('costoevento/listCostosEventos',page);
	        },
            searchEventos(){
                this.$store.dispatch('evento/searchEventos', this.keyEventos);
            },
             searchTiposCosto(){
                this.$store.dispatch('tipocosto/searchTiposCosto', this.keyTiposCosto);
            },
             searchMonedas(){
                this.$store.dispatch('moneda/searchMonedas', this.keyMonedas);
            },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>