<template>
	<div>
		 <v-app id="inspire" v-if="tipoCupones != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-money-check"></i><strong> Listado de</strong> Tipos de Cupones</h2>
                            <div class="text-right">
                            <button @click="setNullTipoCupon" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalTipoCupon">NUEVO TIPO CUPON</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="tipoCupones" :fields="json_fields" worksheet="Listado de tipos de cupones" name="tipoCupones.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='tipoCupones'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado tipos de cupones...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td class="text-xs-center">{{ props.item.nombre }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewTipoCupon(props.item.id)"
           data-toggle="modal" data-target="#modalTipoCupon">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteTipoCupon(props.item.id)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalTipoCupon" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="saveTipoCupon">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titlemodalTipoCupon }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                        <label for="nameTipoCupon">Nombre</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="nameTipoCupon" class="form-control"
                                    placeholder="Nombre del tipo de cupon" required="" v-model="nameTipoCupon">
                            </div>
                        </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-info waves-effect">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('tipocupon/listTipoCupones', 1);
        },
		created(){
           document.title = 'Tipo Cupones - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('tipocupon/listTipoCupones', 1);
        },
        data(){
        	return {
        		titlemodalTipoCupon: null,
        		nameTipoCupon: null,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id'
		          },
		          {text: 'Nombre', value: 'nombre',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id',
            'Nombre': 'nombre',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('tipocupon/listTipoCupones', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	tipoCupones(){
        		return this.$store.getters['tipocupon/listTipoCupones'];
        	},
        	tipoCupon(){
        		return this.$store.getters['tipocupon/tipoCupon'];
        	},
		    pagination(){
        		return this.$store.getters['tipocupon/pagination'];
		      },
		     pages () {
		        return this.$store.getters['tipocupon/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['tipocupon/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['tipocupon/loading'];
		      },
        },
        methods: {
        	deleteTipoCupon(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar el tipo de cupon?",
			        text: "El tipo de cupon se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarlo!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('tipocupon/deleteTipoCupon',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	saveTipoCupon(){
        		if(this.nameTipoCupon != '' || this.nameTipoCupon != null){ 
        		if(this.tipoCupon){
        			this.$store.dispatch('tipocupon/updateTipoCupon',{id: this.tipoCupon.id,nameTipoCupon: this.nameTipoCupon})
        			//console.log('estamos editando un tipo de cupon');
        			$('#modalTipoCupon').modal('hide');
        		}else{	
        			this.$store.dispatch('tipocupon/newTipoCupon',this.nameTipoCupon);
        			//console.log('estamos guardando un tipo de cupon');
        			$('#modalTipoCupon').modal('hide');
        		}
        	}
        		
        	},
        	viewTipoCupon(id){
        	    this.$store.commit('tipocupon/viewTipoCupon',id);
        	    this.nameTipoCupon = this.tipoCupon.nombre;
        	    this.titlemodalTipoCupon = `Editar Tipo Cupon: ${this.tipoCupon.nombre}`;
        	},
        	setNullTipoCupon(){
        		this.$store.commit('tipocupon/viewTipoCupon',null);
        		this.nameTipoCupon = null;
        		this.titlemodalTipoCupon = 'Nuevo Tipo Cupon';
        	},
	         changePage(page){
    		  this.$store.commit('tipocupon/changePage',page)
	          this.$store.dispatch('tipocupon/listTipoCupones',page);
	        },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>