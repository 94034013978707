import {store} from './store/';
import {router} from './main.js';
import Login from './components/Login.vue';
import PasswordReset from './components/PasswordReset.vue';
import PasswordResetToken from './components/PasswordResetToken.vue';
import Dashboard from './components/Dashboard.vue';
import DashboardInicio from './components/DashboardInicio.vue';
import Profile from './components/Profile.vue';
import Generos from './components/Generos.vue';
import Artistas from './components/Artistas.vue';
import Auditorios from './components/Auditorios.vue';
import Temporadas from './components/Temporadas.vue';
import TipoDescuento from './components/TipoDescuento.vue';
import TipoEvento from './components/TipoEvento.vue';
import Cuponera from './components/Cuponera.vue';
import TipoCupon from './components/TipoCupon.vue';
import PuntoVenta from './components/PuntoVenta.vue';
import GrupoVendedor from './components/GrupoVendedor.vue';
import Clientes from './components/Clientes.vue';
import Tribunas from './components/Tribunas.vue';
import Localidades from './components/Localidades.vue';
import Palcos from './components/Palcos.vue';
import Filas from './components/Filas.vue';
import GrupoVendedorPunto from './components/GrupoVendedorPunto.vue';
import Imagenes from './components/Imagenes.vue';
import Puestos from './components/Puestos.vue';
import PuestosPalcos from './components/PuestosPalcos.vue';
import Eventos from './components/Eventos.vue';
import EventosCuponeras from './components/EventosCuponeras.vue';
import Paises from './components/Paises.vue';
import Departamentos from './components/Departamentos.vue';
import Ciudades from './components/Ciudades.vue';
import Condiciones from './components/Condiciones.vue';
import TipoCosto from './components/TipoCosto.vue';
import Preventas from './components/Preventas.vue';
import Monedas from './components/Monedas.vue';
import Tasas from './components/Tasas.vue';
import BoletasEventos from './components/BoletasEventos.vue';
import CondicionesEventos from './components/CondicionesEventos.vue';
import CostosEventos from './components/CostosEventos.vue';
import PalcosEventos from './components/PalcosEventos.vue';
import DescuentosEventos from './components/DescuentosEventos.vue';
import PuntosEventos from './components/PuntosEventos.vue';
import BoletasPreventas from './components/BoletasPreventas.vue';
import ImagenesArtistas from './components/ImagenesArtistas.vue';
import ImagenesAuditorios from './components/ImagenesAuditorios.vue';
import ImagenesEventos from './components/ImagenesEventos.vue';
import PalcosPreventas from './components/PalcosPreventas.vue';
import DetallesDescuentos from './components/DetallesDescuentos.vue';
import PuestosPalcosEventos from './components/PuestosPalcosEventos.vue';
import Usuarios from './components/Usuarios.vue';

export const routes = [
	{
		path: '/',
		component: Dashboard,
		meta: { 
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        component: DashboardInicio,
        name: 'dashboard',
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: 'informe-aforo-evento',
        component: ()=> import('@/components/views/InformesAforo.vue'),
        name: 'InformesAforoEvento',
        meta:{
          requiresAuth: true,
          requiresAdministrador: true, 
          requiresComercial: true,
          requiresPuntodeVenta: true,
          requiresJefeComercial: true,
          requiresJefedePuntosdeVenta: true,
          requiresSupervisor: true,
        }
      },
      {
        path: 'informe-detalle-evento',
        component: ()=> import('@/components/views/InformesVentasEvento.vue'),
        name: 'InformesVentasEvento',
        meta:{
          requiresAuth: true,
          requiresAdministrador: true,
          requiresEmpresario: true,
          requiresComercial: true,
          requiresPuntodeVenta: true,
          requiresJefeComercial: true,
          requiresJefedePuntosdeVenta: true,
          requiresSupervisor: true,
        }
      },


  
      {
        path: 'informe-marcaciones-evento',
        component: ()=> import('@/components/views/InformesMarcaciones.vue'),
        name: 'InformesMarcacionesEvento',
        meta:{
          requiresAuth: true,
          requiresAdministrador: true,
          requiresEmpresario: true,
          requiresComercial: true,
          requiresPuntodeVenta: true,
          requiresJefeComercial: true,
          requiresJefedePuntosdeVenta: true,
          requiresSupervisor: true,
        }
      },

   

      {
        path: 'todas-las-preventas',
        component: ()=> import('@/components/views/ListarPreventas.vue'),
        name: 'ListarPreventas',
        meta:{
          requiresAuth: true,
          requiresAdministrador: true,
          requiresComercial: true,
          requiresJefedePuntosdeVenta: true,
        }
      },
      {
        path: 'crear-preventas',
        component: ()=> import('@/components/views/CrearPreventa.vue'),
        name: 'CrearPreventa',
        meta:{
          requiresAuth: true,
          requiresAdministrador: true,
          requiresComercial: true,
          requiresJefedePuntosdeVenta: true,
        }
      },
      {
        path: 'actualizar-preventas/:id_Preventa_Edit',
        component: ()=> import('@/components/views/CrearPreventa.vue'),
        name: 'ActualizarPreventa',
        meta:{
          requiresAuth: true,
          requiresAdministrador: true,
          requiresComercial: true,
          requiresJefedePuntosdeVenta: true,
        }
      },
      {
        path: '/editor_de_apariencia',
        component: ()=> import('@/components/views/EditApariencia.vue'),
        name: 'EditarApariencia',
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: 'eventos',
        component: ()=> import('@/components/views/EditApariencia.vue'),
        name: 'EditarApariencia',
        meta: {
          requiresAuth: true,
        }
      }
    ]
	},
	{
		path: '/login',
		component: Login,
    name: 'login',
		meta: { 
        requiresAuth: false
      }
	},
  {
    path: '/passwordReset',
    name: 'passwordReset',
    component: PasswordReset,
    meta: { 
        requiresAuth: false
      },
  },
  {
    path: '/passwordReset/:token',
    name: 'passwordResetToken',
    component: PasswordResetToken,
    meta: { 
        requiresAuth: false
      },
  },
  {
    path: '*',
    name: 'Page404',
    component: ()=> import('@/components/views/Page404.vue')
  }
];