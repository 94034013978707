import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       imagenesArtistas: [],
       imagenartista: null,
       imagenesArtista: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listImagenesArtistas: (state,getters,rootState) => {
          return state.imagenesArtistas;
    },
    imagenartista: (state,getters,rootState) => {
        return state.imagenartista;
    },
    imagenesArtista: (state,getters,rootState) => {
          return state.imagenesArtista;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listImagenesArtistas: (state,imagenesArtistas) => {
          state.imagenesArtistas = imagenesArtistas.data;
          state.pagination.totalItems = imagenesArtistas.total;
          state.pagination.page = imagenesArtistas.current_page;
          state.pagination.rowsPerPage = imagenesArtistas.per_page;
       },
      viewImagenArtista(state,payload){
        let auxImagenArtista = state.imagenesArtistas.filter((item) => 
          (item.id_artista == payload.id_artista && item.id_imagen == payload.id_imagen));
        state.imagenartista = auxImagenArtista[0];
      },
      listImagenesArtistaSearch(state,imagenesArtista){
            state.imagenesArtista = imagenesArtista;
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listImagenesArtistas({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}imagenartist?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listImagenesArtistas',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener las imagenes por artista!", "error");
        console.error(error);
      });

    },
  newImagenArtista({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}imagenartist`,
      {
          id_artista: payload.id_artista,
          id_imagen: payload.id_imagen
      }
      ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo la imagen por artista correctamente!", "success");
        store.dispatch('imagenartista/listImagenesArtistas',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear la imagen por artista!", "error");
        console.error(error);
      });
  },
  updateImagenArtista({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}imagenartist/${payload.id}`,
      {
          id_imagen_old: payload.id_imagen_old,
          id_imagen_new: payload.id_imagen_new
      }
      ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo la imagen por artista correctamente!", "success");
        store.dispatch('imagenartista/listImagenesArtistas',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar la imagen por artista!", "error");
        console.error(error);
      });
  },
  deleteImagenArtista({state,getters,commit,rootState},imagenartista){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}imagenartist/${imagenartista}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino la imagen por artista correctamente!", "success");
        store.dispatch('imagenartista/listImagenesArtistas',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar la imagen por artista!","error");
        console.log(error);
     });
  },
  searchImagenesArtista({state,getters,commit,rootState},artista){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}imagenartist/${artista}`,null,{headers: headers})
      .then(response => {
        commit('listImagenesArtistaSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        //swal("Error!", "Error al obtener las imagenes del artista!", "error");
        commit('listImagenesArtistaSearch',[]);
        store.commit('changeLoader', false);
        console.error(error);
      });

    },
  },
}