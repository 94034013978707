<template>
  <ul>
    <div class="item-submenu-nav" @click="dataSubmenu.length > 0 ? display = !display : $router.push({ name: slugRoute})">
      <i class="fas d-flex align-items-center" :class="icon"></i>
      <span>{{title}}</span>
      <i v-if="dataSubmenu.length > 0 && !display" class="fas fa-angle-down ml-auto mr-3 d-flex align-items-center"></i>
      <i v-if="dataSubmenu.length > 0 && display" class="fas fa-angle-up ml-auto mr-3 d-flex align-items-center"></i>
    </div>
    <div v-if="display">
      <router-link :key="index" v-if="!item.permissionRol || item.permissionRol.includes($store.state.user.dataUserLogged.id_rol)" v-for="(item, index) in dataSubmenu" :to="{ name: item.route}" exact>
        <div class="sub-menu-nav p-0 m-0">
          <!-- <i class="fas d-flex align-items-center" :class="item.icon"></i> -->
          <span class="pl-3 m-0">{{item.title}}</span>
          <!-- Falta volver a llamar el submenu -->
        </div>
      </router-link>
    </div>
  </ul>
</template>
<script>
export default {
  data() {
    return {
      display: false,
    }
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    slugRoute: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      required: false,
      default: ''
    },
    dataSubmenu: {
      type: Array,
      required: false,
      default: null
    }
  }
}
</script>
<style>
  .item-submenu-nav{
    padding: 9px 9px 9px 18px;
    margin: 10px 13px;
    border-radius: 3rem;
    cursor: pointer;
    height: 46px;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }
  .item-submenu-nav:hover{
    background-color: rgba(0, 0, 0, 0.03);
    color: #5783c7;
  }
  .item-submenu-nav span{
    padding-left: 1rem;
    padding-top: .3rem;
  }
  .sub-menu-nav{
    display: flex;
    flex-wrap: wrap;
  }
</style>