<template>
	<div>
		 <v-app id="inspire" v-if="cuponeras != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-money-check-alt"></i><strong> Listado de</strong> Cuponeras</h2>
                            <div class="text-right">
                            <button @click="setNullCuponera" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalCuponera">NUEVA CUPONERA</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="cuponeras" :fields="json_fields" worksheet="Listado de cuponeras" name="cuponeras.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='cuponeras'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado cuponeras...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td class="text-xs-center">{{ props.item.nombre }}</td>
      <td class="text-xs-center">
          <span v-if="props.item.fecha_inicio">
               {{ fromNow(props.item.fecha_inicio) }}
               <br>
               <small>{{ props.item.fecha_inicio }}</small>
          </span>
          <span v-else>{{ props.item.fecha_inicio }}</span>
     </td>
      <td class="text-xs-center">
           <span v-if="props.item.fecha_fin">
               {{ fromNow(props.item.fecha_fin) }}
               <br>
               <small>{{ props.item.fecha_fin }}</small>
          </span>
          <span v-else>{{ props.item.fecha_fin }}</span>
      </td>
      <td class="text-xs-center">{{ props.item.status }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewCuponera(props.item.id)"
           data-toggle="modal" data-target="#modalCuponera">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteCuponera(props.item.id)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalCuponera" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="saveCuponera">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titlemodalCuponera }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                        <label for="nameCuponera">Nombre</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="nameCuponera" class="form-control"
                                    placeholder="Nombre de la cuponera" required="" v-model="nameCuponera">
                            </div>
                        </div>
                           <label for="fechaInicio">Fecha inicio</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="fechaInicio" class="form-control datepicker"
                                    placeholder="AAAA/MM/DD" required="">
                            </div>
                        </div>
                         <label for="fechaFin">Fecha fin</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="fechaFin" class="form-control datepicker"
                                    placeholder="AAAA/MM/DD" required="">
                            </div>
                        </div>
                          <label for="status">Estado</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="status" class="form-control"
                                    placeholder="Estado de la cuponera" v-model="status">
                            </div>
                        </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-info waves-effect">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';
import moment from 'moment';

moment.locale('es');


	export default {
        beforeCreate(){
           this.$store.dispatch('cuponera/listCuponeras', 1);
        },
		created(){
           document.title = 'Cuponeras - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('cuponera/listCuponeras', 1);
        },
        data(){
        	return {
        		titlemodalCuponera: null,
        		nameCuponera: null,
               fechaInicio: null,
               fechaFin: null,
               status: null,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id'
		          },
		          {text: 'Nombre', value: 'nombre',align: 'center' },
                  {text: 'Fecha inicio', value: 'fecha_inicio',align: 'center' },
                  {text: 'Fecha fin', value: 'fecha_fin',align: 'center' },
                  {text: 'Estado', value: 'status',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id',
            'Nombre': 'nombre',
            'Fecha inicio': 'fecha_inicio',
            'Fecha fin': 'fecha_fin',
            'Estado': 'status',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('cuponera/listCuponeras', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	cuponeras(){
        		return this.$store.getters['cuponera/listCuponeras'];
        	},
        	cuponera(){
        		return this.$store.getters['cuponera/cuponera'];
        	},
	    pagination(){
   		return this.$store.getters['cuponera/pagination'];
	      },
	     pages () {
	        return this.$store.getters['cuponera/pages'];
	      },
	      totalItemCount(){
	      	return this.$store.getters['cuponera/totalItemCount'];
	      },
	      loading(){
	      		return this.$store.getters['cuponera/loading'];
	      },
        },
        methods: {
        	deleteCuponera(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar la cuponera?",
			        text: "La cuponera se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarla!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('cuponera/deleteCuponera',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	saveCuponera(){
        		if(this.nameCuponera != '' || this.nameCuponera != null){ 
        		if(this.cuponera){
        			this.$store.dispatch('cuponera/updateCuponera',{id: this.cuponera.id,nameCuponera: this.nameCuponera, fechaInicio: $("#fechaInicio").val(), fechaFin: $("#fechaFin").val(), status: this.status})
        			//console.log('estamos editando una cuponera');
        			$('#modalCuponera').modal('hide');
        		}else{	
        			this.$store.dispatch('cuponera/newCuponera',{nameCuponera: this.nameCuponera, fechaInicio: $("#fechaInicio").val(), fechaFin: $("#fechaFin").val(), status: this.status});
        			//console.log('estamos guardando una cuponera');
        			$('#modalCuponera').modal('hide');
        		}
        	}
        		
        	},
        	viewCuponera(id){
        	    this.$store.commit('cuponera/viewCuponera',id);
        	    this.nameCuponera = this.cuponera.nombre;
               $("#fechaInicio").val(this.cuponera.fecha_inicio);
               $("#fechaFin").val(this.cuponera.fecha_fin);
              this.fechaInicio = this.cuponera.fecha_inicio;
              this.fechaFin = this.cuponera.fecha_fin;
              this.status = this.cuponera.status;
        	    this.titlemodalCuponera = `Editar cuponera: ${this.cuponera.nombre}`;
        	},
        	setNullCuponera(){
        		this.$store.commit('cuponera/viewCuponera',null);
        		this.nameCuponera = null;
               this.fechaInicio = null;
               $("#fechaInicio").val("");
               $("#fechaFin").val("");
               this.fechaFin = null;
               this.status = null;
        		this.titlemodalCuponera = 'Nueva cuponera';
        	},
	         changePage(page){
    		     this.$store.commit('cuponera/changePage',page)
	          this.$store.dispatch('cuponera/listCuponeras',page);
	        },
             fromNow(d){
                  return moment(d).fromNow();
             },
            

        }
	}
</script>
<style>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}

</style>