import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       dataUserLogged:{
              nombre: null,
              email: null,
              tipo_identificacion: null,
              identificacion: null,
              telefono: null,
              direccion: null,
              ciudad: null,
              departamento: null,
              id_rol: null
            },
        tipoIdentificaciones: [],
       usuarios: [],
       roles: [],
       usuario: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    dataUserLogged: (state,getters,rootState) => {
      return state.dataUserLogged;
    },
    listTipoIdentificaciones: (state,getters,rootState) => {
        return state.tipoIdentificaciones;
    },
    listUsuarios: (state,getters,rootState) => {
          return state.usuarios;
    },
    listRoles: (state,getters,rootState) => {
          return state.roles;
    },
    usuario: (state,getters,rootState) => {
        return state.usuario;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
    updateDataUserLogged: (state,dataUser) => {
        state.dataUserLogged.nombre = dataUser.nombre;
        state.dataUserLogged.email = dataUser.email;
        state.dataUserLogged.tipo_identificacion = dataUser.tipo_identificacion;
        state.dataUserLogged.identificacion = dataUser.identificacion;
        state.dataUserLogged.telefono = dataUser.telefono;
        state.dataUserLogged.direccion = dataUser.direccion;
        state.dataUserLogged.ciudad = dataUser.ciudad;
        state.dataUserLogged.departamento = dataUser.departamento;
        state.dataUserLogged.id_rol = dataUser.id_rol;
       },
       listTipoIdentificaciones: (state,tipoIdentificaciones) => {
          state.tipoIdentificaciones = tipoIdentificaciones;
       },

    listUsuarios: (state,usuarios) => {
          state.usuarios = usuarios.data;
          state.pagination.totalItems = usuarios.total;
          state.pagination.page = usuarios.current_page;
          state.pagination.rowsPerPage = usuarios.per_page;
       },
      listRoles: (state,roles) => {
          state.roles = roles;
       },
      viewUsuario(state,email){
        let auxUsuario = state.usuarios.filter((item) => item.email === email);
        state.usuario = auxUsuario[0];
      },
           listUsuariosSearch: (state,usuarios) => {
            if(usuarios != null){ 
                state.usuarios = usuarios;
            }else{
              state.usuarios = null;
            }
       },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {

    detailsUser({state,getters,commit,rootState},token){
      var headersT = null;
        if(token != null){ 
          headersT = {
            "Accept": "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token
          };
        }else{
          headersT = headers;
        }
      return axios.post(`${configApi.apiUrl}detailsuser`,null,{headers: headersT})
      .then(response => {
        //console.log(response.data);
        //return;
        // localStorage.setItem('nombreUserTicketClient',response.data.success.nombre);
        // localStorage.setItem('emailUserTicketClient',response.data.success.email);
        // localStorage.setItem('identificacionUserTicketClient',response.data.success.identificacion);
        // localStorage.setItem('telefonoUserTicketClient',response.data.success.telefono);
        // localStorage.setItem('direccionUserTicketClient',response.data.success.direccion);
        // localStorage.setItem('ciudadUserTicketClient',response.data.success.ciudad);
        // localStorage.setItem('departamentoUserTicketClient',response.data.success.departamento);
        // localStorage.setItem('rolUserTicketClient',response.data.success.id_rol);
        commit('updateDataUserLogged',response.data.success)
      })
      .catch(error => {
        console.error(error);
      });

    },
    // updateDataUserLogged({state,getters,commit,rootState}){
    //   let dataUser = {
    //     nombre: localStorage.getItem('nombreUserTicketClient'),
    //     email: localStorage.getItem('emailUserTicketClient'),
    //     identificacion: localStorage.getItem('identificacionUserTicketClient'),
    //     telefono: localStorage.getItem('telefonoUserTicketClient'),
    //     direccion: localStorage.getItem('direccionUserTicketClient'),
    //     ciudad: localStorage.getItem('ciudadUserTicketClient'),
    //     departamento: localStorage.getItem('departamentoUserTicketClient'),
    //     id_rol: localStorage.getItem('rolUserTicketClient'),
    //   };
    //   commit('updateDataUserLogged',dataUser);
    // },
    updateProfile({state,getters,commit,rootState},user){
      // nombre
      // identificacion
      // direccion
      // departamento
      // ciudad
      // telefono
      // tipo_identificacion
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}updateprofile/${user.email}`,user,{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo tu perfil correctamente!", "success");
        store.dispatch('user/detailsUser',null);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar tu perfil!", "error");
        console.error(error);
      });
    },
    updatePassword({state,getters,commit,rootState},payload){
      // mypassword
      // password
      // c_password
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
        axios.post(`${configApi.apiUrl}cambioclave`,payload,{headers: headers})
        .then(response => {
            swal("Exito!", "Se actualizo tu contraseña correctamente!", "success");
            store.commit('changeLoader', false);
        })
        .catch(error => {
            store.commit('changeLoader', false);
            swal("Error al actualizar tu contraseña!",
             "Verifica tu contraseña actual y que las contraseñas nuevas coincidan.",
              "error");
            console.log(error);
        });
    },
    listTipoIdentificaciones({state,getters,commit,rootState}){
      axios.get(`${configApi.apiUrl}tipo_identificacion_all`,null,{headers: headers})
      .then(response => {
        //console.log(response.data);
        commit('listTipoIdentificaciones',response.data.data);
      })
      .catch(error => {
        swal("Error!", "Error al obtener los tipos de identificacion!", "error");
        console.error(error);
      });
    },
    listUsuarios({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}listausuarios?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.user);
        commit('listUsuarios',response.data.data.user);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los usuarios!", "error");
        console.error(error);
      });

    },
    listRoles({state,getters,commit,rootState}){
      store.dispatch('validateToken');
      axios.get(`${configApi.apiUrl}roles_all`,null,{headers: headers})
      .then(response => {
        commit('listRoles',response.data.data);
      })
      .catch(error => {
        swal("Error!", "Error al obtener los roles!", "error");
        console.error(error);
      });

    },
    deleteUser({state,getters,commit,rootState},emailUser){
    store.dispatch('validateToken');
    store.commit('changeLoader', true);
    axios.delete(`${configApi.apiUrl}destroy/${emailUser}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino el usuario correctamente!", "success");
        store.dispatch('user/listUsuarios',state.pagination.page);
        store.commit('changeLoader', false);
     })
     .catch(error => {
      store.commit('changeLoader', false);
        swal("Error!","Error al eliminar el usuario!","error");
        console.log(error);
     });
  },
  updateUser({state,getters,commit,rootState},user){
      // nombre
      // identificacion
      // direccion
      // departamento
      // ciudad
      // telefono
      // tipo_identificacion
      store.commit('changeLoader', true);
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}updateProfileForAdmin/${user.email}`,user,{headers: headers})
      .then(response => {
        swal("Exito!", "El usuario se actualizo correctamente!", "success");
        store.dispatch('user/listUsuarios',state.pagination.page);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al actualizar el usuario!", "error");
        console.error(error);
      });
    },
    newUser({state,getters,commit,rootState}, user) {
      store.commit('changeLoader', true);
      axios.post(`${configApi.apiUrl}register`,user)
      .then(response => {
        if(response.data.success){
          swal(`Bienvenido ${user.nombre}!`, "Hemos enviado un enlace a su correo para que active su cuenta!", "success");
          store.dispatch('user/listUsuarios',state.pagination.page);
          store.commit('changeLoader', false);
          //router.push('/');
        }
      })
      .catch(error => {
        console.log(error);
        store.commit('changeLoader', false);
        //console.log(error.response.data.error);
        swal("Ocurrio un error!", error.response.data.error, "error");
      });
      },
  }
}