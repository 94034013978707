
export const headers = {
   "Accept": "application/json",
    "Content-Type": "application/json",
    "Content-Type" : "multipart/form-data",
};

export const headersMultipart = {
	/*"Accept": "application/json",
    "Content-Type": "application/json",
    "Content-Type" : "multipart/form-data",
    'Authorization': `Bearer  ${store.accessToken}`*/
};