<template>
	<div>
		 <!-- Left Sidebar -->
        <aside id="leftsidebar" class="sidebar">
            <!-- Menu -->
            <div class="menu">
                <ul class="list">
                    <li class="sidebar-user-panel active">
                        <div class="user-panel">
                            <div class=" image" title="">
                                     <router-link style="cursor: pointer" tag="img" src="/images/user.png" class="img-circle user-img-circle" alt="User" :to="{path: '/perfil'}" title="Mi perfil" ></router-link>
                            </div>
                        </div>
                        <div class="profile-usertitle">
                            <div class="sidebar-userpic-name"> {{ dataUser.nombre }} </div>
                            <div class="profile-usertitle-job ">{{ dataUser.identificacion }}</div>
                        </div>
                    </li>
                    <li>
                        <router-link :to="{ path: '/'}" exact>
                            <i class="fas fa-tachometer-alt"></i>
                            <span>Dashboard</span>
                        </router-link>
                    </li>
                    <li :key="index" v-for="(item, index) in dataMenu">
                        <ItemNavList v-if="item.permissionRol.includes($store.state.user.dataUserLogged.id_rol)" :title="item.title" :icon="item.icon" :dataSubmenu="item.items" :slugRoute="item.routerPush"/>
                    </li>
                </ul>
            </div>
            <!-- #Menu -->
        </aside>
        <!-- #END# Left Sidebar -->
	</div>
</template>
<script>
	import ItemNavList from '@/components/Templates/Nav/ItemNavList.vue'
	export default{
        components: { ItemNavList },
        data(){
            return{
                /** Estructura del menu */
                dataMenu: [
                   /* {
                        title: 'Eventos',
                        icon: 'fa-solid fa-calendar',
                        permissionRol: [1, 3, 4, 5, 6, 7, 11],
                        routerPush: '',
                        items: [
                             {
                                title: 'Generar Códigos',
                                icon: 'fa-file-invoice-dollar',
                                route: 'GenerarCodigos',
                                permissionRol: [1],
                                subMenu: []
                            },                            
                        ]
                    },*/
                    {
                        title: 'Informes',
                        icon: 'fa-chart-line',
                        permissionRol: [1, 3, 4, 5, 6, 7, 11],
                        routerPush: '',
                        items: [
                            /** Se debe dejar la estructura por si contiene otro submenu! */
                            {
                                title: 'Control de Acceso',
                                icon: 'fa-file-invoice-dollar',
                                route: 'InformesAforoEvento',
                                permissionRol: [1, 4, 5, 6, 7],
                                subMenu: []
                            },
                            {
                                title: 'Ventas Evento',
                                icon: 'fa-file-invoice-dollar',
                                route: 'InformesVentasEvento',
                                permissionRol: [1, 3, 4, 5, 6, 7, 11],
                                subMenu: []
                            },
                            {
                                title: 'Detalle de Ventas',
                                icon: 'fa-file-invoice-dollar',
                                route: 'InformesMarcacionesEvento', 
                                permissionRol: [1, 3, 4, 5, 6, 7, 11],
                                subMenu: []
                            }
                        ]
                    },
                    {
                        title: 'Preventas',
                        icon: 'fa-ticket-alt',
                        routerPush: '',
                        permissionRol: [1],
                        items: [
                            {
                                title: 'Todas las preventas',
                                icon: 'fa-clipboard-list',
                                route: 'ListarPreventas',
                                subMenu: []
                            },
                            {
                                title: 'Crear preventa',
                                icon: 'fa-ticket-alt',
                                route: 'CrearPreventa',
                                subMenu: []
                            }
                        ]
                    },
                    {
                        title: 'Apariencia',
                        icon: 'fa-chart-line',
                        permissionRol: [1],
                        routerPush: 'EditarApariencia',
                        items: []
                    },
                ]
            }
        },
        computed: {
            dataUser(){
                return this.$store.getters['user/dataUserLogged']; 
            }
        },
	}
</script>
<style>
.router-link-exact-active{
    color:#5783c7 !important;
    background-color: #f5f5f5;
}
.router-link-exact-active span{
    color:#5783c7 !important;
}
</style>