<template>
	<div>
		 <v-app id="inspire" v-if="monedas != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-globe-americas"></i><strong> Listado de</strong> Monedas</h2>
                            <div class="text-right">
                            <button @click="setNullMoneda" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalMoneda">NUEVA MONEDA</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="monedas" :fields="json_fields" worksheet="Listado de monedas" name="monedas.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='monedas'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado monedas...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.codigo_moneda }}</td>
      <td class="text-xs-center">{{ props.item.descripcion }}</td>
      <td class="text-xs-center">{{ props.item.simbolo }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewMoneda(props.item.codigo_moneda)"
           data-toggle="modal" data-target="#modalMoneda">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteMoneda(props.item.codigo_moneda)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalMoneda" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalMoneda }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                        <label for="codigoMoneda" v-if="moneda == null">Codigo</label>
                        <div class="form-group" v-if="moneda == null">
                            <div class="form-line">
                                <input type="text" id="codigoMoneda" class="form-control"
                                    placeholder="Codigo de la moneda" required="" v-model="codigoMoneda">
                            </div>
                        </div>
                        <label for="descripcionMoneda">Descripcion</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="descripcionMoneda" class="form-control"
                                    placeholder="Descripcion de la moneda" required="" v-model="descripcionMoneda">
                            </div>
                        </div>
                          <label for="simboloMoneda">Simbolo</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="simboloMoneda" class="form-control"
                                    placeholder="Simbolo de la moneda" required="" v-model="simboloMoneda">
                            </div>
                        </div>
                     
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info waves-effect" @click="saveMoneda">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('moneda/listMonedas', 1);
        },
		created(){
           document.title = 'Monedas - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('moneda/listMonedas', 1);
        },
        data(){
        	return {
        		titleModalMoneda: null,
        		codigoMoneda: null,
        		descripcionMoneda: null,
                simboloMoneda: null,
        		search: '',
        		headers: [
		          {
		            text: 'Codigo',
		            align: 'left',
		            sortable: false,
		            value: 'codigo_moneda'
		          },
		          {text: 'Descripcion', value: 'descripcion',align: 'center' },
		          {text: 'Simbolo', value: 'simbolo',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Codigo': 'codigo_moneda',
            'Descripcion': 'descripcion',
            'Simbolo': 'simbolo',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('moneda/listMonedas', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	monedas(){
        		return this.$store.getters['moneda/listMonedas'];
        	},
        	moneda(){
        		return this.$store.getters['moneda/moneda'];
        	},
		    pagination(){
        		return this.$store.getters['moneda/pagination'];
		      },
		     pages () {
		        return this.$store.getters['moneda/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['moneda/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['moneda/loading'];
		      },
        },
        methods: {
        	deleteMoneda(codigo_moneda){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar la moneda?",
			        text: "La moneda se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarla!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('moneda/deleteMoneda',codigo_moneda);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	saveMoneda(){
        		if((this.codigoMoneda != '' || this.codigoMoneda != null) &&
                    (this.descripcionMoneda != '' && this.descripcionMoneda != null) &&
                    (this.simboloMoneda != '' && this.simboloMoneda != null)){ 
        		if(this.moneda){
        			this.$store.dispatch('moneda/updateMoneda',
                        {
                            codigo_moneda: this.moneda.codigo_moneda,
                            descripcion: this.descripcionMoneda,
                            simbolo: this.simboloMoneda
                        });
        			//console.log('estamos editando un moneda');
        			$('#modalMoneda').modal('hide');
        		}else{	
        			this.$store.dispatch('moneda/newMoneda',
                        {
                            codigo_moneda: this.codigoMoneda,
                            descripcion: this.descripcionMoneda,
                            simbolo: this.simboloMoneda
                        });
        			//console.log('estamos guardando un moneda');
        			$('#modalMoneda').modal('hide');
        		}
        	}
        		
        	},
        	viewMoneda(codigo_moneda){
        	    this.$store.commit('moneda/viewMoneda',codigo_moneda);
        	    this.codigoMoneda = this.moneda.codigo_moneda;
        	    this.descripcionMoneda = this.moneda.descripcion;
                this.simboloMoneda = this.moneda.simbolo;
        	    this.titleModalMoneda = `Editar Moneda: ${this.moneda.codigo_moneda}`;
        	},
        	setNullMoneda(){
        		this.$store.commit('moneda/viewMoneda',null);
        		this.codigoMoneda = null;
                this.descripcionMoneda = null;
                this.simboloMoneda = null;
        		this.titleModalMoneda = 'Nueva Moneda';
        	},
	         changePage(page){
    		  this.$store.commit('moneda/changePage',page)
	          this.$store.dispatch('moneda/listMonedas',page);
	        },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>