//Modulos y Componentes
import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import {routes} from './routes';
import {store} from './store/';
//import titleMixin from './mixins/titleMixin';
import Vuetify from 'vuetify';
import 'babel-polyfill';
import 'vuetify/dist/vuetify.min.css';
import excel from 'vue-excel-export';
import moment from 'moment';
import axios from 'axios';

moment.locale('es');
 
//Librerias
import '../public/css/app.css';

Vue.config.productionTip = false

//Vue.mixin(titleMixin);
Vue.use(excel);
Vue.use(Vuetify);
Vue.use(VueRouter);

export const router = new VueRouter({
		routes,
		mode: 'history',
});

//axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessTokenTicketClient')}`;
axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.accessToken}`;
axios.interceptors.response.use((response) => { 
  return response
}, function (error) {
  let originalRequest = error.config
  if (error.response.status === 401) { 
    //store.dispatch('logoutLocal');
  }
  return Promise.reject(error)
});  

router.beforeEach(async (to, from, next) => {
  if (store.state.accessToken) {
    await store.dispatch('validateToken');
  }
  var permisos = [];
  if (to.meta.requiresAdministrador) {
    permisos.push(1);
  }
  if (to.meta.requiresComprador) {
    permisos.push(2);
  }
  if (to.meta.requiresEmpresario) {
    permisos.push(3);
  }
  if (to.meta.requiresComercial) {
    permisos.push(4);
  }
  if (to.meta.requiresPuntodeVenta) {
    permisos.push(5);
  }
  if (to.meta.requiresJefeComercial) {
    permisos.push(6);
  }
  if (to.meta.requiresJefedePuntosdeVenta) {
    permisos.push(7);
  }
  if (to.meta.requiresFinanzas) {
    permisos.push(8);
  }
  if (to.meta.requiresAnalista) {
    permisos.push(9);
  }
  if (to.meta.requiresGerencia) {
    permisos.push(10);
  }
  if (to.meta.requiresSupervisor) {
    permisos.push(11);
  }
  if (!to.meta.requiresAuth && permisos.length == 0) {
    next();
  } else if (to.meta.requiresAuth && permisos.length == 0) {
    if (store.state.accessToken === null || store.state.accessToken === '' || store.state.accessToken === undefined) {
      next('/login');
    } else {
      next();
    }
  } else {
    if (permisos.length == 0) {
      next();
    } else {
      if (store.state.accessToken === null || store.state.accessToken === '' || store.state.accessToken === undefined) {
        next('/login');
      } else {
        var cont = false
        for (var i = 0; i < permisos.length; i++) {
          if (store.state.user.dataUserLogged.id_rol == permisos[i]) {
            cont = true;
          }
        }
        if (cont) {
          next();
        } else {
          next('/');
        }
      }
    }
  }
});

Vue.filter('mayusculas', (value) => value.toUpperCase());

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
