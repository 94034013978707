<template>
	<div>
		 <v-app id="inspire" v-if="puestosPalcos != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-gem"></i><strong> Listado de</strong> Puestos por Palco</h2>
                            <div class="text-right">
                            <button @click="setNullPuestoPalco" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalPuestoPalco">NUEVO PALCO</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="puestosPalcos" :fields="json_fields" worksheet="Listado de puestos por palco" name="puestosPalcos.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='puestosPalcos'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado puestos por palco...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id_palco }}</td>
      <td class="text-xs-center">{{ props.item.puesto.numero }}</td>
      <td class="text-xs-center">{{ props.item.palco.nombre }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewPuestoPalco(props.item.id_palco,props.item.id_puesto)"
           data-toggle="modal" data-target="#modalPuestoPalco">
            edit
          </v-icon>
          <v-icon
            small
            @click="deletePuestoPalco(props.item.id_palco)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalPuestoPalco" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalPuestoPalco }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                     <label for="puestoActual" v-if="puestoActual != null">Puesto</label>
                          <div class="form-group" v-if="puestoActual != null">
                            <div class="form-line">
                                <input type="text" id="puestoActual" class="form-control"
                                    placeholder="Puesto" :value="puestoActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="searchPuestos" class="form-control"
                                    placeholder="Buscar puestos..." v-model="keyPuestos" @keyup.enter="searchPuestos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(puestos && puestos.length > 0)">
                        <v-select
                          :items="puestos"
                           item-text="numero"
                           item-value="id"
                           v-model="puesto"
                          label="Seleccione un puesto"
                        ></v-select>
                    </div>
                      <label for="palcoActual" v-if="palcoActual != null">Palco</label>
                          <div class="form-group" v-if="palcoActual != null">
                            <div class="form-line">
                                <input type="text" id="palcoActual" class="form-control"
                                    placeholder="Palco" :value="palcoActual">
                            </div>
                        </div>
                        <div class="form-group" v-if="palcoActual == null">
                            <div class="form-line">
                                <input type="text" id="searchPalcos" class="form-control"
                                    placeholder="Buscar palcos..." v-model="keyPalcos" @keyup.enter="searchPalcos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(palcos && palcos.length > 0) && (palcoActual == null)">
                        <v-select
                          :items="palcos"
                           item-text="nombre"
                           item-value="id"
                           v-model="palco"
                          label="Seleccione un palco"
                        ></v-select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button"  v-if="(puesto != null || puestoActual != null) && (palco != null || palcoActual != null)" class="btn btn-info waves-effect" @click="savePuestoPalco">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('puestopalco/listPuestosPalcos', 1);
        },
		created(){
           document.title = 'Puestos por Palco - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('puestopalco/listPuestosPalcos', 1);
        },
        data(){
        	return {
        		titleModalPuestoPalco: null,
        		puesto: null,
                puestoActual: null,
                palco: null,
                palcoActual: null,
                keyPalcos: null,
                keyPuestos: null,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id_palco'
		          },
		          {text: 'Puesto', value: 'puesto.numero',align: 'center' },
                  {text: 'Palco', value: 'palco.nombre',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id_palco',
            'Puesto': 'puesto.numero',
            'Palco': 'palco.nombre',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('puestopalco/listPuestosPalcos', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	puestosPalcos(){
        		return this.$store.getters['puestopalco/listPuestosPalcos'];
        	},
        	puestoPalco(){
        		return this.$store.getters['puestopalco/puestoPalco'];
        	},
		    pagination(){
        		return this.$store.getters['puestopalco/pagination'];
		      },
		     pages () {
		        return this.$store.getters['puestopalco/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['puestopalco/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['puestopalco/loading'];
		      },
               puestos(){
                return this.$store.getters['puesto/listPuestos'];
              },
               palcos(){
                return this.$store.getters['palco/listPalcos'];
              },
        },
        methods: {
        	deletePuestoPalco(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar el registro?",
			        text: "El registro se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarlo!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('puestopalco/deletePuestoPalco',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	savePuestoPalco(){
        		if(((this.puesto != '' || this.puesto != null) ||
                     (this.puestoActual != '' || this.puestoActual != null)) && 
                    ((this.palco != '' || this.palco != null) ||
                     (this.palcoActual != '' || this.palcoActual != null))){ 
        		if(this.puestoPalco){
                    var puestoUpdate = null;
                    if(this.puesto != null && this.puesto != ''){
                        puestoUpdate = this.puesto;
                    }else{
                        puestoUpdate = this.puestoPalco.id_palco;
                    }
        			this.$store.dispatch('puestopalco/updatePuestoPalco',{id: this.puestoPalco.id_palco,id_puesto_old: this.puestoPalco.id_puesto, id_puesto_new: puestoUpdate})
        			//console.log('estamos editando un palco');
        			$('#modalPuestoPalco').modal('hide');
        		}else{	
        			this.$store.dispatch('puestopalco/newPuestoPalco',{id_palco: this.palco, id_puesto: this.puesto});
        			//console.log('estamos guardando un palco');
        			$('#modalPuestoPalco').modal('hide');
        		}
        	}
        		
        	},
        	viewPuestoPalco(id_palco,id_puesto){
        	    this.$store.commit('puestopalco/viewPuestoPalco',{id_palco,id_puesto});
        	    this.puestoActual = this.puestoPalco.puesto.numero;
                this.palcoActual = this.puestoPalco.palco.nombre;
                this.keyPalcos = null;
                this.keyPuestos = null;
                this.puesto = null;
                this.palco = null;
        	    this.titleModalPuestoPalco = `Editar Registro: ${this.puestoPalco.id_palco}`;
        	},
        	setNullPuestoPalco(){
        		this.$store.commit('puestopalco/viewPuestoPalco',{id_palco:null,id_puesto:null});
        		this.puestoActual = null;
                this.palcoActual = null;
                this.keyPalcos = null;
                this.keyPuestos = null;
                this.puesto = null;
                this.palco = null;
        		this.titleModalPuestoPalco = 'Nuevo Registro';
        	},
	         changePage(page){
    		  this.$store.commit('puestopalco/changePage',page)
	          this.$store.dispatch('puestopalco/listPuestosPalcos',page);
	        },
            searchPalcos(){
                this.$store.dispatch('palco/searchPalcos', this.keyPalcos);
            },
            searchPuestos(){
                this.$store.dispatch('puesto/searchPuestos', this.keyPuestos);
            },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>