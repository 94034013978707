<template>
	<div>
		 <v-app id="inspire" v-if="eventosCuponeras != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-ticket-alt"></i><strong> Listado de</strong> Eventos por Cuponera</h2>
                            <div class="text-right">
                            <button @click="setNullEventoCuponera" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalEventoCuponera">NUEVO REGISTRO</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="eventosCuponeras" :fields="json_fields" worksheet="Listado de eventos por cuponera" name="eventosCuponeras.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='eventosCuponeras'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado eventos por cuponera...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id_evento }}</td>
      <td class="text-xs-center">{{ props.item.evento.nombre }}</td>
      <td class="text-xs-center">{{ props.item.cuponera.nombre }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewEventoCuponera(props.item.id_evento,props.item.id_cuponera)"
           data-toggle="modal" data-target="#modalEventoCuponera">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteEventoCuponera(props.item.id_evento)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalEventoCuponera" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalEventoCuponera }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                     <label for="cuponeraActual" v-if="cuponeraActual != null">Cuponera</label>
                          <div class="form-group" v-if="cuponeraActual != null">
                            <div class="form-line">
                                <input type="text" id="cuponeraActual" class="form-control"
                                    placeholder="Cuponera" :value="cuponeraActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchCuponeras" class="form-control"
                                    placeholder="Buscar cuponeras..." v-model="keyCuponeras" @keyup.enter="searchCuponeras">
                            </div>
                        </div>
                        <div class="form-group" v-if="(cuponeras && cuponeras.length > 0)">
                        <v-select
                          :items="cuponeras"
                           item-text="nombre"
                           item-value="id"
                           v-model="cuponera"
                          label="Seleccione una cuponera"
                        ></v-select>
                    </div>
                      <label for="eventoActual" v-if="eventoActual != null">Evento</label>
                          <div class="form-group" v-if="eventoActual != null">
                            <div class="form-line">
                                <input type="text" id="eventoActual" class="form-control"
                                    placeholder="Evento" :value="eventoActual">
                            </div>
                        </div>
                        <div class="form-group" v-if="eventoActual == null">
                            <div class="form-line">
                                <input type="text" id="searchEventos" class="form-control"
                                    placeholder="Buscar eventos..." v-model="keyEventos" @keyup.enter="searchEventos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(eventos && eventos.length > 0) && (eventoActual == null)">
                        <v-select
                          :items="eventos"
                           item-text="nombre"
                           item-value="id"
                           v-model="evento"
                          label="Seleccione un evento"
                        ></v-select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button"  v-if="(cuponera != null || cuponeraActual != null) && (evento != null || eventoActual != null)" class="btn btn-info waves-effect" @click="saveEventoCuponera">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('eventocuponera/listEventosCuponeras', 1);
        },
		created(){
           document.title = 'Eventos por Cuponera - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('eventocuponera/listEventosCuponeras', 1);
        },
        data(){
        	return {
        		titleModalEventoCuponera: null,
        		evento: null,
                eventoActual: null,
                cuponera: null,
                cuponeraActual: null,
                keyEventos: null,
                keyCuponeraseventosCuponeras: null,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id_evento'
		          },
		          {text: 'Evento', value: 'evento.nombre',align: 'center' },
                  {text: 'Cuponera', value: 'cuponera.nombre',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id_evento',
            'Evento': 'evento.nombre',
            'Cuponera': 'cuponera.nombre',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('eventocuponera/listEventosCuponeras', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	eventosCuponeras(){
        		return this.$store.getters['eventocuponera/listEventosCuponeras'];
        	},
        	eventoCuponera(){
        		return this.$store.getters['eventocuponera/eventoCuponera'];
        	},
		    pagination(){
        		return this.$store.getters['eventocuponera/pagination'];
		      },
		     pages () {
		        return this.$store.getters['eventocuponera/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['eventocuponera/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['eventocuponera/loading'];
		      },
               eventos(){
                return this.$store.getters['evento/listEventos'];
              },
               cuponeras(){
                return this.$store.getters['cuponera/listCuponeras'];
              },
        },
        methods: {
        	deleteEventoCuponera(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar el registro?",
			        text: "El registro se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarlo!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('eventocuponera/deleteEventoCuponera',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	saveEventoCuponera(){
        		if(((this.evento != '' || this.evento != null) ||
                     (this.eventoActual != '' || this.eventoActual != null)) && 
                    ((this.cuponera != '' || this.cuponera != null) ||
                     (this.cuponeraActual != '' || this.cuponeraActual != null))){ 
        		if(this.eventoCuponera){
                    var cuponeraUpdate = null;
                    if(this.cuponera != null && this.cuponera != ''){
                        cuponeraUpdate = this.cuponera;
                    }else{
                        cuponeraUpdate = this.eventoCuponera.id_cuponera;
                    }
        			this.$store.dispatch('eventocuponera/updateEventoCuponera',{id: this.eventoCuponera.id_evento,id_cuponera_old: this.eventoCuponera.id_cuponera, id_cuponera_new: cuponeraUpdate})
        			//console.log('estamos editando un palco');
        			$('#modalEventoCuponera').modal('hide');
        		}else{	
        			this.$store.dispatch('eventocuponera/newEventoCuponera',{id_evento: this.evento, id_cuponera: this.cuponera});
        			//console.log('estamos guardando un palco');
        			$('#modalEventoCuponera').modal('hide');
        		}
        	}
        		
        	},
        	viewEventoCuponera(id_evento,id_cuponera){
        	    this.$store.commit('eventocuponera/viewEventoCuponera',{id_evento,id_cuponera});
        	    this.eventoActual = this.eventoCuponera.evento.nombre;
                this.cuponeraActual = this.eventoCuponera.cuponera.nombre;
                this.keyEventos = null;
                this.keyCuponeras = null;
                this.evento = null;
                this.cuponera = null;
        	    this.titleModalEventoCuponera = `Editar Registro: ${this.eventoCuponera.id_evento}`;
        	},
        	setNullEventoCuponera(){
        		this.$store.commit('eventocuponera/viewEventoCuponera',{id_evento:null,id_cuponera:null});
        		this.eventoActual = null;
                this.cuponeraActual = null;
                this.keyEventos = null;
                this.keyCuponeras = null;
                this.evento = null;
                this.cuponera = null;
        		this.titleModalEventoCuponera = 'Nuevo Registro';
        	},
	         changePage(page){
    		  this.$store.commit('eventocuponera/changePage',page)
	          this.$store.dispatch('eventocuponera/listEventosCuponeras',page);
	        },
            searchEventos(){
                this.$store.dispatch('evento/searchEventos', this.keyEventos);
            },
            searchCuponeras(){
                this.$store.dispatch('cuponera/searchCuponeras', this.keyCuponeras);
            },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>