<template>
	<div>
		 <v-app id="inspire" v-if="palcosEventos != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-gem"></i><strong> Listado de</strong> Palcos por Evento</h2>
                            <div class="text-right">
                            <button @click="setNullPalcoEvento" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalPalcoEvento">NUEVO PALCO EVENTO</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="palcosEventos" :fields="json_fields" worksheet="Listado de palcos por evento" name="palcosEventos.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='palcosEventos'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado palcos por evento...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td class="text-xs-center">{{ props.item.evento.nombre }}</td>
      <td class="text-xs-center">{{ props.item.palco.nombre }}</td>
      <td class="text-xs-center">{{ props.item.precio_venta }}</td>
      <td class="text-xs-center">{{ props.item.precio_servicio }}</td>
      <td class="text-xs-center">{{ props.item.impuesto }}</td>
      <td class="text-xs-center">{{ props.item.status }}</td>
      <td class="text-xs-center">{{ props.item.moneda.codigo_moneda }} - {{ props.item.moneda.descripcion }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewPalcoEvento(props.item.id)"
           data-toggle="modal" data-target="#modalPalcoEvento">
            edit
          </v-icon>
          <v-icon
            small
            @click="deletePalcoEvento(props.item.id)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalPalcoEvento" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalPalcoEvento }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                          <div class="col-md-6 col-lg-6">
                        <label for="precioVentaPalcoEvento">Precio venta</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" step="any" id="precioVentaPalcoEvento" class="form-control"
                                    placeholder="Precio venta" required="" v-model="precioVentaPalcoEvento">
                            </div>
                        </div>
                      </div>
                       <div class="col-md-6 col-lg-6">
                          <label for="precioServicioPalcoEvento">Precio servicio</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" step="any" id="precioServicioPalcoEvento" class="form-control"
                                    placeholder="Precio servicio" required="" v-model="precioServicioPalcoEvento">
                            </div>
                        </div>
                       </div>
                       <div class="col-md-6 col-lg-6">
                          <label for="impuestoPalcoEvento">Impuesto</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" step="any" id="impuestoPalcoEvento" class="form-control"
                                    placeholder="Impuesto" required="" v-model="impuestoPalcoEvento">
                            </div>
                        </div>
                       </div>
                         <div class="col-md-6 col-lg-6">
                          <label for="statusPalcoEvento">Estado</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="statusPalcoEvento" class="form-control"
                                    placeholder="Estado" required="" v-model="statusPalcoEvento">
                            </div>
                        </div>
                       </div>
                        <div class="col-lg-12 col-md-12">
                              <label for="eventoActual" v-if="eventoActual != null">Evento actual</label>
                          <div class="form-group" v-if="eventoActual != null">
                            <div class="form-line">
                                <input type="text" id="eventoActual" class="form-control"
                                    placeholder="Evento actual" :value="eventoActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchEventos" class="form-control"
                                    placeholder="Buscar eventos..." v-model="keyEventos" @keyup.enter="searchEventos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(eventos && eventos.length > 0)">
                        <v-select
                          :items="eventos"
                           item-text="nombre"
                           item-value="id"
                           v-model="evento"
                          label="Seleccione un evento"
                        ></v-select>
                    </div>
                       </div>
                    <div class="col-md-6 col-lg-6">
                      <label for="palcoActual" v-if="palcoActual != null">Palco</label>
                          <div class="form-group" v-if="palcoActual != null">
                            <div class="form-line">
                                <input type="text" id="palcoActual" class="form-control"
                                    placeholder="Palco" :value="palcoActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchPalcos" class="form-control"
                                    placeholder="Buscar palcos..." v-model="keyPalcos" @keyup.enter="searchPalcos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(palcos && palcos.length > 0)">
                        <v-select
                          :items="palcos"
                           item-text="nombre"
                           item-value="id"
                           v-model="palco"
                          label="Seleccione un palco"
                        ></v-select>
                    </div>
                </div>
                    <div class="col-md-6 col-lg-6">
                            <label for="monedaActual" v-if="monedaActual != null">Moneda actual</label>
                          <div class="form-group" v-if="monedaActual != null">
                            <div class="form-line">
                                <input type="text" id="monedaActual" class="form-control"
                                    placeholder="Moneda actual" :value="monedaActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchMonedas" class="form-control"
                                    placeholder="Buscar monedas..." v-model="keyMonedas" @keyup.enter="searchMonedas">
                            </div>
                        </div>
                        <div class="form-group" v-if="(monedas && monedas.length > 0)">
                        <v-select
                          :items="monedas"
                           item-text="descripcion"
                           item-value="codigo_moneda"
                           v-model="moneda"
                          label="Seleccione una moneda"
                        ></v-select>
                    </div>
                       </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info waves-effect" @click="savePalcoEvento">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('palcoevento/listPalcosEventos', 1);
        },
		created(){
           document.title = 'Palcos por Evento - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('palcoevento/listPalcosEventos', 1);
        },
        data(){
        	return {
        		titleModalPalcoEvento: null,
        		evento: null,
                eventoActual: null,
                palco: null,
                palcoActual: null,
                moneda: null,
                monedaActual: null,
                keyPalcos: null,
                keyEventos: null,
                keyMonedas: null,
                precioServicioPalcoEvento: null,
                precioVentaPalcoEvento: null,
                statusPalcoEvento: null,
                impuestoPalcoEvento: null,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id'
		          },
                  {text: 'Evento', value: 'evento.nombre',align: 'center' },
                  {text: 'Palco', value: 'palco.nombre',align: 'center' },
                  {text: 'Precio servicio', value: 'precio_servicio',align: 'center' },
                  {text: 'Precio venta', value: 'precio_venta',align: 'center' },
                  {text: 'Impuesto', value: 'impuesto',align: 'center' },
                  {text: 'Estado', value: 'status',align: 'center' },
                  {text: 'Moneda', value: 'moneda.codigo_moneda',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id',
            'Evento': 'evento.nombre',
            'Precio servicio': 'precio_servicio',
            'Precio venta': 'precio_venta',
            'Impuesto': 'impuesto',
            'Estado': 'status',
            'Moneda': 'moneda.codigo_moneda',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('palcoevento/listPalcosEventos', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	palcosEventos(){
        		return this.$store.getters['palcoevento/listPalcosEventos'];
        	},
        	palcoevento(){
        		return this.$store.getters['palcoevento/palcoevento'];
        	},
		    pagination(){
        		return this.$store.getters['palcoevento/pagination'];
		      },
		     pages () {
		        return this.$store.getters['palcoevento/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['palcoevento/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['palcoevento/loading'];
		      },
           eventos(){
            return this.$store.getters['evento/listEventos'];
          },
            monedas(){
            return this.$store.getters['moneda/listMonedas'];
          },
           palcos(){
            return this.$store.getters['palco/listPalcos'];
          },
        },
        methods: {
        	deletePalcoEvento(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar el registro?",
			        text: "El registro se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarlo!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('palcoevento/deletePalcoEvento',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	savePalcoEvento(){
        		if(this.precioVentaPalcoEvento != null && this.precioServicioPalcoEvento != null &&
                    this.statusPalcoEvento != null && this.impuestoPalcoEvento != null &&
                    (this.evento != null || this.eventoActual != null) &&
                    (this.moneda != null || this.monedaActual != null) &&
                    (this.palco != null || this.palcoActual != null)){ 
        		if(this.palcoevento){
                    var eventoUpdate = null;
                    if(this.evento != null && this.evento != ''){
                        eventoUpdate = this.evento;
                    }else{
                        eventoUpdate = this.palcoevento.id_evento;
                    }
                     var palcoUpdate = null;
                    if(this.palco != null && this.palco != ''){
                        palcoUpdate = this.palco;
                    }else{
                        palcoUpdate = this.palcoevento.id_palco;
                    }
                     var monedaUpdate = null;
                    if(this.moneda != null && this.moneda != ''){
                        monedaUpdate = this.moneda;
                    }else{
                        monedaUpdate = this.palcoevento.codigo_moneda;
                    }
        			this.$store.dispatch('palcoevento/updatePalcoEvento',
                    {
                        id: this.palcoevento.id,
                        id_evento: eventoUpdate,
                        id_palco: palcoUpdate,
                        precio_venta: this.precioVentaPalcoEvento,
                        precio_servicio: this.precioServicioPalcoEvento,
                        impuesto: this.impuestoPalcoEvento,
                        status: this.statusPalcoEvento,
                        codigo_moneda: monedaUpdate
                    });
        			//console.log('estamos editando un palco');
        			$('#modalPalcoEvento').modal('hide');
        		}else{	
        			this.$store.dispatch('palcoevento/newPalcoEvento',
                        {
                            id_evento: this.evento,
                            id_palco: this.palco,
                            precio_venta: this.precioVentaPalcoEvento,
                            precio_servicio: this.precioServicioPalcoEvento,
                            impuesto: this.impuestoPalcoEvento,
                            status: this.statusPalcoEvento,
                            codigo_moneda: this.moneda
                        });
        			//console.log('estamos guardando un palco');
        			$('#modalPalcoEvento').modal('hide');
        		}
        	}
        		
        	},
        	viewPalcoEvento(id){
        	    this.$store.commit('palcoevento/viewPalcoEvento',id);
        	    this.eventoActual = this.palcoevento.evento.nombre;
                this.palcoActual = this.palcoevento.palco.nombre;
                this.monedaActual = `${this.palcoevento.moneda.codigo_moneda} - ${this.palcoevento.moneda.descripcion}`;
                this.precioVentaPalcoEvento = this.palcoevento.precio_venta;
                this.precioServicioPalcoEvento = this.palcoevento.precio_servicio;
                this.impuestoPalcoEvento = this.palcoevento.impuesto;
                this.statusPalcoEvento = this.palcoevento.status;
                this.keyPalcos = null;
                this.keyEventos = null;
                this.keyMonedas = null;
                this.evento = null;
                this.moneda = null;
                this.palco = null;
        	    this.titleModalPalcoEvento = `Editar Registro:`;
        	},
        	setNullPalcoEvento(){
        		this.$store.commit('palcoevento/viewPalcoEvento',null);
        		this.eventoActual = null;
                this.palcoActual = null;
                this.monedaActual = null;
                this.precioVentaPalcoEvento = null;
                this.precioServicioPalcoEvento = null;
                this.impuestoPalcoEvento = null;
                this.statusPalcoEvento = null;
                this.keyPalcos = null;
                this.keyEventos = null;
                this.keyMonedas = null;
                this.evento = null;
                this.moneda = null;
                this.palco = null;
        		this.titleModalPalcoEvento = 'Nuevo Registro';
        	},
	         changePage(page){
    		  this.$store.commit('palcoevento/changePage',page)
	          this.$store.dispatch('palcoevento/listPalcosEventos',page);
	        },
            searchPalcos(){
                this.$store.dispatch('palco/searchPalcos', this.keyPalcos);
            },
             searchEventos(){
                this.$store.dispatch('evento/searchEventos', this.keyEventos);
            },
             searchMonedas(){
                this.$store.dispatch('moneda/searchMonedas', this.keyMonedas);
            },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>