import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       detallesDescuentos: [],
       detalledescuento: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listDetallesDescuentos: (state,getters,rootState) => {
          return state.detallesDescuentos;
    },
    detalledescuento: (state,getters,rootState) => {
        return state.detalledescuento;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listDetallesDescuentos: (state,detallesDescuentos) => {
          state.detallesDescuentos = detallesDescuentos.data;
          state.pagination.totalItems = detallesDescuentos.total;
          state.pagination.page = detallesDescuentos.current_page;
          state.pagination.rowsPerPage = detallesDescuentos.per_page;
       },
      viewDetalleDescuento(state,id){
        let auxDetalleDescuento = state.detallesDescuentos.filter((item) => item.id == id);
        state.detalledescuento = auxDetalleDescuento[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listDetallesDescuentos({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}detalledescuento?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listDetallesDescuentos',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los detalles de descuento!", "error");
        console.error(error);
      });

    },
  newDetalleDescuento({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}detalledescuento`,
        {   
            id_descuento: payload.id_descuento,
            id_tribuna: payload.id_tribuna,
            id_localidad: payload.id_localidad,
            id_evento: payload.id_evento,
            id_puesto: payload.id_puesto,
            id_palco: payload.id_palco,
            porcentaje: payload.porcentaje,
            monto: payload.monto,
            status: payload.status,
            codigo_moneda: payload.codigo_moneda
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo el detalle de descuento correctamente!", "success");
        store.dispatch('detalledescuento/listDetallesDescuentos',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear el detalle de descuento!", "error");
        console.error(error);
      });
  },
  updateDetalleDescuento({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}detalledescuento/${payload.id}`,
       {
            id_descuento: payload.id_descuento,
            id_tribuna: payload.id_tribuna,
            id_localidad: payload.id_localidad,
            id_boleta_evento: payload.id_boleta_evento,
            id_palco_puesto: payload.id_palco_puesto,
            porcentaje: payload.porcentaje,
            monto: payload.monto,
            status: payload.status,
            codigo_moneda: payload.codigo_moneda
        },
        {headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo el detalle de descuento correctamente!", "success");
        store.dispatch('detalledescuento/listDetallesDescuentos',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar el detalle de descuento!", "error");
        console.error(error);
      });
  },
  deleteDetalleDescuento({state,getters,commit,rootState},detalledescuento){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}detalledescuento/${detalledescuento}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino el detalle de descuento correctamente!", "success");
        store.dispatch('detalledescuento/listDetallesDescuentos',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar el detalle de descuento!","error");
        console.log(error);
     });
  },
  },
}