<template>
	<div>
		<div class="limiter">
		<div class="container-login100">
			<div class="wrap-login100">
				<form class="login100-form validate-form" @submit.prevent="doLogin(user)">
					<span class="login100-form-logo">
						<img alt="" src="/images/logo-ticketshop.png">
					</span>
					<span class="login100-form-title p-b-34 p-t-27">
						Iniciar sesion
					</span>
					<div class="wrap-input100 validate-input" data-validate="Ingrese su email">
						<input class="input100" type="text" required="" name="email" id="email" v-model="user.email" placeholder="Correo electronico">
						<i class="material-icons focus-input1001">email</i>
					</div>
					<div class="wrap-input100 validate-input" data-validate="Ingrese su Contraseña">
						<input class="input100" type="password" required="" name="password" id="password" v-model="user.password" placeholder="Contraseña">
						<i class="material-icons focus-input1001">vpn_key</i>
					</div>
					<div class="container-login100-form-btn">
						<button class="login100-form-btn">
							Ingresar
						</button>
					</div>
					<div class="text-center p-t-50">
						<router-link class="txt1" :to="{name: 'passwordReset'}">
							Olvido su contraseña?
						</router-link>
					</div>
				</form>
			</div>
		</div>
	</div>
	</div>
</template>
<script>
//import {loginRegister} from '../assets/assets/js/pages/examples/login-register.js';
 
	export default{
		beforeRouteEnter (to, from, next) {
		  next(vm => {
            //vm.$store.dispatch('fetchAccessToken');
		    if(vm.$store.state.accessToken != null){
		    	next('/');
		    }
		  })
		},
		created(){
			document.title = 'Iniciar sesion - TicketShop';
			$(document).ready(function(){
				$("body").removeClass("light");
				$("body").addClass("login-page");
				//loginRegister();
			});
		},	
		data(){
			return{
				user: {
					email: null,
					password: null
				}
				
			}
		},
		methods: {
			doLogin(){
				this.$store.dispatch('doLogin',this.user);
			},
		},

	}
</script>
<style scoped>
input:-webkit-autofill {
        box-shadow: inset 0 0 0 50px #7d690f !important;
}
.wrap-login100 {
    background: -webkit-linear-gradient(top, #000000, #cead1a) !important;
}
.limiter{
	background:url('/images/fondo-login.jpg') !important;
	    background-size: cover !important;
    background-position: center center !important;
}
.container-login100::before {
background-color:transparent !important;
}
</style>