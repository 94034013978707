<template>
	<div>
		 <v-app id="inspire" v-if="tasas != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-balance-scale"></i><strong> Listado de</strong> Tasas</h2>
                            <div class="text-right">
                            <button @click="setNullTasa" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalTasa">NUEVA TASA</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="tasas" :fields="json_fields" worksheet="Listado de tasas" name="tasas.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='tasas'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado tasas...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td class="text-xs-center">{{ props.item.codigo_moneda_alta }}</td>
      <td class="text-xs-center">{{ props.item.codigo_moneda_baja }}</td>
      <td class="text-xs-center">{{ props.item.valor }}</td>
      <td class="text-xs-center">{{ props.item.activo }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewTasa(props.item.id)"
           data-toggle="modal" data-target="#modalTasa">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteTasa(props.item.id)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalTasa" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalTasa }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchMonedas" class="form-control"
                                    placeholder="Buscar monedas..." v-model="keyMonedas" @keyup.enter="searchMonedas">
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <label for="codigoMonedaAltaTasa" v-if="codigoMonedaAltaTasa != null">Codigo moneda alta</label>
                          <div class="form-group" v-if="codigoMonedaAltaTasa != null">
                            <div class="form-line">
                                <input type="text" id="codigoMonedaAltaTasa" class="form-control"
                                    placeholder="Codigo moneda alta" :value="codigoMonedaAltaTasa">
                            </div>
                        </div>
                        <div class="form-group" v-if="(monedas && monedas.length > 0)">
                        <v-select
                          :items="monedas"
                           item-text="descripcion"
                           item-value="codigo_moneda"
                           v-model="monedaAlta"
                          label="Seleccione una moneda alta"
                        ></v-select>
                    </div>
                       </div>
                        <div class="col-md-6 col-lg-6">
                            <label for="codigoMonedaBajaTasa" v-if="codigoMonedaBajaTasa != null">Codigo moneda baja</label>
                          <div class="form-group" v-if="codigoMonedaBajaTasa != null">
                            <div class="form-line">
                                <input type="text" id="codigoMonedaBajaTasa" class="form-control"
                                    placeholder="Codigo moneda baja" :value="codigoMonedaBajaTasa">
                            </div>
                        </div>
                        <div class="form-group" v-if="(monedas && monedas.length > 0)">
                        <v-select
                          :items="monedas"
                           item-text="descripcion"
                           item-value="codigo_moneda"
                           v-model="monedaBaja"
                          label="Seleccione una moneda baja"
                        ></v-select>
                    </div>
                       </div>
                          <label for="valorTasa">Valor</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="valorTasa" class="form-control"
                                    placeholder="Valor de la tasa" required="" v-model="valorTasa">
                            </div>
                        </div>
                           <label for="estadoTasa">Estado</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="estadoTasa" class="form-control"
                                    placeholder="Estado de la tasa" required="" v-model="estadoTasa">
                            </div>
                        </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info waves-effect" @click="saveTasa">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('tasa/listTasas', 1);
        },
		created(){
           document.title = 'Tasas - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('tasa/listTasas', 1);
        },
        data(){
        	return {
        		titleModalTasa: null,
                keyMonedas: null,
        		codigoMonedaAltaTasa: null,
                monedaAlta: null,
        		estadoTasa: null,
                codigoMonedaBajaTasa: null,
                monedaBaja: null,
                valorTasa: null,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id'
		          },
		          {text: 'Codigo moneda alta', value: 'codigo_moneda_alta',align: 'center' },
                  {text: 'Codigo moneda baja', value: 'codigo_moneda_baja',align: 'center' },
                  {text: 'Valor', value: 'valor',align: 'center' },
		          {text: 'Estado', value: 'activo',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id',
            'Codigo moneda alta': 'codigo_moneda_alta',
            'Codigo moneda baja': 'codigo_moneda_baja',
            'Valor': 'valor',
            'Estado': 'activo',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('tasa/listTasas', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	tasas(){
        		return this.$store.getters['tasa/listTasas'];
        	},
        	tasa(){
        		return this.$store.getters['tasa/tasa'];
        	},
		    pagination(){
        		return this.$store.getters['tasa/pagination'];
		      },
		     pages () {
		        return this.$store.getters['tasa/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['tasa/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['tasa/loading'];
		      },
                monedas(){
                return this.$store.getters['moneda/listMonedas'];
              },
        },
        methods: {
        	deleteTasa(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar la tasa?",
			        text: "La tasa se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarla!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('tasa/deleteTasa',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	saveTasa(){
        		if((this.codigoMonedaAltaTasa != null || this.monedaAlta != null) && 
                    (this.codigoMonedaBajaTasa != null || this.monedaBaja != null) && 
                    (this.estadoTasa != '' && this.estadoTasa != null) && 
                    (this.valorTasa != '' && this.valorTasa != null)){ 
        		if(this.tasa){
                    var monedaAltaUpdate = null;
                    if(this.monedaAlta != null && this.monedaAlta != ''){
                        monedaAltaUpdate = this.monedaAlta;
                    }else{
                        monedaAltaUpdate = this.tasa.codigo_moneda_alta;
                    }
                    var monedaBajaUpdate = null;
                    if(this.monedaBaja != null && this.monedaBaja != ''){
                        monedaBajaUpdate = this.monedaBaja;
                    }else{
                        monedaBajaUpdate = this.tasa.codigo_moneda_baja;
                    }
        			this.$store.dispatch('tasa/updateTasa',
                        {
                            id: this.tasa.id,
                            codigo_moneda_alta: monedaAltaUpdate,
                            codigo_moneda_baja: monedaBajaUpdate,
                            activo: this.estadoTasa,
                            valor: this.valorTasa
                        });
        			//console.log('estamos editando un tasa');
        			$('#modalTasa').modal('hide');
        		}else{	
        			this.$store.dispatch('tasa/newTasa',
                        {
                            codigo_moneda_alta: this.monedaAlta,
                            codigo_moneda_baja: this.monedaBaja,
                            activo: this.estadoTasa,
                            valor: this.valorTasa
                        });
        			//console.log('estamos guardando un tasa');
        			$('#modalTasa').modal('hide');
        		}
        	}
        		
        	},
        	viewTasa(id){
        	    this.$store.commit('tasa/viewTasa',id);
        	    this.codigoMonedaAltaTasa = this.tasa.codigo_moneda_alta;
                this.codigoMonedaBajaTasa = this.tasa.codigo_moneda_baja;
                this.keyMonedas = null;
                this.monedaAlta = null;
                this.monedaBaja = null;
        	    this.estadoTasa = this.tasa.activo;
                this.valorTasa = this.tasa.valor;
        	    this.titleModalTasa = `Editar Tasa`;
        	},
        	setNullTasa(){
        		this.$store.commit('tasa/viewTasa',null);
        		this.codigoMonedaAltaTasa = null;
                this.codigoMonedaBajaTasa = null;
                this.keyMonedas = null;
                this.monedaAlta = null;
                this.monedaBaja = null;
                this.estadoTasa = null;
                this.valorTasa = null;
        		this.titleModalTasa = 'Nueva Tasa';
        	},
	         changePage(page){
    		  this.$store.commit('tasa/changePage',page)
	          this.$store.dispatch('tasa/listTasas',page);
	        },
            searchMonedas(){
                this.$store.dispatch('moneda/searchMonedas', this.keyMonedas);
            },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>