<template>
	<div>
		 <v-app id="inspire" v-if="preventas != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-hand-holding-usd"></i><strong> Listado de</strong> Preventas</h2>
                            <div class="text-right">
                            <button @click="setNullPreventa" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalPreventa">NUEVA PREVENTA</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="preventas" :fields="json_fields" worksheet="Listado de preventas" name="preventas.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='preventas'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado preventas...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td class="text-xs-center">{{ props.item.nombre }}</td>
      <td class="text-xs-center">{{ props.item.fecha_inicio }}</td>
      <td class="text-xs-center">{{ props.item.fecha_fin }}</td>
      <td class="text-xs-center">{{ props.item.evento.nombre }}</td>
      <td class="text-xs-center">{{ props.item.activo }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewPreventa(props.item.id)"
           data-toggle="modal" data-target="#modalPreventa">
            edit
          </v-icon>
          <v-icon
            small
            @click="deletePreventa(props.item.id)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalPreventa" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalPreventa }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                        <label for="namePreventa">Nombre</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="namePreventa" class="form-control"
                                    placeholder="Nombre de la preventa" required="" v-model="namePreventa">
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <label for="fechaInicioPreventa">Fecha inicio</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="fechaInicioPreventa" class="form-control datepicker"
                                    placeholder="AAAA-MM-DD" required="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                          <label for="fechaFinPreventa">Fecha fin</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="fechaFinPreventa" class="form-control datepicker"
                                    placeholder="AAAA-MM-DD" required="">
                            </div>
                        </div>
                    </div>
                        <label for="estadoPreventa">Estado</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="estadoPreventa" class="form-control"
                                    placeholder="Estado de la preventa" required="" v-model="estadoPreventa">
                            </div>
                        </div>
                             <label for="eventoPreventa" v-if="eventoPreventa != null">Evento</label>
                          <div class="form-group" v-if="eventoPreventa != null">
                            <div class="form-line">
                                <input type="text" id="eventoPreventa" class="form-control"
                                    placeholder="Evento de la preventa" :value="eventoPreventa">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchEventos" class="form-control"
                                    placeholder="Buscar eventos..." v-model="keyEventos" @keyup.enter="searchEventos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(eventos && eventos.length > 0)">
                        <v-select
                          :items="eventos"
                           item-text="nombre"
                           item-value="id"
                           v-model="evento"
                          label="Seleccione un evento"
                        ></v-select>
                    </div>
                    
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-info waves-effect" @click="savePreventa">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('preventa/listPreventas', 1);
        },
		created(){
           document.title = 'Preventas - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('preventa/listPreventas', 1);
        },
        data(){
        	return {
        		titleModalPreventa: null,
        		namePreventa: null,
        		estadoPreventa: false,
                eventoPreventa: null,
                evento: null,
                keyEventos: null,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id'
		          },
		          {text: 'Nombre', value: 'nombre',align: 'center' },
                  {text: 'Fecha inicio', value: 'fecha_inicio',align: 'center' },
                  {text: 'Fecha Fin', value: 'fecha_fin',align: 'center' },
                  {text: 'Evento', value: 'evento.nombre',align: 'center' },
		          {text: 'Estado', value: 'activo',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id',
            'Nombre': 'nombre',
            'Fecha inicio': 'fecha_inicio',
            'Fecha Fin': 'fecha_fin',
            'Evento': 'evento.nombre',
            'Estado': 'activo',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('preventa/listPreventas', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	preventas(){
        		return this.$store.getters['preventa/listPreventas'];
        	},
        	preventa(){
        		return this.$store.getters['preventa/preventa'];
        	},
		    pagination(){
        		return this.$store.getters['preventa/pagination'];
		      },
		     pages () {
		        return this.$store.getters['preventa/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['preventa/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['preventa/loading'];
		      },
                eventos(){
                return this.$store.getters['evento/listEventos'];
              },
        },
        methods: {
        	deletePreventa(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar la preventa?",
			        text: "La preventa se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarla!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('preventa/deletePreventa',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	savePreventa(){
        		if((this.namePreventa != '' && this.namePreventa != null) &&
                    (this.estadoPreventa != '' && this.estadoPreventa != null) &&
                    ((this.eventoPreventa != '' && this.eventoPreventa != null) 
                        || (this.evento != '' && this.evento != null)) &&
                    ($("#fechaInicioPreventa").val() != '' && $("#fechaFinPreventa").val() != '')){ 
        		if(this.preventa){
                      var eventoUpdate = null;
                    if(this.evento != null && this.evento != ''){
                        eventoUpdate = this.evento;
                    }else{
                        eventoUpdate = this.preventa.id_evento;
                    }
        			this.$store.dispatch('preventa/updatePreventa',
                        {
                            id: this.preventa.id,
                            nombre: this.namePreventa,
                            activo: this.estadoPreventa,
                            id_evento: eventoUpdate,
                            fecha_inicio: $("#fechaInicioPreventa").val(),
                            fecha_fin: $("#fechaFinPreventa").val()
                        }
                        );
        			//console.log('estamos editando un preventa');
        			$('#modalPreventa').modal('hide');
        		}else{	
        			this.$store.dispatch('preventa/newPreventa',
                         {
                            nombre: this.namePreventa,
                            activo: this.estadoPreventa,
                            id_evento: this.evento,
                            fecha_inicio: $("#fechaInicioPreventa").val(),
                            fecha_fin: $("#fechaFinPreventa").val()
                        }
                        );
        			//console.log('estamos guardando un preventa');
        			$('#modalPreventa').modal('hide');
        		}
        	}
        		
        	},
        	viewPreventa(id){
        	    this.$store.commit('preventa/viewPreventa',id);
        	    this.namePreventa = this.preventa.nombre;
        	    this.estadoPreventa = this.preventa.activo;
                $("#fechaInicioPreventa").val(this.preventa.fecha_inicio);
                $("#fechaFinPreventa").val(this.preventa.fecha_fin);
                this.eventoPreventa = this.preventa.evento.nombre;
                this.keyEventos = null;
                this.evento = null;
        	    this.titleModalPreventa = `Editar Preventa: ${this.preventa.nombre}`;
        	},
        	setNullPreventa(){
        		this.$store.commit('preventa/viewPreventa',null);
        		this.namePreventa = null;
                this.estadoPreventa = null;
                $("#fechaInicioPreventa").val('');
                $("#fechaFinPreventa").val('');
                this.eventoPreventa = null;
                this.keyEventos = null;
                this.evento = null;
        		this.titleModalPreventa = 'Nuevo Preventa';
        	},
	         changePage(page){
    		  this.$store.commit('preventa/changePage',page)
	          this.$store.dispatch('preventa/listPreventas',page);
	        },
             searchEventos(){
                this.$store.dispatch('evento/searchEventos', this.keyEventos);
            },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>