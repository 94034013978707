<template>
    <div>
         <v-app id="inspire" v-if="boletasEventos != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-ticket-alt"></i><strong> Listado de</strong> Boletas por Evento</h2>
                            <div class="text-right">
                            <button @click="setNullBoletaEvento" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalBoletaEvento">NUEVA BOLETA</button>
                        </div>
                        <div>
                             <export-excel style="width: fit-content;" :data="boletasEventos" :fields="json_fields" worksheet="Listado de boletas por evento" name="boletasEventos.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
            must-sort
            :items='boletasEventos'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado boletas por evento...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td class="text-xs-center">{{ props.item.evento.nombre }}</td>
      <td class="text-xs-center">{{ props.item.puesto.numero }}</td>
      <td class="text-xs-center">{{ props.item.precio_venta }}</td>
      <td class="text-xs-center">{{ props.item.precio_servicio }}</td>
      <td class="text-xs-center">{{ props.item.impuesto }}</td>
      <td class="text-xs-center">{{ props.item.codigo_moneda.codigo_moneda }} - {{ props.item.codigo_moneda.descripcion }}</td>
      <td class="text-xs-center">{{ props.item.status }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewBoletaEvento(props.item.id)"
           data-toggle="modal" data-target="#modalBoletaEvento">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteBoletaEvento(props.item.id)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalBoletaEvento" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalBoletaEvento }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                      <div class="col-md-6 col-lg-6">
                        <label for="precioVentaBoleta">Precio venta</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" step="any" id="precioVentaBoleta" class="form-control"
                                    placeholder="Precio venta" required="" v-model="precioVentaBoleta">
                            </div>
                        </div>
                      </div>
                       <div class="col-md-6 col-lg-6">
                          <label for="precioServicioBoleta">Precio servicio</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" step="any" id="precioServicioBoleta" class="form-control"
                                    placeholder="Precio servicio" required="" v-model="precioServicioBoleta">
                            </div>
                        </div>
                       </div>
                       <div class="col-md-6 col-lg-6">
                          <label for="impuestoBoleta">Impuesto</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" step="any" id="impuestoBoleta" class="form-control"
                                    placeholder="Impuesto" required="" v-model="impuestoBoleta">
                            </div>
                        </div>
                       </div>
                         <div class="col-md-6 col-lg-6">
                          <label for="statusBoleta">Estado</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="statusBoleta" class="form-control"
                                    placeholder="Estado" required="" v-model="statusBoleta">
                            </div>
                        </div>
                       </div>
                       <div class="col-lg-12 col-md-12">
                              <label for="eventoBoleta" v-if="eventoBoleta != null">Evento</label>
                          <div class="form-group" v-if="eventoBoleta != null">
                            <div class="form-line">
                                <input type="text" id="eventoBoleta" class="form-control"
                                    placeholder="Evento" :value="eventoBoleta">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchEventos" class="form-control"
                                    placeholder="Buscar eventos..." v-model="keyEventos" @keyup.enter="searchEventos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(eventos && eventos.length > 0)">
                        <v-select
                          :items="eventos"
                           item-text="nombre"
                           item-value="id"
                           v-model="evento"
                          label="Seleccione un evento"
                        ></v-select>
                    </div>
                       </div>
                       <div class="col-md-6 col-lg-6">
                            <label for="puestoBoleta" v-if="puestoBoleta != null">Puesto</label>
                          <div class="form-group" v-if="puestoBoleta != null">
                            <div class="form-line">
                                <input type="text" id="puestoBoleta" class="form-control"
                                    placeholder="Puesto" :value="puestoBoleta">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="searchPuestos" class="form-control"
                                    placeholder="Buscar puestos..." v-model="keyPuestos" @keyup.enter="searchPuestos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(puestos && puestos.length > 0)">
                        <v-select
                          :items="puestos"
                           item-text="numero"
                           item-value="id"
                           v-model="puesto"
                          label="Seleccione un puesto"
                        ></v-select>
                    </div>
                       </div>
                       <div class="col-md-6 col-lg-6">
                            <label for="monedaBoleta" v-if="monedaBoleta != null">Moneda</label>
                          <div class="form-group" v-if="monedaBoleta != null">
                            <div class="form-line">
                                <input type="text" id="monedaBoleta" class="form-control"
                                    placeholder="Moneda" :value="monedaBoleta">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchMonedas" class="form-control"
                                    placeholder="Buscar monedas..." v-model="keyMonedas" @keyup.enter="searchMonedas">
                            </div>
                        </div>
                        <div class="form-group" v-if="(monedas && monedas.length > 0)">
                        <v-select
                          :items="monedas"
                           item-text="descripcion"
                           item-value="codigo_moneda"
                           v-model="moneda"
                          label="Seleccione una moneda"
                        ></v-select>
                    </div>
                       </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info waves-effect" @click="saveBoletaEvento">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
    </div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

    export default {
        beforeCreate(){
           this.$store.dispatch('boletaevento/listBoletasEventos', 1);
        },
        created(){
           document.title = 'Boletas por evento - TicketShop';
        },
        async mounted(){
           //adminApp() 
           this.$store.dispatch('boletaevento/listBoletasEventos', 1);
        },
        data(){
            return {
                titleModalBoletaEvento: null,
                precioVentaBoleta: null,
                precioServicioBoleta: null,
                statusBoleta: null,
                impuestoBoleta: null,
                eventoBoleta: null,
                evento: null,
                keyEventos: null,
                monedaBoleta: null,
                moneda: null,
                keyMonedas: null,
                puestoBoleta: null,
                puesto: null,
                keyPuestos: null,
                search: '',
                headers: [
                  {
                    text: 'Id',
                    align: 'left',
                    sortable: false,
                    value: 'id'
                  },
                  {text: 'Evento', value: 'evento.nombre',align: 'center' },
                  {text: 'Puesto', value: 'puesto.numero',align: 'center' },
                  {text: 'Precio venta', value: 'precio_venta',align: 'center' },
                  {text: 'Precio servicio', value: 'precio_servicio',align: 'center' },
                  {text: 'Impuesto', value: 'impuesto',align: 'center' },
                  {text: 'Moneda', value: 'codigo_moneda',align: 'center' },
                  {text: 'Estado', value: 'status',align: 'center' },
                  {text: 'Acciones',value: '', align: 'right'}
             ],
             json_fields: {
            'Id': 'id',
            'Evento': 'evento.nombre',
            'Puesto': 'puesto.numero',
            'Precio venta': 'precio_venta',
            'Precio servicio': 'precio_servicio',
            'Impuesto': 'impuesto',
            'Moneda': 'codigo_moneda',
            'Estado': 'status',

        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
            }
        },
        watch: {
            pagination: {
              async handler () {
                this.$store.dispatch('boletaevento/listBoletasEventos', this.pagination.page);
              },
              deep: true
            }
          },
        computed: {
            boletasEventos(){
                return this.$store.getters['boletaevento/listBoletasEventos'];
            },
            boletaEvento(){
                return this.$store.getters['boletaevento/boletaEvento'];
            },
            pagination(){
                return this.$store.getters['boletaevento/pagination'];
              },
             pages () {
                return this.$store.getters['boletaevento/pages'];
              },
              totalItemCount(){
                return this.$store.getters['boletaevento/totalItemCount'];
              },
              loading(){
                    return this.$store.getters['boletaevento/loading'];
              },
               eventos(){
                return this.$store.getters['evento/listEventos'];
              },
                puestos(){
                return this.$store.getters['puesto/listPuestos'];
              },
                monedas(){
                return this.$store.getters['moneda/listMonedas'];
              },
        },
        methods: {
            deleteBoletaEvento(id){
                var self = this;
                    swal({
                    title: "¿Estas seguro de eliminar la boleta?",
                    text: "La boleta se eliminara permanentemente!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Si, Eliminarla!",
                    cancelButtonText: "No, cancelar!",
                    closeOnConfirm: false,
                    closeOnCancel: false
                }, function (isConfirm) {
                    if (isConfirm) {
                        self.$store.dispatch('boletaevento/deleteBoletaEvento',id);
                    } else {
                        swal("Cancelado", "Se ha cancelado la operacion.", "error");
                    }
                });
            },
            saveBoletaEvento(){
                if((this.precioVentaBoleta != null) && 
                  (this.precioServicioBoleta != null) &&
                  (this.impuestoBoleta != null) &&
                  (this.statusBoleta != null) &&
                  (this.eventoBoleta != null || this.evento != null) &&
                  (this.puestoBoleta != null || this.puesto != null) &&
                  (this.monedaBoleta != null || this.moneda != null)){ 
                if(this.boletaEvento){
                     var eventoUpdate = null;
                    if(this.evento != null && this.evento != ''){
                        eventoUpdate = this.evento;
                    }else{
                        eventoUpdate = this.boletaEvento.id_evento;
                    }
                       var puestoUpdate = null;
                    if(this.puesto != null && this.puesto != ''){
                        puestoUpdate = this.puesto;
                    }else{
                        puestoUpdate = this.boletaEvento.id_puesto;
                    }
                      var monedaUpdate = null;
                    if(this.moneda != null && this.moneda != ''){
                        monedaUpdate = this.moneda;
                    }else{
                        monedaUpdate = this.boletaEvento.codigo_moneda.codigo_moneda;
                    }
                    this.$store.dispatch('boletaevento/updateBoletaEvento',
                       {
                          id: this.boletaEvento.id,
                          id_evento: eventoUpdate,
                          id_puesto: puestoUpdate,
                          precio_venta: this.precioVentaBoleta,
                          precio_servicio: this.precioServicioBoleta,
                          impuesto: this.impuestoBoleta,
                          status: this.statusBoleta,
                          codigo_moneda: monedaUpdate
                       });
                    //console.log('estamos editando un boletaEvento');
                    $('#modalBoletaEvento').modal('hide');
                }else{  
                    this.$store.dispatch('boletaevento/newBoletaEvento',
                       {
                          id_evento: this.evento,
                          id_puesto: this.puesto,
                          precio_venta: this.precioVentaBoleta,
                          precio_servicio: this.precioServicioBoleta,
                          impuesto: this.impuestoBoleta,
                          status: this.statusBoleta,
                          codigo_moneda: this.moneda
                       });
                    //console.log('estamos guardando un boletaEvento');
                    $('#modalBoletaEvento').modal('hide');
                }
            }
                
            },
            viewBoletaEvento(id){
                this.$store.commit('boletaevento/viewBoletaEvento',id);
                this.precioVentaBoleta = this.boletaEvento.precio_venta;
                this.precioServicioBoleta = this.boletaEvento.precio_servicio;
                this.statusBoleta = this.boletaEvento.status;
                this.impuestoBoleta = this.boletaEvento.impuesto;
                this.eventoBoleta = this.boletaEvento.evento.nombre;
                this.evento = null;
                this.keyEventos = null;
                this.puestoBoleta = this.boletaEvento.puesto.numero;
                this.puesto = null;
                this.keyPuestos = null;
                if(this.boletaEvento.codigo_moneda != null){ 
                    this.monedaBoleta = `${this.boletaEvento.codigo_moneda.codigo_moneda} ${this.boletaEvento.codigo_moneda.descripcion}`;
                }else{
                    this.monedaBoleta = null;
                }
                this.moneda = null;
                this.keyMonedas = null;
                this.titleModalBoletaEvento = `Editar Boleta`;
            },
            setNullBoletaEvento(){
                this.$store.commit('boletaevento/viewBoletaEvento',null);
                this.precioVentaBoleta = null;
                this.precioServicioBoleta = null;
                this.statusBoleta = null;
                this.impuestoBoleta = null;
                this.eventoBoleta = null;
                this.evento = null;
                this.keyEventos = null;
                this.puestoBoleta = null;
                this.puesto = null;
                this.keyPuestos = null;
                this.monedaBoleta = null;
                this.moneda = null;
                this.keyMonedas = null;
                this.titleModalBoletaEvento = 'Nueva Boleta';
            },
             changePage(page){
              this.$store.commit('boletaevento/changePage',page)
              this.$store.dispatch('boletaevento/listBoletasEventos',page);
            },
              searchEventos(){
                this.$store.dispatch('evento/searchEventos', this.keyEventos);
            },
             searchPuestos(){
                this.$store.dispatch('puesto/searchPuestos', this.keyPuestos);
            },
             searchMonedas(){
                this.$store.dispatch('moneda/searchMonedas', this.keyMonedas);
            },
        }
    }
</script>
<style scoped>
.changes{
    margin-right: 5px;
    margin-bottom: 12px;
}
</style>