<template>
	<div>
			<div class="limiter">
		<div class="container-login100">
			<div class="wrap-login100">
					<span class="login100-form-logo">
						<img alt="" src="/images/logo-ticketshop.png">
					</span>
					<span class="login100-form-title p-b-34 p-t-27">
						Restablecer contraseña
					</span>
					<div class="text-center">
						<div v-if="tokenResetPassword == null">
							<div class="alert alert-danger">
								<p><strong><i class="material-icons">error</i></strong>Este token de reestablecimiento de contraseña es invalido!</p>
							</div>
						</div>
						
					</div>
					<form @submit.prevent="" v-if="tokenResetPassword">
						<p class="txt1 p-b-20">
							Ingrese los siguientes datos.
						</p>
					<div class="wrap-input100 validate-input" data-validate="Ingrese su email">
						<input class="input100" type="text" required="" name="email" id="email" v-model="security.email" placeholder="Correo electronico">
						<i class="material-icons focus-input1001">email</i>
					</div>
			       <div class="wrap-input100 validate-input" data-validate="Ingrese su nueva contraseña">
                        <input type="password" id="password_new" class="input100" placeholder="Nueva contraseña" v-model="security.password_new" required="">
						<i class="material-icons focus-input1001">vpn_key</i>
                    </div>
                      <div class="wrap-input100 validate-input" data-validate="Confirmar nueva contraseña">
                        <input type="password" id="password_new_confirmed" class="input100" placeholder="Confirmar Nueva contraseña" v-model="security.password_new_confirmed" required="">
						<i class="material-icons focus-input1001">vpn_key</i>
                    </div>
                    <div class="alert alert-danger" v-if="(security.password_new != null && security.password_new_confirmed != null) && (security.password_new != security.password_new_confirmed)">
                        <i class="material-icons">warning</i> Las contraseñas nuevas deben coincidir!
                    </div>
					<div class="container-login100-form-btn">
						<button v-if="(security.password_new != null && security.password_new_confirmed != null) && (security.password_new === security.password_new_confirmed) && (tokenResetPassword)" class="login100-form-btn" @click="resetPasswordToken">
							Restablecer contraseña
						</button>
					</div>
				</form>
					<div class="text-center p-t-50">
						<router-link class="txt1" :to="{name: 'login'}">
							Iniciar sesion
						</router-link>
					</div>
			</div>
		</div>
	</div>
	</div>
</template>
<script>
	
	export default{
		beforeRouteEnter (to, from, next) {
		  next(vm => {
            vm.$store.dispatch('fetchAccessToken');
		    if(vm.$store.state.accessToken != null){
		    	next('/');
		    }
		  });
		},
		created(){
			document.title = 'Restablecer contraseña - TicketShop';
			$(document).ready(function(){
				$("body").removeClass("light");
				$("body").addClass("login-page");
			});
			this.$store.dispatch('findTokenPasswordReset',this.$route.params.token);
		},	
		data(){
			return {
				security: {
					email: null,
					password_new: null,
					password_new_confirmed: null,
					token: this.$route.params.token,
				}
			}
		},
		methods: {
			resetPasswordToken(){
					this.$store.dispatch('resetPasswordToken',this.security);
			}
		},
		computed: { 
		tokenResetPassword(){
			return this.$store.getters.tokenResetPassword;
		},
	}
	}
</script>
<style scoped>
input:-webkit-autofill {
        box-shadow: inset 0 0 0 50px #7d690f !important;
}
.wrap-login100 {
    background: -webkit-linear-gradient(top, #000000, #cead1a) !important;
}
.limiter{
	background:url('/images/fondo-login.jpg') !important;
	    background-size: cover !important;
    background-position: center center !important;
}
.container-login100::before {
background-color:transparent !important;
}
</style>