export const mutations = {
	loginStart: state => state.loggingIn = true,
    loginStop: (state, errorMessage) => {
      state.loggingIn = false;
      state.loginError = errorMessage;
    },
    updateAccessToken: (state, accessToken) => {
      state.accessToken = accessToken;
    },
    logout: (state) => {
      state.accessToken = null;
    },
    changeLoader: (state,status) => {
        state.loader = status;
    },
    validateToken: (state,status) => {
        state.validateToken = status;
    },
    tokenResetPassword: (state,status) => {
        state.tokenResetPassword = status;
    },
};