<template>
	<div>
		 <v-app id="inspire" v-if="grupoVendedoresPunto != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-users"></i><strong> Listado de</strong> Grupo de vendedores por punto</h2>
                            <div class="text-right">
                            <button @click="setNullGrupoVendedorPunto" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalGrupoVendedorPunto">NUEVO GRUPO VENDEDOR POR PUNTO</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="grupoVendedoresPunto" :fields="json_fields" worksheet="Listado de grupo de vendedores por punto" name="grupoVendedoresPunto.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='grupoVendedoresPunto'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado grupo de vendedores por punto...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id_grupo_vendedores }}</td>
      <td class="text-xs-center">{{ props.item.grups_vendedore.nombre }}</td>
      <td class="text-xs-center">{{ props.item.punto_ventum.nombre_razon }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewGrupoVendedorPunto(props.item.id_grupo_vendedores,props.item.id_punto_venta)"
           data-toggle="modal" data-target="#modalGrupoVendedorPunto">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteGrupoVendedorPunto(props.item.id_grupo_vendedores)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalGrupoVendedorPunto" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalGrupoVendedorPunto }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                        <label for="grupoVendedoresActual" v-if="grupoVendedoresActual != null">Grupo de vendedores</label>
                          <div class="form-group" v-if="grupoVendedoresActual != null">
                            <div class="form-line">
                                <input type="text" id="grupoVendedoresActual" class="form-control"
                                    placeholder="Grupo de vendedores" :value="grupoVendedoresActual">
                            </div>
                        </div>
                        <div class="form-group" v-if="grupoVendedoresActual == null">
                            <div class="form-line">
                                <input type="text" id="searchGrupoVendedores" class="form-control"
                                    placeholder="Buscar grupos de vendedores..." v-model="keyGrupoVendedores" @keyup.enter="searchGrupoVendedores">
                            </div>
                        </div>
                        <div class="form-group" v-if="(grupoVendedores && grupoVendedores.length > 0) && (grupoVendedoresActual == null)">
                        <v-select
                          :items="grupoVendedores"
                           item-text="nombre"
                           item-value="id"
                           v-model="grupoVendedor"
                          label="Seleccione un grupo de vendedores"
                        ></v-select>
                    </div>
                      <label for="puntoVentaActual" v-if="puntoVentaActual != null">Punto de venta</label>
                          <div class="form-group" v-if="puntoVentaActual != null">
                            <div class="form-line">
                                <input type="text" id="puntoVentaActual" class="form-control"
                                    placeholder="Punto de venta" :value="puntoVentaActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchPuntosVenta" class="form-control"
                                    placeholder="Buscar puntos de venta..." v-model="keyPuntosVenta" @keyup.enter="searchPuntosVenta">
                            </div>
                        </div>
                        <div class="form-group" v-if="(puntosVenta && puntosVenta.length > 0)">
                        <v-select
                          :items="puntosVenta"
                           item-text="nombre_razon"
                           item-value="id"
                           v-model="puntoVenta"
                          label="Seleccione un punto de venta"
                        ></v-select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button"  v-if="((grupoVendedoresActual != null || grupoVendedor != null) && (puntoVentaActual != null || puntoVenta != null))" class="btn btn-info waves-effect" @click="saveGrupoVendedorPunto">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('grupovendedorpunto/listGrupoVendedoresPunto', 1);
        },
		created(){
           document.title = 'Grupo de vendedores por punto - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('grupovendedorpunto/listGrupoVendedoresPunto', 1);
        },
        data(){
        	return {
        		titleModalGrupoVendedorPunto: null,
                grupoVendedoresActual: null,
                puntoVentaActual: null,
                grupoVendedor: null,
                puntoVenta: null,
                keyGrupoVendedores: null,
                keyPuntosVenta: null,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id_grupo_vendedores'
		          },
		          {text: 'Grupo de vendedores', value: 'grups_vendedore.nombre',align: 'center' },
                  {text: 'Punto de venta', value: 'punto_ventum.nombre_razon',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id_grupo_vendedores',
            'Grupo de vendedores': 'grups_vendedore.nombre',
            'Punto de venta': 'punto_ventum.nombre_razon',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('grupovendedorpunto/listGrupoVendedoresPunto', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	grupoVendedoresPunto(){
        		return this.$store.getters['grupovendedorpunto/listGrupoVendedoresPunto'];
        	},
        	grupoVendedorPunto(){
        		return this.$store.getters['grupovendedorpunto/grupoVendedorPunto'];
        	},
		    pagination(){
        		return this.$store.getters['grupovendedorpunto/pagination'];
		      },
		     pages () {
		        return this.$store.getters['grupovendedorpunto/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['grupovendedorpunto/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['grupovendedorpunto/loading'];
		      },
               grupoVendedores(){
                return this.$store.getters['grupovendedor/listGrupoVendedores'];
              },
               puntosVenta(){
                return this.$store.getters['puntoventa/listPuntosVenta'];
              },
        },
        methods: {
        	deleteGrupoVendedorPunto(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar el registro?",
			        text: "El registro se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarlo!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('grupovendedorpunto/deleteGrupoVendedorPunto',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	saveGrupoVendedorPunto(){
        		if(((this.grupoVendedoresActual != '' || this.grupoVendedoresActual != null) ||
                    (this.grupoVendedor != '' || this.grupoVendedor != null)) &&
                    ((this.puntoVentaActual != '' || this.puntoVentaActual != null) ||
                    (this.puntoVenta != '' || this.puntoVenta != null))){ 
        		if(this.grupoVendedorPunto){
                    var puntoVentaUpdate = null;
                    if(this.puntoVenta != null && this.puntoVenta != ''){
                        puntoVentaUpdate = this.puntoVenta;
                    }else{
                        puntoVentaUpdate = this.grupoVendedorPunto.id_punto_venta;
                    }
        			this.$store.dispatch('grupovendedorpunto/updateGrupoVendedorPunto',{id: this.grupoVendedor.id_grupo_vendedores,id_punto_venta_old: this.grupoVendedorPunto.id_punto_venta,id_punto_venta_new: puntoVentaUpdate})
        			//console.log('estamos editando un fila');
        			$('#modalGrupoVendedorPunto').modal('hide');
        		}else{	
        			this.$store.dispatch('grupovendedorpunto/newGrupoVendedorPunto',{id_grupo_vendedores: this.grupoVendedor,id_punto_venta: this.puntoVenta});
        			//console.log('estamos guardando un fila');
        			$('#modalGrupoVendedorPunto').modal('hide');
        		}
        	}
        		
        	},
        	viewGrupoVendedorPunto(id_grupo_vendedores,id_punto_venta){
        	    this.$store.commit('grupovendedorpunto/viewGrupoVendedorPunto',{id_grupo_vendedores,id_punto_venta});
        	    this.puntoVentaActual = this.grupoVendedorPunto.punto_ventum.nombre_razon;
                this.grupoVendedoresActual = this.grupoVendedorPunto.grups_vendedore.nombre;
                this.keyGrupoVendedores = null;
                this.keyPuntosVenta = null;
                this.grupoVendedor = null;
                this.puntoVenta = null;
        	    this.titleModalGrupoVendedorPunto = `Editar Registro: ${this.grupoVendedorPunto.id_grupo_vendedores}`;
        	},
        	setNullGrupoVendedorPunto(){
        		this.$store.commit('grupovendedorpunto/viewGrupoVendedorPunto',{id_grupo_vendedores:null,id_punto_venta:null});
        		this.puntoVentaActual = null;
                this.grupoVendedoresActual = null;
                this.keyGrupoVendedores = null;
                this.keyPuntosVenta = null;
                this.grupoVendedor = null;
                this.puntoVenta = null;
        		this.titleModalGrupoVendedorPunto = 'Nuevo Registro';
        	},
	         changePage(page){
    		  this.$store.commit('grupovendedorpunto/changePage',page)
	          this.$store.dispatch('grupovendedorpunto/listGrupoVendedoresPunto',page);
	        },
            searchGrupoVendedores(){
                this.$store.dispatch('grupovendedor/searchGrupoVendedores', this.keyGrupoVendedores);
            },
            searchPuntosVenta(){
                this.$store.dispatch('puntoventa/searchPuntosVenta', this.keyPuntosVenta);
            },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>