<template>
	<div>
		 <v-app id="inspire" v-if="ciudades != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-map"></i><strong> Listado de</strong> Ciudades</h2>
                            <div class="text-right">
                            <button @click="setNullCiudad" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalCiudad">NUEVO CIUDAD</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="ciudades" :fields="json_fields" worksheet="Listado de ciudades" name="ciudades.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='ciudades'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado ciudades...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td class="text-xs-center">{{ props.item.descripcion }}</td>
      <td class="text-xs-center">{{ props.item.id_departamento }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewCiudad(props.item.id)"
           data-toggle="modal" data-target="#modalCiudad">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteCiudad(props.item.id)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalCiudad" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalCiudad }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                         <label for="nameCiudad">Descripcion</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="nameCiudad" class="form-control"
                                    placeholder="Descripcion del ciudad" required="" v-model="nameCiudad">
                            </div>
                        </div>
                      <label for="departamentoCiudad" v-if="departamentoCiudad != null">Departamento</label>
                          <div class="form-group" v-if="departamentoCiudad != null">
                            <div class="form-line">
                                <input type="text" id="departamentoCiudad" class="form-control"
                                    placeholder="Departamento de la ciudad" :value="departamentoCiudad">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchDepartamentos" class="form-control"
                                    placeholder="Buscar departamentos..." v-model="keyDepartamentos" @keyup.enter="searchDepartamentos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(departamentos && departamentos.length > 0)">
                        <v-select
                          :items="departamentos"
                           item-text="descripcion"
                           item-value="id"
                           v-model="departamento"
                          label="Seleccione un departamento"
                        ></v-select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button"  v-if="(nameCiudad != null && (departamento != null || departamentoCiudad != null))" class="btn btn-info waves-effect" @click="saveCiudad">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('ciudad/listCiudades', 1);
        },
		created(){
           document.title = 'Ciudades - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('ciudad/listCiudades', 1);
        },
        data(){
        	return {
        		titleModalCiudad: null,
        		nameCiudad: null,
                departamentoCiudad: null,
                departamento: null,
                keyDepartamentos: null,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id'
		          },
		          {text: 'Descripcion', value: 'descripcion',align: 'center' },
                  {text: 'Departamento', value: 'id_departamento',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id',
            'Descripcion': 'descripcion',
            'Departamento': 'id_departamento',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('ciudad/listCiudades', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	ciudades(){
        		return this.$store.getters['ciudad/listCiudades'];
        	},
        	ciudad(){
        		return this.$store.getters['ciudad/ciudad'];
        	},
		    pagination(){
        		return this.$store.getters['ciudad/pagination'];
		      },
		     pages () {
		        return this.$store.getters['ciudad/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['ciudad/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['ciudad/loading'];
		      },
               departamentos(){
                return this.$store.getters['departamento/listDepartamentos'];
              },
        },
        methods: {
        	deleteCiudad(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar la ciudad?",
			        text: "La ciudad se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarla!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('ciudad/deleteCiudad',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	saveCiudad(){
        		if(this.nameCiudad != '' || this.nameCiudad != null && 
                    ((this.departamento != '' && this.departamento != null) ||
                     (this.departamentoCiudad != '' || this.departamentoCiudad != null))){ 
        		if(this.ciudad){
                    var departamentoUpdate = null;
                    if(this.departamento != null && this.departamento != ''){
                        departamentoUpdate = this.departamento;
                    }else{
                        departamentoUpdate = this.ciudad.id_departamento;
                    }
        			this.$store.dispatch('ciudad/updateCiudad',{id: this.ciudad.id,descripcion: this.nameCiudad, id_departamento: departamentoUpdate})
        			//console.log('estamos editando un ciudad');
        			$('#modalCiudad').modal('hide');
        		}else{	
        			this.$store.dispatch('ciudad/newCiudad',{descripcion: this.nameCiudad, id_departamento: this.departamento});
        			//console.log('estamos guardando un ciudad');
        			$('#modalCiudad').modal('hide');
        		}
        	}
        		
        	},
        	viewCiudad(id){
        	    this.$store.commit('ciudad/viewCiudad',id);
        	    this.nameCiudad = this.ciudad.descripcion;
                this.departamentoCiudad = this.ciudad.id_departamento;
                this.keyDepartamentos = null;
                this.departamento = null;
        	    this.titleModalCiudad = `Editar Ciudad: ${this.ciudad.descripcion}`;
        	},
        	setNullCiudad(){
        		this.$store.commit('ciudad/viewCiudad',null);
        		this.nameCiudad = null;
                this.departamentoCiudad = null;
                this.keyDepartamentos = null;
                this.departamento = null;
        		this.titleModalCiudad = 'Nueva Ciudad';
        	},
	         changePage(page){
    		  this.$store.commit('ciudad/changePage',page)
	          this.$store.dispatch('ciudad/listCiudades',page);
	        },
            searchDepartamentos(){
                this.$store.dispatch('departamento/searchDepartamentos', this.keyDepartamentos);
            },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>