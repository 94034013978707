import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       ciudades: [],
       ciudad: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listCiudades: (state,getters,rootState) => {
          return state.ciudades;
    },
    ciudad: (state,getters,rootState) => {
        return state.ciudad;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listCiudades: (state,ciudades) => {
          state.ciudades = ciudades.data;
          state.pagination.totalItems = ciudades.total;
          state.pagination.page = ciudades.current_page;
          state.pagination.rowsPerPage = ciudades.per_page;
       },
    listCiudadesSearch: (state,ciudades) => {
            if(ciudades != null){ 
                state.ciudades = ciudades;
            }else{
              state.ciudades = null;
            }
       },
      viewCiudad(state,id){
        let auxCiudad = state.ciudades.filter((item) => item.id == id);
        state.ciudad = auxCiudad[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listCiudades({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}ciudad?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listCiudades',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener las ciudades!", "error");
        console.error(error);
      });

    },
  newCiudad({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}ciudad`,
        {
          id_departamento: payload.id_departamento,
          descripcion: payload.descripcion,
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo la ciudad correctamente!", "success");
        store.dispatch('ciudad/listCiudades',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear la ciudad!", "error");
        console.error(error);
      });
  },
  updateCiudad({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}ciudad/${payload.id}`,
        {
          id_departamento: payload.id_departamento,
          descripcion: payload.descripcion,
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo la ciudad correctamente!", "success");
        store.dispatch('ciudad/listCiudades',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar la ciudad!", "error");
        console.error(error);
      });
  },
  deleteCiudad({state,getters,commit,rootState},ciudad){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}ciudad/${ciudad}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino la ciudad correctamente!", "success");
        store.dispatch('ciudad/listCiudades',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar la ciudad!","error");
        console.log(error);
     });
  },
  searchCiudades({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarCiudad?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listCiudadesSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener las ciudades!", "error");
        console.error(error);
      });

    },
  },
}