import axios from 'axios';
import {configApi} from '../../../api/configApi';
import {router} from '../../../main.js';
import {headers} from '../../../api/headers';
import {store} from '../../index';

export default {
    namespaced: true,
    state: {
      statusUpdateImagenPreventa: false,
      eventosImgPreventa: [],
      paisesHome: null,
    },
    actions: {
      async getImgsPreventaEvento({state,getters,commit,rootState}){
        store.commit('changeLoader', true);
        await axios.get(`${configApi.apiUrl}app/v1/apariencia/listar_eventos`,null,{headers: headers})
        .then(response => {
          state.eventosImgPreventa = response.data.eventos
        })
        .catch(error => {
          swal("Error!", "Error al obtener los eventos de la preventa!", "error");
        })
        .finally(()=> {
          store.commit('changeLoader', false);
        });
      },
      async updateImgPreventa({state,getters,commit,rootState}, payload){
        store.commit('changeLoader', true);
        await axios.post(`${configApi.apiUrl}app/v1/apariencia/actualizar_imagen_preventa_evento`,payload,{headers: headers})
        .then(response => {
          swal("Actualización", response.data.message, "success");
        })
        .catch(error => {
          swal("Error!", "Error al obtener la preventa!", "error");
        })
        .finally(()=> {
          store.commit('changeLoader', false);
        })
      },
      async updateImgDestacadas({state,getters,commit,rootState}, payload){
        store.commit('changeLoader', true);
        await axios.post(`${configApi.apiUrl}app/v1/update_banners_destacados_home`,payload,{headers: headers})
        .then(response => {
          swal("Actualización", response.data.message, "success");
        })
        .catch(error => {
          swal("Error!", "Error al actualizar la imagenes destacadas!", "error");
        })
        .finally(()=> {
          store.commit('changeLoader', false);
        })
      },
      async getBannersDestacados({state,getters,commit,rootState}, payload){
        store.commit('changeLoader', true);
        await axios.get(`${configApi.apiUrl}app/v1/get_banners_destacados_home`,payload,{headers: headers})
        .then(response => {
          console.log(response)
          state.paisesHome = response.data.paises
        })
        .catch(error => {
          swal("Error!", "Error al obtener los banners!", "error");
        })
        .finally(()=> {
          store.commit('changeLoader', false);
        });
      },
    }
  }