import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       eventosCuponeras: [],
       eventoCuponera: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listEventosCuponeras: (state,getters,rootState) => {
          return state.eventosCuponeras;
    },
    eventoCuponera: (state,getters,rootState) => {
        return state.eventoCuponera;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listEventosCuponeras: (state,eventosCuponeras) => {
          state.eventosCuponeras = eventosCuponeras.data;
          state.pagination.totalItems = eventosCuponeras.total;
          state.pagination.page = eventosCuponeras.current_page;
          state.pagination.rowsPerPage = eventosCuponeras.per_page;
       },
      viewEventoCuponera(state,payload){
        let auxEventoCuponera = state.eventosCuponeras.filter((item) => 
          (item.id_evento == payload.id_evento && item.id_cuponera == payload.id_cuponera));
        state.eventoCuponera = auxEventoCuponera[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listEventosCuponeras({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}eventocuponera?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listEventosCuponeras',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los eventos por cuponera!", "error");
        console.error(error);
      });

    },
  newEventoCuponera({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}eventocuponera`,
        {
          id_evento: payload.id_evento,
          id_cuponera: payload.id_cuponera,
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo el evento por cuponera correctamente!", "success");
        store.dispatch('eventocuponera/listEventosCuponeras',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear el evento por cuponera!", "error");
        console.error(error);
      });
  },
  updateEventoCuponera({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}eventocuponera/${payload.id}`,
        {
          id_cuponera_old: payload.id_cuponera_old,
          id_cuponera_new: payload.id_cuponera_new,
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo el evento por cuponera correctamente!", "success");
        store.dispatch('eventocuponera/listEventosCuponeras',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar el evento por cuponera!", "error");
        console.error(error);
      });
  },
  deleteEventoCuponera({state,getters,commit,rootState},eventoCuponera){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}eventocuponera/${eventoCuponera}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino el evento por cuponera correctamente!", "success");
        store.dispatch('eventocuponera/listEventosCuponeras',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar el evento por cuponera!","error");
        console.log(error);
     });
  },
  },
}