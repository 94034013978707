<template>
	<div>
		 <v-app id="inspire" v-if="temporadas != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-calendar-alt"></i><strong> Listado de</strong> Temporadas</h2>
                            <div class="text-right">
                            <button @click="setNullTemporada" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalTemporada">NUEVA TEMPORADA</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="temporadas" :fields="json_fields" worksheet="Listado de temporadas" name="temporadas.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='temporadas'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado temporadas...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td class="text-xs-center">{{ props.item.nombre }}</td>
      <td class="text-xs-center">
      <span v-if="props.item.status" class="label bg-green shadow-style">Activo</span>
      <span v-if="!props.item.status" class="label bg-red shadow-style">Inactivo</span>
  </td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewTemporada(props.item.id)"
           data-toggle="modal" data-target="#modalTemporada">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteTemporada(props.item.id)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalTemporada" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="saveTemporada">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titlemodalTemporada }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                        <label for="nameTemporada">Nombre</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="nameTemporada" class="form-control"
                                    placeholder="Nombre de la temporada" required="" v-model="nameTemporada">
                            </div>
                        </div>
                        <label for="statusTemporada">Estado</label>
                        <div class="form-group">
                        <br>
                        <div class="switch">
                                    <label>Inactivo
                                        <input id="statusTemporada" type="checkbox" v-model="statusTemporada">
                                        <span class="lever"></span>Activo</label>
                                </div>
                         </div>
                </div>
                <div class="modal-footer">
                    <button v-if="nameTemporada != null" type="submit" class="btn btn-info waves-effect">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('temporada/listTemporadas', 1);
        },
		created(){
           document.title = 'Temporadas - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('temporada/listTemporadas', 1);
        },
        data(){
        	return {
        		titlemodalTemporada: null,
        		nameTemporada: null,
        		statusTemporada: false,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id'
		          },
		          {text: 'Nombre', value: 'nombre',align: 'center' },
		          {text: 'Estado', value: 'status',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id',
            'Nombre': 'nombre',
            'Estado': 'status',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('temporada/listTemporadas', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	temporadas(){
        		return this.$store.getters['temporada/listTemporadas'];
        	},
        	temporada(){
        		return this.$store.getters['temporada/temporada'];
        	},
		    pagination(){
        		return this.$store.getters['temporada/pagination'];
		      },
		     pages () {
		        return this.$store.getters['temporada/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['temporada/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['temporada/loading'];
		      },
        },
        methods: {
        	deleteTemporada(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar la temporada?",
			        text: "La temporada se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarla!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('temporada/deleteTemporada',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	saveTemporada(){
        		if(this.nameTemporada != '' || this.nameTemporada != null){ 
        		if(this.temporada){
        			this.$store.dispatch('temporada/updateTemporada',{id: this.temporada.id,nombre: this.nameTemporada,status: this.statusTemporada})
        			//console.log('estamos editando un temporada');
        			$('#modalTemporada').modal('hide');
        		}else{	
        			this.$store.dispatch('temporada/newTemporada',{nombre: this.nameTemporada,status: this.statusTemporada});
        			//console.log('estamos guardando un temporada');
        			$('#modalTemporada').modal('hide');
        		}
        	}
        		
        	},
        	viewTemporada(id){
        	    this.$store.commit('temporada/viewTemporada',id);
        	    this.nameTemporada = this.temporada.nombre;
        	    this.statusTemporada = this.temporada.status;
        	    this.titlemodalTemporada = `Editar Temporada: ${this.temporada.nombre}`;
        	},
        	setNullTemporada(){
        		this.$store.commit('temporada/viewTemporada',null);
        		this.nameTemporada = null;
        		this.statusTemporada = false;
        		this.titlemodalTemporada = 'Nueva Temporada';
        	},
	         changePage(page){
    		  this.$store.commit('temporada/changePage',page)
	          this.$store.dispatch('temporada/listTemporadas',page);
	        },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>