<template>
	<div>
		 <v-app id="inspire" v-if="auditorios != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-building"></i><strong> Listado de</strong> Auditorios</h2>
                            <div class="text-right">
                            <button @click="setNullAuditorio" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalAuditorio">NUEVO AUDITORIO</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="auditorios" :fields="json_fields" worksheet="Listado de auditorios" name="auditorios.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='auditorios'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado auditorios...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td class="text-xs-center">{{ props.item.nombre }}</td>
      <td class="text-xs-center">{{ props.item.id_ciudad }}</td>
      <td class="text-xs-center">{{ props.item.id_departamento }}</td>
      <td class="text-xs-center">{{ props.item.id_pais }}</td>
      <td class="text-xs-center">{{ props.item.direccion }}</td>
      <td class="text-xs-center">{{ props.item.latitud }}</td>
      <td class="text-xs-center">{{ props.item.longitud }}</td>
      <td class="text-xs-center">{{ props.item.aforo }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewAuditorio(props.item.id)"
           data-toggle="modal" data-target="#modalAuditorio">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteAuditorio(props.item.id)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade bd-example-modal-lg" id="modalAuditorio" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalAuditorio }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                    <div class="col-md-6 col-lg-6">
                        <label for="nombre">Nombre</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="nombre" class="form-control"
                                    placeholder="Nombre del auditorio" required="" v-model="auditorioModel.nombre">
                            </div>
                        </div>
                    </div>
                            <div class="col-md-6 col-lg-6">
                         <label for="direccion">Direccion</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="direccion" class="form-control"
                                    placeholder="Direccion del auditorio" required="" v-model="auditorioModel.direccion">
                            </div>
                        </div>
                    </div>
                        <div class="col-md-4 col-lg-4">
                          <label for="latitud">Latitud</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="latitud" class="form-control"
                                    placeholder="Latitud del auditorio" required="" v-model="auditorioModel.latitud">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                          <label for="longitud">Longitud</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="longitud" class="form-control"
                                    placeholder="Longitud del auditorio" required="" v-model="auditorioModel.longitud">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
						  <label for="aforo">Aforo</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="aforo" class="form-control"
                                    placeholder="Aforo del auditorio" required="" v-model="auditorioModel.aforo">
                            </div>
                        </div>
                    </div>
                     <div class="col-md-4 col-lg-4">
                              <label for="paisAuditorio" v-if="paisAuditorio != null">Pais</label>
                          <div class="form-group" v-if="paisAuditorio != null">
                            <div class="form-line">
                                <input type="text" id="paisAuditorio" class="form-control"
                                    placeholder="Pais del auditorio" :value="paisAuditorio">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchPaises" class="form-control"
                                    placeholder="Buscar paises..." v-model="keyPaises" @keyup.enter="searchPaises">
                            </div>
                        </div>
                        <div class="form-group" v-if="(paises && paises.length > 0)">
                        <v-select
                          :items="paises"
                           item-text="descripcion"
                           item-value="id"
                           v-model="pais"
                          label="Seleccione un pais"
                        ></v-select>
                    </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                           <label for="departamentoAuditorio" v-if="departamentoAuditorio != null">Departamento</label>
                          <div class="form-group" v-if="departamentoAuditorio != null">
                            <div class="form-line">
                                <input type="text" id="departamentoAuditorio" class="form-control"
                                    placeholder="Departamento del auditorio" :value="departamentoAuditorio">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchDepartamentos" class="form-control"
                                    placeholder="Buscar departamentos..." v-model="keyDepartamentos" @keyup.enter="searchDepartamentos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(departamentos && departamentos.length > 0)">
                        <v-select
                          :items="departamentos"
                           item-text="descripcion"
                           item-value="id"
                           v-model="departamento"
                          label="Seleccione un departamento"
                        ></v-select>
                    </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                         <label for="ciudadAuditorio" v-if="ciudadAuditorio != null">Ciudad</label>
                          <div class="form-group" v-if="ciudadAuditorio != null">
                            <div class="form-line">
                                <input type="text" id="ciudadAuditorio" class="form-control"
                                    placeholder="Ciudad del punto de venta" :value="ciudadAuditorio">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchCiudades" class="form-control"
                                    placeholder="Buscar ciudades..." v-model="keyCiudades" @keyup.enter="searchCiudades">
                            </div>
                        </div>
                        <div class="form-group" v-if="(ciudades && ciudades.length > 0)">
                        <v-select
                          :items="ciudades"
                           item-text="descripcion"
                           item-value="id"
                           v-model="ciudad"
                          label="Seleccione una ciudad"
                        ></v-select>
                    </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" @click="saveAuditorio" class="btn btn-info waves-effect">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('auditorio/listAuditorios', 1);
        },
		created(){
           document.title = 'Auditorios - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('auditorio/listAuditorios', 1);
        },
        data(){
        	return {
        		titleModalAuditorio: null,
        		search: '',
        		auditorioModel: {
        				id: null,
        				nombre: null,
        				direccion: null,
        				latitud: null,
        				longitud: null,
        				aforo: null,
        		},
                paisAuditorio: null,
                pais: null,
                keyPaises: null,
                departamentoAuditorio: null,
                departamento: null,
                keyDepartamentos: null,
                ciudadAuditorio: null,
                ciudad: null,
                keyCiudades: null,
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id'
		          },
		          {text: 'Nombre', value: 'nombre',align: 'center' },
		          {text: 'Ciudad', value: 'id_ciudad',align: 'center' },
		          {text: 'Departamento', value: 'id_departamento',align: 'center' },
		          {text: 'Pais', value: 'id_pais',align: 'center' },
		          {text: 'Direccion', value: 'direccion',align: 'center' },
		          {text: 'Latitud', value: 'latitud',align: 'center' },
		          {text: 'Longitud', value: 'longitud',align: 'center' },
		          {text: 'Aforo', value: 'aforo',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id',
            'Nombre': 'nombre',
            'Ciudad': 'id_ciudad',
            'Departamento': 'id_departamento',
            'Pais': 'id_pais',
            'Direccion': 'direccion',
            'Latitud': 'latitud',
            'Longitud': 'longitud',
            'Aforo': 'aforo',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('auditorio/listAuditorios', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	auditorios(){
        		return this.$store.getters['auditorio/listAuditorios'];
        	},
        	auditorio(){
        		return this.$store.getters['auditorio/auditorio'];
        	},
		    pagination(){
        		return this.$store.getters['auditorio/pagination'];
		      },
		     pages () {
		        return this.$store.getters['auditorio/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['auditorio/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['auditorio/loading'];
		      },
                paises(){
                return this.$store.getters['pais/listPaises'];
              },
                departamentos(){
                return this.$store.getters['departamento/listDepartamentos'];
              },
                ciudades(){
                return this.$store.getters['ciudad/listCiudades'];
              },
        },
        methods: {
        	deleteAuditorio(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar el auditorio?",
			        text: "El auditorio se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarlo!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('auditorio/deleteAuditorio',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	saveAuditorio(){
        		if(this.auditorioModel.nombre != null && this.auditorioModel.direccion != null &&
                    this.auditorioModel.latitud != null && 
        			this.auditorioModel.longitud != null && this.auditorioModel.aforo != null &&
                    (this.paisAuditorio != null || this.pais != null) &&
                    (this.departamentoAuditorio != null || this.departamento != null) &&
                    (this.ciudadAuditorio != null || this.ciudad != null)){ 
        		if(this.auditorio){
                    var departamentoUpdate = null;
                    if(this.departamento != null && this.departamento != ''){
                        departamentoUpdate = this.departamento;
                    }else{
                        departamentoUpdate = this.auditorio.id_departamento;
                    }
                      var paisUpdate = null;
                    if(this.pais != null && this.pais != ''){
                        paisUpdate = this.pais;
                    }else{
                        paisUpdate = this.auditorio.id_pais;
                    }
                      var ciudadUpdate = null;
                    if(this.ciudad != null && this.ciudad != ''){
                        ciudadUpdate = this.ciudad;
                    }else{
                        ciudadUpdate = this.auditorio.id_ciudad;
                    }
        			this.$store.dispatch('auditorio/updateAuditorio',
                    {   
                        id: this.auditorio.id,
                        nombre: this.auditorioModel.nombre,
                        id_ciudad: ciudadUpdate,
                        id_pais: paisUpdate,
                        id_departamento: departamentoUpdate,
                        direccion: this.auditorioModel.direccion,
                        latitud: this.auditorioModel.latitud,
                        longitud: this.auditorioModel.longitud,
                        aforo: this.auditorioModel.aforo
                    });
        			//console.log('estamos editando un genero');
        			$('#modalAuditorio').modal('hide');
        		}else{	
        			this.$store.dispatch('auditorio/newAuditorio',
                        {
                        nombre: this.auditorioModel.nombre,
                        id_ciudad: this.ciudad,
                        id_pais: this.pais,
                        id_departamento: this.departamento,
                        direccion: this.auditorioModel.direccion,
                        latitud: this.auditorioModel.latitud,
                        longitud: this.auditorioModel.longitud,
                        aforo: this.auditorioModel.aforo
                    });
        			//console.log('estamos guardando un genero');
        			$('#modalAuditorio').modal('hide');
        		}
        	}
        		
        	},
        	viewAuditorio(id){
        	    this.$store.commit('auditorio/viewAuditorio',id);
        	    this.auditorioModel.id = id;
        	    this.auditorioModel.nombre = this.auditorio.nombre;
        	    this.auditorioModel.ciudad = this.auditorio.ciudad;
        	    this.auditorioModel.departamento = this.auditorio.departamento;
        	    this.auditorioModel.pais = this.auditorio.pais;
        	    this.auditorioModel.direccion = this.auditorio.direccion;
        	    this.auditorioModel.latitud = this.auditorio.latitud;
        	    this.auditorioModel.longitud = this.auditorio.longitud;
        	    this.auditorioModel.aforo = this.auditorio.aforo;
                this.paisAuditorio = this.auditorio.id_pais;
                this.pais = null;
                this.keyPaises = null;
                this.departamentoAuditorio = this.auditorio.id_departamento;
                this.departamento = null;
                this.keyDepartamentos = null;
                this.ciudadAuditorio = this.auditorio.id_ciudad;
                this.ciudad = null;
                this.keyCiudades = null;
        	    this.titleModalAuditorio = `Editar Auditorio: ${this.auditorio.nombre}`;
        	},
        	setNullAuditorio(){
        		this.$store.commit('auditorio/viewAuditorio',null);
        		this.auditorioModel.id = null;
        		this.auditorioModel.nombre = null;
        		this.auditorioModel.ciudad = null;
        		this.auditorioModel.departamento = null;
        		this.auditorioModel.pais = null;
        		this.auditorioModel.direccion = null;
        		this.auditorioModel.latitud = null;
        		this.auditorioModel.longitud = null;
        		this.auditorioModel.aforo = null;
                this.paisAuditorio = null;
                this.pais = null;
                this.keyPaises = null;
                this.departamentoAuditorio = null;
                this.departamento = null;
                this.keyDepartamentos = null;
                this.ciudadAuditorio = null;
                this.ciudad = null;
                this.keyCiudades = null;
        		this.titleModalAuditorio = 'Nuevo Auditorio';
        	},
	         changePage(page){
    		  this.$store.commit('auditorio/changePage',page)
	          this.$store.dispatch('auditorio/listAuditorios',page);
	        },
             searchDepartamentos(){
                this.$store.dispatch('departamento/searchDepartamentos', this.keyDepartamentos);
            },
             searchPaises(){
                this.$store.dispatch('pais/searchPaises', this.keyPaises);
            },
             searchCiudades(){
                this.$store.dispatch('ciudad/searchCiudades', this.keyCiudades);
            },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>