import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       imagenesAuditorios: [],
       imagenauditorio: null,
       imagenesAuditorio: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listImagenesAuditorios: (state,getters,rootState) => {
          return state.imagenesAuditorios;
    },
    imagenauditorio: (state,getters,rootState) => {
        return state.imagenauditorio;
    },
    imagenesAuditorio: (state,getters,rootState) => {
          return state.imagenesAuditorio;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listImagenesAuditorios: (state,imagenesAuditorios) => {
          state.imagenesAuditorios = imagenesAuditorios.data;
          state.pagination.totalItems = imagenesAuditorios.total;
          state.pagination.page = imagenesAuditorios.current_page;
          state.pagination.rowsPerPage = imagenesAuditorios.per_page;
       },
      viewImagenAuditorio(state,payload){
        let auxImagenAuditorio = state.imagenesAuditorios.filter((item) => 
          (item.id_auditorio == payload.id_auditorio && item.id_imagen == payload.id_imagen));
        state.imagenauditorio = auxImagenAuditorio[0];
      },
      listImagenesAuditoriosearch(state,imagenesAuditorio){
            state.imagenesAuditorio = imagenesAuditorio;
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listImagenesAuditorios({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}imagenesauditorio?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listImagenesAuditorios',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener las imagenes por auditorio!", "error");
        console.error(error);
      });

    },
  newImagenAuditorio({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}imagenesauditorio`,
      {
          id_auditorio: payload.id_auditorio,
          id_imagen: payload.id_imagen
      }
      ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo la imagen por auditorio correctamente!", "success");
        store.dispatch('imagenauditorio/listImagenesAuditorios',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear la imagen por auditorio!", "error");
        console.error(error);
      });
  },
  updateImagenAuditorio({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}imagenesauditorio/${payload.id}`,
      {
          id_imagen_old: payload.id_imagen_old,
          id_imagen_new: payload.id_imagen_new
      }
      ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo la imagen por auditorio correctamente!", "success");
        store.dispatch('imagenauditorio/listImagenesAuditorios',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar la imagen por auditorio!", "error");
        console.error(error);
      });
  },
  deleteImagenAuditorio({state,getters,commit,rootState},imagenauditorio){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}imagenesauditorio/${imagenauditorio}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino la imagen por auditorio correctamente!", "success");
        store.dispatch('imagenauditorio/listImagenesAuditorios',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar la imagen por auditorio!","error");
        console.log(error);
     });
  },
  searchImagenesAuditorio({state,getters,commit,rootState},auditorio){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}imagenesauditorio/${auditorio}`,null,{headers: headers})
      .then(response => {
        commit('listImagenesAuditoriosearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        //swal("Error!", "Error al obtener las imagenes del auditorio!", "error");
        commit('listImagenesAuditoriosearch',[]);
        store.commit('changeLoader', false);
        console.error(error);
      });

    },
  },
}