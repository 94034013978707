<template>
	<div>
		 <v-app id="inspire" v-if="imagenes != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-images"></i><strong> Listado de</strong> Imagenes</h2>
                  <v-layout row>
                <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                  <template v-slot:activator="{ on }">
                    <div class="text-right col-lg-12 col-md-12">
                    <button @click="setNullImagen" class="btn btn-primary waves-effect"v-on="on">NUEVA IMAGEN</button>
                </div>
                  </template>
                  <v-card>
                    <v-toolbar dark color="primary">
                      <v-btn icon dark @click="dialog = false">
                        <v-icon>close</v-icon>
                      </v-btn>
                      <v-toolbar-title>{{ titleModalImagen }}</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn dark flat v-if="(fileImage != null && fileImage != '')"  @click="saveImagen">Guardar</v-btn>
                        <v-btn dark flat v-if="(imagen != null && fileImage != '')"  @click="deleteImagen(imagen.id)">Eliminar</v-btn>
                      </v-toolbar-items>
                    </v-toolbar>
                    <form action="#" method="POST" @submit.prevent="" class="col-md-12 col-lg-12 row clearfix">
                    <div class="col-md-12 col-lg-12 text-center">
                    <label class="btn-hover btn-border-radius color-7 btn-lg btn-upload">SUBIR IMAGEN
                    <input type="file" id="file" ref="file" v-on:change="handleFileUpload()" style="display: none;" />
                  </label>
                  <img src="" id="contImage" class="rounded">
              </div>
                </form> 
                    
                  </v-card>
                </v-dialog>
              </v-layout>
                        </div>
        <div id="aniimated-thumbnials" class="list-unstyled row clearfix">
            <div class="col-md-2 col-lg-2 col-sm-3 col-xs-12" v-for="imagen in imagenes" style="padding-right: 3px;padding-left: 3px;">
                <img :src="imagen.url"
                 class="img-responsive img-thumbnail rounded"
                  :title="imagen.nombre"
                   @click="viewImagen(imagen.id)"
                   >
            </div>
        </div>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('imagen/listImagenes', 1);
        },
		created(){
           document.title = 'Imagenes - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('imagen/listImagenes', 1);
        },
        data(){
        	return {
                fileImage: null,
                titleModalImagen: null,
                dialog: false,
                notifications: false,
                sound: true,
                widgets: false
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('imagen/listImagenes', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	imagenes(){
        		return this.$store.getters['imagen/listImagenes'];
        	},
        	imagen(){
        		return this.$store.getters['imagen/imagen'];
        	},
		    pagination(){
        		return this.$store.getters['imagen/pagination'];
		      },
		     pages () {
		        return this.$store.getters['imagen/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['imagen/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['imagen/loading'];
		      },
        },
        methods: {
        	deleteImagen(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar la imagen?",
			        text: "La imagen se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarla!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('imagen/deleteImagen',id);
                        self.dialog = false;
                        $("#file").val("");
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	async saveImagen(){
                if(this.fileImage != '' || this.fileImage != null){ 
                if(this.imagen){
                    const formData = new FormData();
                    formData.append('imagen', this.fileImage);
                    await this.$store.dispatch('imagen/updateImagen',{id: this.imagen.id, imagen: formData}).then(() => {
                        //console.log('estamos guardando un imagen');
                        this.dialog = false;
                        $("#file").val("");
                        this.fileImage = null;
                    });
                }else{  
            	     const formData = new FormData();
                     formData.append('imagen', this.fileImage);
                     await this.$store.dispatch('imagen/newImagen',formData).then(() => {
                        //console.log('estamos guardando un imagen');
                        this.dialog = false;
                        $("#file").val("");
                        this.fileImage = null;
                     });
                }
            }
        		
        	},
        	viewImagen(id){
                this.$store.commit('imagen/viewImagen',id);
                $("#file").val("");
                $("#contImage").attr('src',this.imagen.url);
                this.dialog = true;
                this.fileImage = null;
                this.titleModalImagen = `Editar Imagen: ${this.imagen.nombre}`;
        	},
        	setNullImagen(){
        	    $("#file").val("");
                $("#contImage").attr('src','');
                this.fileImage = null;
                this.$store.commit('imagen/viewImagen',null);
                this.titleModalImagen = `Nueva Imagen`;
        	},
	         changePage(page){
    		  this.$store.commit('imagen/changePage',page)
	          this.$store.dispatch('imagen/listImagenes',page);
	        },
            handleFileUpload(){
                this.fileImage = this.$refs.file.files[0];
                  if (this.fileImage) {
                    var reader = new FileReader();

                    reader.onload = function(e) {
                      $('#contImage').attr('src', e.target.result);
                    }

                    reader.readAsDataURL(this.fileImage);
                  }
            },

        }
	}
</script>
<style>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
.btn-upload{
    width:100%;
}
#contImage{
        max-width: 700px;
}
.v-btn .v-btn__content .v-icon {
    line-height:37px;
}
.v-dialog__content.v-dialog__content--active {
    z-index: 9999 !important;
}
.sweet-alert {
    z-index: 999999 !important;
}
.sweet-overlay {
    z-index: 9999 !important;
}
#aniimated-thumbnials{
      padding: 30px;
}
#aniimated-thumbnials img{
    margin-bottom: 5px;
    cursor: pointer;
    width:170px;
    height:120px;
}
#aniimated-thumbnials img:hover{
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.44);
}
</style>