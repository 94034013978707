<template>
	<div>
		 <v-app id="inspire" v-if="imagenesEventos != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-ticket-alt"></i><strong> Listado de</strong> Imagenes por evento</h2>
                            <div class="text-right">
                            <button @click="setNullImagenEvento" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalImagenEvento">NUEVO REGISTRO</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="imagenesEventos" :fields="json_fields" worksheet="Listado de imagenes por evento" name="imagenesEventos.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='imagenesEventos'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado imagenes por evento...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id_evento }}</td>
      <td class="text-xs-center">{{ props.item.evento.nombre }}</td>
      <td class="text-xs-center"><img :title="props.item.imagen.nombre" :src="props.item.imagen.url" width="80px" class=""></td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewImagenEvento(props.item.id_evento,props.item.id_imagen)"
           data-toggle="modal" data-target="#modalImagenEvento">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteImagenEvento(props.item.id_evento)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalImagenEvento" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalImagenEvento }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                          <label for="eventoImagen" v-if="eventoImagen != null">Evento</label>
                          <div class="form-group" v-if="eventoImagen != null">
                            <div class="form-line">
                                <input type="text" id="eventoImagen" class="form-control"
                                    placeholder="Evento" :value="eventoImagen">
                            </div>
                        </div>
                        <div class="form-group" v-if="eventoImagen == null">
                            <div class="form-line">
                                <input type="text" id="searchEventos" class="form-control"
                                    placeholder="Buscar eventos..." v-model="keyEventos" @keyup.enter="searchEventos">
                            </div>
                        </div>
                        <div class="form-group" v-if="((eventos && eventos.length > 0) && eventoImagen == null)">
                        <v-select
                          :items="eventos"
                           item-text="nombre"
                           item-value="id"
                           v-model="evento"
                          label="Seleccione un evento"
                          @change="searchImagenesEvento(evento)"
                        ></v-select>
                    </div>
                    <template v-if="eventoImagen != null">
                        <div class="form-group text-center">
                        <h5>Imagen actual: {{ imagenActual.nombre }}</h5>
                            <img :src="imagenActual.url" width="300px" class="imagenActual" :title="imagenActual.nombre">
                        </div>
                    </template>
                    <template v-if="((evento != null || eventoImagen != null) && imagenesEvento != null)">
                    <h4 v-if="eventoImagen == null">Añadir una imagen al evento</h4>
                    <h4 v-if="eventoImagen != null">Cambiar esta imagen del evento</h4>
                    <Imagenes :imagenesActivas="imagenesEvento"></Imagenes>
                </template>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info waves-effect" @click="saveImagenEvento">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

import Imagenes from './utils/Imagenes.vue';

	export default {
        components:{
            Imagenes,
        },
        beforeCreate(){
           this.$store.dispatch('imagenevento/listImagenesEventos', 1);
        },
		created(){
           document.title = 'Imagenes por evento - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('imagenevento/listImagenesEventos', 1);
        },
        data(){
        	return {
        		titleModalImagenEvento: null,
        		search: '',
                eventoImagen: null,
                imagenActual: null,
                evento: null,
                keyEventos: null,
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id_evento'
		          },
		          {text: 'Evento', value: 'evento.nombre',align: 'center' },
                  {text: 'Imagen', value: 'imagen.nombre',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id_evento',
            'Evento': 'evento.nombre',
            'Imagen': 'imagen.nombre',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('imagenevento/listImagenesEventos', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	imagenesEventos(){
        		return this.$store.getters['imagenevento/listImagenesEventos'];
        	},
        	imagenevento(){
        		return this.$store.getters['imagenevento/imagenevento'];
        	},
		    pagination(){
        		return this.$store.getters['imagenevento/pagination'];
		      },
		     pages () {
		        return this.$store.getters['imagenevento/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['imagenevento/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['imagenevento/loading'];
		      },
               eventos(){
                return this.$store.getters['evento/listEventos'];
              },
               imagenesEvento(){
                return this.$store.getters['imagenevento/imagenesEvento'];
              },
        },
        methods: {
        	deleteImagenEvento(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar el registro?",
			        text: "El registro se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarlo!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('imagenevento/deleteImagenEvento',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	saveImagenEvento(){
        		if((this.eventoImagen != null || this.evento != null) &&
                    ($("input[name='imagen-select']:checked").val() != undefined && $("input[name='imagen-select']:checked").val() != "" && $("input[name='imagen-select']:checked").val() != null)){ 
        		if(this.imagenevento){
                    var imagenUpdate = null;
                    if($("input[name='imagen-select']:checked").val() != undefined && $("input[name='imagen-select']:checked").val() != "" && $("input[name='imagen-select']:checked").val() != null){
                        imagenUpdate = $("input[name='imagen-select']:checked").val();
                    }else{
                        imagenUpdate = this.imagenevento.id_imagen;
                    }
        			this.$store.dispatch('imagenevento/updateImagenEvento',
                    {
                        id: this.imagenevento.id_evento,
                        id_imagen_old: this.imagenevento.id_imagen,
                        id_imagen_new: imagenUpdate
                    })
        			//console.log('estamos editando un imagenevento');
        			$('#modalImagenEvento').modal('hide');
        		}else{	
        			this.$store.dispatch('imagenevento/newImagenEvento',
                        {
                            id_evento: this.evento,
                            id_imagen: $("input[name='imagen-select']:checked").val()
                        });
        			//console.log('estamos guardando un imagenevento');
        			$('#modalImagenEvento').modal('hide');
        		}
        	}else{
                swal("Error", "Selecciona una imagen!", "error");
            }
        		
        	},
        	viewImagenEvento(id_evento,id_imagen){
                this.searchImagenesEvento(id_evento);
        	    this.$store.commit('imagenevento/viewImagenEvento',{id_evento,id_imagen});
        	    this.eventoImagen = this.imagenevento.evento.nombre;
                this.imagenActual = this.imagenevento.imagen;
                this.evento = null;
                this.keyEventos = null;
        	    this.titleModalImagenEvento = `Editar Registro:`;
        	},
        	setNullImagenEvento(){
        		this.$store.commit('imagenevento/viewImagenEvento',{id_evento:null,id_imagen:null});
        		this.eventoImagen = null;
                this.imagenActual = null;
                this.evento = null;
                this.keyEventos = null;
        		this.titleModalImagenEvento = 'Nuevo Registro';
        	},
	         changePage(page){
    		  this.$store.commit('imagenevento/changePage',page)
	          this.$store.dispatch('imagenevento/listImagenesEventos',page);
	        },
            searchEventos(){
                this.$store.dispatch('evento/searchEventos', this.keyEventos);
            },
            searchImagenesEvento(evento){
                this.$store.dispatch('imagenevento/searchImagenesEvento',evento);
            }
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
img.imagenActual {
    box-shadow: 0px 0px 10px #d0d0d0;
    border-radius: 10px;
    max-width: 200px;
}

</style>