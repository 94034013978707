<template>
	<div>
		 <v-app id="inspire" v-if="puestos != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-gem"></i><strong> Listado de</strong> Puestos</h2>
                            <div class="text-right">
                            <button @click="setNullPuesto" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalPuesto">NUEVO PUESTO</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="puestos" :fields="json_fields" worksheet="Listado de puestos" name="puestos.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='puestos'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado puestos...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td class="text-xs-center">{{ props.item.numero }}</td>
      <td class="text-xs-center">{{ props.item.localidad.nombre }}</td>
      <td class="text-xs-center">{{ props.item.fila.nombre }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewPuesto(props.item.id)"
           data-toggle="modal" data-target="#modalPuesto">
            edit
          </v-icon>
          <v-icon
            small
            @click="deletePuesto(props.item.id)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalPuesto" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalPuesto }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                         <label for="numeroPuesto">Numero</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="numeroPuesto" class="form-control"
                                    placeholder="Numero del puesto" required="" v-model="numeroPuesto">
                            </div>
                        </div>
                      <label for="localidadPuesto" v-if="localidadPuesto != null">Localidad</label>
                          <div class="form-group" v-if="localidadPuesto != null">
                            <div class="form-line">
                                <input type="text" id="localidadPuesto" class="form-control"
                                    placeholder="Localidad del puesto" :value="localidadPuesto">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchLocalidades" class="form-control"
                                    placeholder="Buscar localidades..." v-model="keyLocalidades" @keyup.enter="searchLocalidades">
                            </div>
                        </div>
                        <div class="form-group" v-if="(localidades && localidades.length > 0)">
                        <v-select
                          :items="localidades"
                           item-text="nombre"
                           item-value="id"
                           v-model="localidad"
                          label="Seleccione una localidad"
                        ></v-select>
                    </div>
                       <label for="filaPuesto" v-if="filaPuesto != null">Fila</label>
                          <div class="form-group" v-if="filaPuesto != null">
                            <div class="form-line">
                                <input type="text" id="filaPuesto" class="form-control"
                                    placeholder="Fila del puesto" :value="filaPuesto">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchFilas" class="form-control"
                                    placeholder="Buscar filas..." v-model="keyFilas" @keyup.enter="searchFilas">
                            </div>
                        </div>
                        <div class="form-group" v-if="(filas && filas.length > 0)">
                        <v-select
                          :items="filas"
                           item-text="nombre"
                           item-value="id"
                           v-model="fila"
                          label="Seleccione una fila"
                        ></v-select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button"  v-if="(numeroPuesto != null && (localidad != null || localidadPuesto != null) 
                    && (fila != null || filaPuesto != null))" class="btn btn-info waves-effect" @click="savePuesto">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('puesto/listPuestos', 1);
        },
		created(){
           document.title = 'Puestos - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('puesto/listPuestos', 1);
        },
        data(){
        	return {
        		titleModalPuesto: null,
        		numeroPuesto: null,
                localidadPuesto: null,
                localidad: null,
                keyLocalidades: null,
                filaPuesto: null,
                fila: null,
                keyFilas: null,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id'
		          },
		          {text: 'Numero', value: 'numero',align: 'center' },
                  {text: 'Localidad', value: 'localidad.nombre',align: 'center' },
                  {text: 'Fila', value: 'fila.nombre',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id',
            'Numero': 'numero',
            'Localidad': 'localidad.nombre',
            'Fila': 'fila.nombre',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('puesto/listPuestos', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	puestos(){
        		return this.$store.getters['puesto/listPuestos'];
        	},
        	puesto(){
        		return this.$store.getters['puesto/puesto'];
        	},
		    pagination(){
        		return this.$store.getters['puesto/pagination'];
		      },
		     pages () {
		        return this.$store.getters['puesto/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['puesto/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['puesto/loading'];
		      },
               localidades(){
                return this.$store.getters['localidad/listLocalidades'];
              },
              filas(){
                return this.$store.getters['fila/listFilas'];
              },
        },
        methods: {
        	deletePuesto(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar el puesto?",
			        text: "El puesto se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarlo!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('puesto/deletePuesto',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	savePuesto(){
        		if(this.numeroPuesto != '' || this.numeroPuesto != null && 
                    ((this.localidad != '' || this.localidad != null) ||
                     (this.localidadPuesto != '' || this.localidadPuesto != null)) &&
                     ((this.fila != '' || this.fila != null) ||
                     (this.filaPuesto != '' || this.filaPuesto != null))){ 
        		if(this.puesto){
                    var localidadUpdate = null;
                    var filaUpdate = null;
                    if(this.localidad != null && this.localidad != ''){
                        localidadUpdate = this.localidad;
                    }else{
                        localidadUpdate = this.puesto.id_localidad;
                    }

                    if(this.fila != null && this.fila != ''){
                        filaUpdate = this.fila;
                    }else{
                        filaUpdate = this.puesto.id_fila;
                    }
        			this.$store.dispatch('puesto/updatePuesto',{id: this.puesto.id,numero: this.numeroPuesto, id_localidad: localidadUpdate, id_fila: filaUpdate})
        			//console.log('estamos editando un puesto');
        			$('#modalPuesto').modal('hide');
        		}else{	
        			this.$store.dispatch('puesto/newPuesto',{numero: this.numeroPuesto, id_localidad: this.localidad, id_fila: this.fila});
        			//console.log('estamos guardando un puesto');
        			$('#modalPuesto').modal('hide');
        		}
        	}
        		
        	},
        	viewPuesto(id){
        	    this.$store.commit('puesto/viewPuesto',id);
        	    this.numeroPuesto = this.puesto.numero;
                this.localidadPuesto = this.puesto.localidad.nombre;
                this.filaPuesto = this.puesto.fila.nombre;
                this.keyLocalidades = null;
                this.localidad = null;
                this.keyFilas = null;
                this.fila = null;
        	    this.titleModalPuesto = `Editar Puesto: ${this.puesto.numero}`;
        	},
        	setNullPuesto(){
        		this.$store.commit('puesto/viewPuesto',null);
        		this.numeroPuesto = null;
                this.localidadPuesto = null;
                this.keyLocalidades = null;
                this.localidad = null;
                this.filaPuesto = null;
                this.keyFilas = null;
                this.fila = null;
        		this.titleModalPuesto = 'Nuevo Puesto';
        	},
	         changePage(page){
    		  this.$store.commit('puesto/changePage',page)
	          this.$store.dispatch('puesto/listPuestos',page);
	        },
            searchLocalidades(){
                this.$store.dispatch('localidad/searchLocalidades', this.keyLocalidades);
            },
            searchFilas(){
                this.$store.dispatch('fila/searchFilas', this.keyFilas);
            },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>