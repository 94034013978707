import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       tipoDescuentos: [],
       tipoDescuento: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listTipoDescuento: (state,getters,rootState) => {
          return state.tipoDescuentos;
    },
    tipoDescuento: (state,getters,rootState) => {
        return state.tipoDescuento;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listTipoDescuento: (state,tipoDescuentos) => {
          state.tipoDescuentos = tipoDescuentos.data;
          state.pagination.totalItems = tipoDescuentos.total;
          state.pagination.page = tipoDescuentos.current_page;
          state.pagination.rowsPerPage = tipoDescuentos.per_page;
       },
           listTipoDescuentoSearch: (state,tipoDescuentos) => {
            if(tipoDescuentos != null){ 
                state.tipoDescuentos = tipoDescuentos;
            }else{
              state.tipoDescuentos = null;
            }
       },
      viewTipoDescuento(state,id){
        let auxTipoDescuento = state.tipoDescuentos.filter((item) => item.id == id);
        state.tipoDescuento = auxTipoDescuento[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listTipoDescuento({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}tipodescuento?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listTipoDescuento',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los tipos de descuento!", "error");
        console.error(error);
      });

    },
  newTipoDescuento({state,getters,commit,rootState},nameTipoDescuento){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}tipodescuento`,{nombre: nameTipoDescuento},{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo el tipo de descuento correctamente!", "success");
        store.dispatch('tipodescuento/listTipoDescuento',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear el tipo de descuento!", "error");
        console.error(error);
      });
  },
  updateTipoDescuento({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}tipodescuento/${payload.id}`,{nombre: payload.nameTipoDescuento},{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo el tipo de descuento correctamente!", "success");
        store.dispatch('tipodescuento/listTipoDescuento',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar el tipo de descuento!", "error");
        console.error(error);
      });
  },
  deleteTipoDescuento({state,getters,commit,rootState},tipodescuento){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}tipodescuento/${tipodescuento}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino el tipo de descuento correctamente!", "success");
        store.dispatch('tipodescuento/listTipoDescuento',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar el tipo de descuento!","error");
        console.log(error);
     });
  },
    searchTipoDescuento({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarTipoDescuento?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listTipoDescuentoSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener los tipos de descuento!", "error");
        console.error(error);
      });

    },
  },
}