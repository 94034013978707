<template>
    <div>
         <v-app id="inspire" v-if="imagenesAuditorios != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-building"></i><strong> Listado de</strong> Imagenes por auditorio</h2>
                            <div class="text-right">
                            <button @click="setNullImagenAuditorio" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalImagenAuditorio">NUEVO REGISTRO</button>
                        </div>
                        <div>
                             <export-excel style="width: fit-content;" :data="imagenesAuditorios" :fields="json_fields" worksheet="Listado de imagenes por auditorio" name="imagenesAuditorios.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
            must-sort
            :items='imagenesAuditorios'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado imagenes por auditorio...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id_auditorio }}</td>
      <td class="text-xs-center">{{ props.item.auditorio.nombre }}</td>
      <td class="text-xs-center"><img :title="props.item.imagen.nombre" :src="props.item.imagen.url" width="80px" class=""></td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewImagenAuditorio(props.item.id_auditorio,props.item.id_imagen)"
           data-toggle="modal" data-target="#modalImagenAuditorio">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteImagenAuditorio(props.item.id_auditorio)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalImagenAuditorio" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalImagenAuditorio }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                          <label for="auditorioImagen" v-if="auditorioImagen != null">Auditorio</label>
                          <div class="form-group" v-if="auditorioImagen != null">
                            <div class="form-line">
                                <input type="text" id="auditorioImagen" class="form-control"
                                    placeholder="Auditorio" :value="auditorioImagen">
                            </div>
                        </div>
                        <div class="form-group" v-if="auditorioImagen == null">
                            <div class="form-line">
                                <input type="text" id="searchAuditorios" class="form-control"
                                    placeholder="Buscar auditorios..." v-model="keyAuditorios" @keyup.enter="searchAuditorios">
                            </div>
                        </div>
                        <div class="form-group" v-if="((auditorios && auditorios.length > 0) && auditorioImagen == null)">
                        <v-select
                          :items="auditorios"
                           item-text="nombre"
                           item-value="id"
                           v-model="auditorio"
                          label="Seleccione un auditorio"
                          @change="searchImagenesAuditorio(auditorio)"
                        ></v-select>
                    </div>
                    <template v-if="auditorioImagen != null">
                        <div class="form-group text-center">
                        <h5>Imagen actual: {{ imagenActual.nombre }}</h5>
                            <img :src="imagenActual.url" width="300px" class="imagenActual" :title="imagenActual.nombre">
                        </div>
                    </template>
                    <template v-if="((auditorio != null || auditorioImagen != null) && imagenesAuditorio != null)">
                    <h4 v-if="auditorioImagen == null">Añadir una imagen al auditorio</h4>
                    <h4 v-if="auditorioImagen != null">Cambiar esta imagen del auditorio</h4>
                    <Imagenes :imagenesActivas="imagenesAuditorio"></Imagenes>
                </template>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info waves-effect" @click="saveImagenAuditorio">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
    </div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

import Imagenes from './utils/Imagenes.vue';

    export default {
        components:{
            Imagenes,
        },
        beforeCreate(){
           this.$store.dispatch('imagenauditorio/listImagenesAuditorios', 1);
        },
        created(){
           document.title = 'Imagenes por auditorio - TicketShop';
        },
        async mounted(){
           //adminApp() 
           this.$store.dispatch('imagenauditorio/listImagenesAuditorios', 1);
        },
        data(){
            return {
                titleModalImagenAuditorio: null,
                search: '',
                auditorioImagen: null,
                imagenActual: null,
                auditorio: null,
                keyAuditorios: null,
                headers: [
                  {
                    text: 'Id',
                    align: 'left',
                    sortable: false,
                    value: 'id_auditorio'
                  },
                  {text: 'Auditorio', value: 'auditorio.nombre',align: 'center' },
                  {text: 'Imagen', value: 'imagen.nombre',align: 'center' },
                  {text: 'Acciones',value: '', align: 'right'}
             ],
             json_fields: {
            'Id': 'id_auditorio',
            'Auditorio': 'auditorio.nombre',
            'Imagen': 'imagen.nombre',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
            }
        },
        watch: {
            pagination: {
              async handler () {
                this.$store.dispatch('imagenauditorio/listImagenesAuditorios', this.pagination.page);
              },
              deep: true
            }
          },
        computed: {
            imagenesAuditorios(){
                return this.$store.getters['imagenauditorio/listImagenesAuditorios'];
            },
            imagenauditorio(){
                return this.$store.getters['imagenauditorio/imagenauditorio'];
            },
            pagination(){
                return this.$store.getters['imagenauditorio/pagination'];
              },
             pages () {
                return this.$store.getters['imagenauditorio/pages'];
              },
              totalItemCount(){
                return this.$store.getters['imagenauditorio/totalItemCount'];
              },
              loading(){
                    return this.$store.getters['imagenauditorio/loading'];
              },
               auditorios(){
                return this.$store.getters['auditorio/listAuditorios'];
              },
               imagenesAuditorio(){
                return this.$store.getters['imagenauditorio/imagenesAuditorio'];
              },
        },
        methods: {
            deleteImagenAuditorio(id){
                var self = this;
                    swal({
                    title: "¿Estas seguro de eliminar el registro?",
                    text: "El registro se eliminara permanentemente!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Si, Eliminarlo!",
                    cancelButtonText: "No, cancelar!",
                    closeOnConfirm: false,
                    closeOnCancel: false
                }, function (isConfirm) {
                    if (isConfirm) {
                        self.$store.dispatch('imagenauditorio/deleteImagenAuditorio',id);
                    } else {
                        swal("Cancelado", "Se ha cancelado la operacion.", "error");
                    }
                });
            },
            saveImagenAuditorio(){
                if((this.auditorioImagen != null || this.auditorio != null) &&
                    ($("input[name='imagen-select']:checked").val() != undefined && $("input[name='imagen-select']:checked").val() != "" && $("input[name='imagen-select']:checked").val() != null)){ 
                if(this.imagenauditorio){
                    var imagenUpdate = null;
                    if($("input[name='imagen-select']:checked").val() != undefined && $("input[name='imagen-select']:checked").val() != "" && $("input[name='imagen-select']:checked").val() != null){
                        imagenUpdate = $("input[name='imagen-select']:checked").val();
                    }else{
                        imagenUpdate = this.imagenauditorio.id_imagen;
                    }
                    this.$store.dispatch('imagenauditorio/updateImagenAuditorio',
                    {
                        id: this.imagenauditorio.id_auditorio,
                        id_imagen_old: this.imagenauditorio.id_imagen,
                        id_imagen_new: imagenUpdate
                    })
                    //console.log('estamos editando un imagenauditorio');
                    $('#modalImagenAuditorio').modal('hide');
                }else{  
                    this.$store.dispatch('imagenauditorio/newImagenAuditorio',
                        {
                            id_auditorio: this.auditorio,
                            id_imagen: $("input[name='imagen-select']:checked").val()
                        });
                    //console.log('estamos guardando un imagenauditorio');
                    $('#modalImagenAuditorio').modal('hide');
                }
            }else{
                swal("Error", "Selecciona una imagen!", "error");
            }
                
            },
            viewImagenAuditorio(id_auditorio,id_imagen){
                this.searchImagenesAuditorio(id_auditorio);
                this.$store.commit('imagenauditorio/viewImagenAuditorio',{id_auditorio,id_imagen});
                this.auditorioImagen = this.imagenauditorio.auditorio.nombre;
                this.imagenActual = this.imagenauditorio.imagen;
                this.auditorio = null;
                this.keyAuditorios = null;
                this.titleModalImagenAuditorio = `Editar Registro:`;
            },
            setNullImagenAuditorio(){
                this.$store.commit('imagenauditorio/viewImagenAuditorio',{id_auditorio:null,id_imagen:null});
                this.auditorioImagen = null;
                this.imagenActual = null;
                this.auditorio = null;
                this.keyAuditorios = null;
                this.titleModalImagenAuditorio = 'Nuevo Registro';
            },
             changePage(page){
              this.$store.commit('imagenauditorio/changePage',page)
              this.$store.dispatch('imagenauditorio/listImagenesAuditorios',page);
            },
            searchAuditorios(){
                this.$store.dispatch('auditorio/searchAuditorios', this.keyAuditorios);
            },
            searchImagenesAuditorio(auditorio){
                this.$store.dispatch('imagenauditorio/searchImagenesAuditorio',auditorio);
            }
        }
    }
</script>
<style scoped>
.changes{
    margin-right: 5px;
    margin-bottom: 12px;
}
img.imagenActual {
    box-shadow: 0px 0px 10px #d0d0d0;
    border-radius: 10px;
    max-width: 200px;
}

</style>