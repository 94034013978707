import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       puestos: [],
       puesto: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listPuestos: (state,getters,rootState) => {
          return state.puestos;
    },
    puesto: (state,getters,rootState) => {
        return state.puesto;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listPuestos: (state,puestos) => {
          state.puestos = puestos.data;
          state.pagination.totalItems = puestos.total;
          state.pagination.page = puestos.current_page;
          state.pagination.rowsPerPage = puestos.per_page;
       },
    listPuestosSearch: (state,puestos) => {
            if(puestos != null){ 
                state.puestos = puestos;
            }else{
              state.puestos = null;
            }
       },
      viewPuesto(state,id){
        let auxPuesto = state.puestos.filter((item) => item.id == id);
        state.puesto = auxPuesto[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listPuestos({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}listado_detalle_puestos?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listPuestos',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los puestos!", "error");
        console.error(error);
      });

    },
  newPuesto({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}puesto`,
        {
          numero: payload.numero,
          id_localidad: payload.id_localidad,
          id_fila: payload.id_fila
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo el puesto correctamente!", "success");
        store.dispatch('puesto/listPuestos',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear el puesto!", "error");
        console.error(error);
      });
  },
  updatePuesto({state,getters,commit,rootState},payload){
    console.log(payload);
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}puesto/${payload.id}`,
        {
          numero: payload.numero,
          id_localidad: payload.id_localidad,
          id_fila: payload.id_fila
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo el puesto correctamente!", "success");
        store.dispatch('puesto/listPuestos',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar el puesto!", "error");
        console.error(error);
      });
  },
  deletePuesto({state,getters,commit,rootState},puesto){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}puesto/${puesto}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino el puesto correctamente!", "success");
        store.dispatch('puesto/listPuestos',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar el puesto!","error");
        console.log(error);
     });
  },
  searchPuestos({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarPuestos?numero=${key}`,null,{headers: headers})
      .then(response => {
        commit('listPuestosSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener los puestos!", "error");
        console.error(error);
      });

    },
  },
}