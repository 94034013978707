<template>
	<div>
		 <v-app id="inspire" v-if="puntosEventos != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-ticket-alt"></i><strong> Listado de</strong> Puntos de Venta por Evento</h2>
                            <div class="text-right">
                            <button @click="setNullPuntoEvento" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalPuntoEvento">NUEVO REGISTRO</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="puntosEventos" :fields="json_fields" worksheet="Listado de puntos de venta por evento" name="puntosEventos.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='puntosEventos'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado puntos de venta por evento...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id_evento }}</td>
      <td class="text-xs-center">{{ props.item.evento.nombre }}</td>
      <td class="text-xs-center">{{ props.item.punto_ventum.nombre_razon }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewPuntoEvento(props.item.id_evento,props.item.id_puntoventa)"
           data-toggle="modal" data-target="#modalPuntoEvento">
            edit
          </v-icon>
          <v-icon
            small
            @click="deletePuntoEvento(props.item.id_evento)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalPuntoEvento" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalPuntoEvento }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                     <label for="puntoVentaActual" v-if="puntoVentaActual != null">Punto de venta</label>
                          <div class="form-group" v-if="puntoVentaActual != null">
                            <div class="form-line">
                                <input type="text" id="puntoVentaActual" class="form-control"
                                    placeholder="Punto de venta" :value="puntoVentaActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchPuntosVenta" class="form-control"
                                    placeholder="Buscar puntos de venta..." v-model="keyPuntosVenta" @keyup.enter="searchPuntosVenta">
                            </div>
                        </div>
                        <div class="form-group" v-if="(puntosVenta && puntosVenta.length > 0)">
                        <v-select
                          :items="puntosVenta"
                           item-text="nombre_razon"
                           item-value="id"
                           v-model="puntoVenta"
                          label="Seleccione un punto de venta"
                        ></v-select>
                    </div>
                      <label for="eventoActual" v-if="eventoActual != null">Evento</label>
                          <div class="form-group" v-if="eventoActual != null">
                            <div class="form-line">
                                <input type="text" id="eventoActual" class="form-control"
                                    placeholder="Evento" :value="eventoActual">
                            </div>
                        </div>
                        <div class="form-group" v-if="eventoActual == null">
                            <div class="form-line">
                                <input type="text" id="searchEventos" class="form-control"
                                    placeholder="Buscar eventos..." v-model="keyEventos" @keyup.enter="searchEventos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(eventos && eventos.length > 0) && (eventoActual == null)">
                        <v-select
                          :items="eventos"
                           item-text="nombre"
                           item-value="id"
                           v-model="evento"
                          label="Seleccione un evento"
                        ></v-select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button"  v-if="(puntoVenta != null || puntoVentaActual != null) && (evento != null || eventoActual != null)" class="btn btn-info waves-effect" @click="savePuntoEvento">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('puntoevento/listPuntosEventos', 1);
        },
		created(){
           document.title = 'Puntos de venta por evento - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('puntoevento/listPuntosEventos', 1);
        },
        data(){
        	return {
        		titleModalPuntoEvento: null,
        		evento: null,
                eventoActual: null,
                puntoVenta: null,
                puntoVentaActual: null,
                keyEventos: null,
                keyPuntosVenta: null,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id_evento'
		          },
		          {text: 'Evento', value: 'evento.nombre',align: 'center' },
                  {text: 'Punto de venta', value: 'punto_ventum.nombre_razon',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id_evento',
            'Evento': 'evento.nombre',
            'Punto de venta': 'punto_ventum.nombre_razon',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('puntoevento/listPuntosEventos', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	puntosEventos(){
        		return this.$store.getters['puntoevento/listPuntosEventos'];
        	},
        	puntoEvento(){
        		return this.$store.getters['puntoevento/puntoEvento'];
        	},
		    pagination(){
        		return this.$store.getters['puntoevento/pagination'];
		      },
		     pages () {
		        return this.$store.getters['puntoevento/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['puntoevento/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['puntoevento/loading'];
		      },
               eventos(){
                return this.$store.getters['evento/listEventos'];
              },
               puntosVenta(){
                return this.$store.getters['puntoventa/listPuntosVenta'];
              },
        },
        methods: {
        	deletePuntoEvento(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar el registro?",
			        text: "El registro se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarlo!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('puntoevento/deletePuntoEvento',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	savePuntoEvento(){
        		if(((this.evento != '' || this.evento != null) ||
                     (this.eventoActual != '' || this.eventoActual != null)) && 
                    ((this.puntoVenta != '' || this.puntoVenta != null) ||
                     (this.puntoVentaActual != '' || this.puntoVentaActual != null))){ 
        		if(this.puntoEvento){
                    var puntoVentaUpdate = null;
                    if(this.puntoVenta != null && this.puntoVenta != ''){
                        puntoVentaUpdate = this.puntoVenta;
                    }else{
                        puntoVentaUpdate = this.puntoEvento.id_puntoventa;
                    }
        			this.$store.dispatch('puntoevento/updatePuntoEvento',{id: this.puntoEvento.id_evento,id_puntoventa_old: this.puntoEvento.id_puntoventa, id_puntoventa_new: puntoVentaUpdate})
        			//console.log('estamos editando un palco');
        			$('#modalPuntoEvento').modal('hide');
        		}else{	
        			this.$store.dispatch('puntoevento/newPuntoEvento',{id_evento: this.evento, id_puntoventa: this.puntoVenta});
        			//console.log('estamos guardando un palco');
        			$('#modalPuntoEvento').modal('hide');
        		}
        	}
        		
        	},
        	viewPuntoEvento(id_evento,id_puntoventa){
        	    this.$store.commit('puntoevento/viewPuntoEvento',{id_evento,id_puntoventa});
        	    this.eventoActual = this.puntoEvento.evento.nombre;
                this.puntoVentaActual = this.puntoEvento.punto_ventum.nombre_razon;
                this.keyEventos = null;
                this.keyPuntosVenta = null;
                this.evento = null;
                this.puntoVenta = null;
        	    this.titleModalPuntoEvento = `Editar Registro: ${this.puntoEvento.id_evento}`;
        	},
        	setNullPuntoEvento(){
        		this.$store.commit('puntoevento/viewPuntoEvento',{id_evento:null,id_puntoventa:null});
        		this.eventoActual = null;
                this.puntoVentaActual = null;
                this.keyEventos = null;
                this.keyPuntosVenta = null;
                this.evento = null;
                this.puntoVenta = null;
        		this.titleModalPuntoEvento = 'Nuevo Registro';
        	},
	         changePage(page){
    		  this.$store.commit('puntoevento/changePage',page)
	          this.$store.dispatch('puntoevento/listPuntosEventos',page);
	        },
            searchEventos(){
                this.$store.dispatch('evento/searchEventos', this.keyEventos);
            },
            searchPuntosVenta(){
                this.$store.dispatch('puntoventa/searchPuntosVenta', this.keyPuntosVenta);
            },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>