import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       filas: [],
       fila: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listFilas: (state,getters,rootState) => {
          return state.filas;
    },
    fila: (state,getters,rootState) => {
        return state.fila;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listFilas: (state,filas) => {
          state.filas = filas.data;
          state.pagination.totalItems = filas.total;
          state.pagination.page = filas.current_page;
          state.pagination.rowsPerPage = filas.per_page;
       },
    listFilasSearch: (state,filas) => {
            if(filas != null){ 
                state.filas = filas;
            }else{
              state.filas = null;
            }
       },
      viewFila(state,id){
        let auxFila = state.filas.filter((item) => item.id == id);
        state.fila = auxFila[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listFilas({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}listado_detalle_filas?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listFilas',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener las filas!", "error");
        console.error(error);
      });

    },
  newFila({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}fila`,
        {
          id_localidad: payload.id_localidad,
          nombre: payload.nombre,
          numero: payload.numero
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo la fila correctamente!", "success");
        store.dispatch('fila/listFilas',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear la fila!", "error");
        console.error(error);
      });
  },
  updateFila({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}fila/${payload.id}`,
        {
          id_localidad: payload.id_localidad,
          nombre: payload.nombre,
          numero: payload.numero
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo la fila correctamente!", "success");
        store.dispatch('fila/listFilas',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar la fila!", "error");
        console.error(error);
      });
  },
  deleteFila({state,getters,commit,rootState},fila){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}fila/${fila}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino la fila correctamente!", "success");
        store.dispatch('fila/listFilas',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar la fila!","error");
        console.log(error);
     });
  },
  searchFilas({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarFila?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listFilasSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener las filas!", "error");
        console.error(error);
      });

    },
  },
}