import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       preventas: [],
       preventa: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listPreventas: (state,getters,rootState) => {
          return state.preventas;
    },
    preventa: (state,getters,rootState) => {
        return state.preventa;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listPreventas: (state,preventas) => {
          state.preventas = preventas.data;
          state.pagination.totalItems = preventas.total;
          state.pagination.page = preventas.current_page;
          state.pagination.rowsPerPage = preventas.per_page;
       },
      listPreventasSearch: (state,preventas) => {
            if(preventas != null){ 
                state.preventas = preventas;
            }else{
              state.preventas = null;
            }
       },
      viewPreventa(state,id){
        let auxPreventa = state.preventas.filter((item) => item.id == id);
        state.preventa = auxPreventa[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listPreventas({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}preventum?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listPreventas',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener las preventas!", "error");
        console.error(error);
      });

    },
  newPreventa({state,getters,commit,rootState},preventa){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}preventum`,
          {
          nombre: preventa.nombre,
          fecha_inicio: preventa.fecha_inicio,
          fecha_fin: preventa.fecha_fin,
          id_evento: preventa.id_evento,
          activo: preventa.activo
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo la preventa correctamente!", "success");
        store.dispatch('preventa/listPreventas',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear la preventa!", "error");
        console.error(error);
      });
  },
  updatePreventa({state,getters,commit,rootState},preventa){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}preventum/${preventa.id}`,
        {
          nombre: preventa.nombre,
          fecha_inicio: preventa.fecha_inicio,
          fecha_fin: preventa.fecha_fin,
          id_evento: preventa.id_evento,
          activo: preventa.activo
        },
        {headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo la preventa correctamente!", "success");
        store.dispatch('preventa/listPreventas',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar la preventa!", "error");
        console.error(error);
      });
  },
  deletePreventa({state,getters,commit,rootState},preventa){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}preventum/${preventa}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino la preventa correctamente!", "success");
        store.dispatch('preventa/listPreventas',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar la preventa!","error");
        console.log(error);
     });
  },
    searchPreventas({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarPreventa?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listPreventasSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener las preventas!", "error");
        console.error(error);
      });

    },
  },
}