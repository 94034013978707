import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       clientes: [],
       cliente: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listClientes: (state,getters,rootState) => {
          return state.clientes;
    },
    cliente: (state,getters,rootState) => {
        return state.cliente;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listClientes: (state,clientes) => {
          state.clientes = clientes.data;
          state.pagination.totalItems = clientes.total;
          state.pagination.page = clientes.current_page;
          state.pagination.rowsPerPage = clientes.per_page;
       },
      listClientesSearch: (state,clientes) => {
            if(clientes != null){ 
                state.clientes = clientes;
            }else{
              state.clientes = null;
            }
       },
      viewCliente(state,id){
        let auxCliente = state.clientes.filter((item) => item.id == id);
        state.cliente = auxCliente[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listClientes({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}cliente?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listClientes',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los clientes!", "error");
        console.error(error);
      });

    },
  newCliente({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}cliente`,
        {
          Identificacion: payload.identificacion,
          tipo_identificacion: payload.tipo_identificacion,
          nombrerazon: payload.nombrerazon,
          direccion: payload.direccion,
          ciudad: payload.ciudad,
          departamento: payload.departamento,
          tipo_cliente: payload.tipo_cliente,
          email: payload.email,
          telefono: payload.telefono
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo el cliente correctamente!", "success");
        store.dispatch('cliente/listClientes',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear el cliente!", "error");
        console.error(error);
      });
  },
  updateCliente({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}cliente/${payload.id}`,
        {
          Identificacion: payload.identificacion,
          tipo_identificacion: payload.tipo_identificacion,
          nombrerazon: payload.nombrerazon,
          direccion: payload.direccion,
          ciudad: payload.ciudad,
          departamento: payload.departamento,
          tipo_cliente: payload.tipo_cliente,
          email: payload.email,
          telefono: payload.telefono
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo el cliente correctamente!", "success");
        store.dispatch('cliente/listClientes',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar el cliente!", "error");
        console.error(error);
      });
  },
  deleteCliente({state,getters,commit,rootState},cliente){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}cliente/${cliente}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino el cliente correctamente!", "success");
        store.dispatch('cliente/listClientes',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar el cliente!","error");
        console.log(error);
     });
  },
    searchClientes({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarClientes?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listClientesSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener los clientes!", "error");
        console.error(error);
      });

    },
  },
}