<template>
	<div>
		 <v-app id="inspire">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-star"></i><strong> Listado de</strong> Artistas</h2>
                            <div class="text-right">
                            <button @click="setNullArtista" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalArtista">NUEVO ARTISTA</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="artistas" :fields="json_fields" worksheet="Listado de artistas" name="artistas.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='artistas'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado artistas...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td class="text-xs-center">{{ props.item.nombre }}</td>
      <td class="text-xs-center">{{ props.item.manager }}</td>
      <td class="text-xs-center">{{ props.item.genero.nombre }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewArtista(props.item.id)"
           data-toggle="modal" data-target="#modalArtista">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteArtista(props.item.id)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalArtista" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalArtista }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                        <label for="nameArtista">Nombre</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="nameArtista" class="form-control"
                                    placeholder="Nombre del artista" v-model="nameArtista">
                            </div>
                        </div>
                         <label for="managerArtista">Manager</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="managerArtista" class="form-control"
                                    placeholder="Nombre del manager" v-model="managerArtista">
                            </div>
                        </div>
                         <label for="generoArtista" v-if="generoArtista != null">Genero</label>
                          <div class="form-group" v-if="generoArtista != null">
                            <div class="form-line">
                                <input type="text" id="generoArtista" class="form-control"
                                    placeholder="Genero del artista" :value="generoArtista">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchGeneros" class="form-control"
                                    placeholder="Buscar generos..." v-model="keyGeneros" @keyup.enter="searchGeneros">
                            </div>
                        </div>
                        <div class="form-group" v-if="(generos && generos.length > 0)">
                        <v-select
				          :items="generos"
				           item-text="nombre"
  						   item-value="id"
                           v-model="genero"
				          label="Seleccione un genero"
				        ></v-select>
				    </div>
                </div>
                <div class="modal-footer">
                    <button v-if="(nameArtista != null && managerArtista != null && (genero != null || generoArtista != null))" type="button" class="btn btn-info waves-effect" @click="saveArtista">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
		created(){
           document.title = 'Artistas - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('artista/listArtistas', 1);
        },
        data(){
        	return {
        		titleModalArtista: null,
        		nameArtista: null,
        		managerArtista: null,
                generoArtista: null,
        		genero: null,
                keyGeneros: null,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id'
		          },
		          {text: 'Nombre', value: 'nombre',align: 'center' },
		          {text: 'Manager', value: 'manager',align: 'center' },
		          {text: 'Genero', value: 'genero.nombre',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id',
            'Nombre': 'nombre',
            'Manager': 'manager',
            'Genero': 'genero.nombre',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('artista/listArtistas', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	artistas(){
        		return this.$store.getters['artista/listArtistas'];
        	},
        	artista(){
        		return this.$store.getters['artista/artista'];
        	},
		    pagination(){
        		return this.$store.getters['artista/pagination'];
		      },
		     pages () {
		        return this.$store.getters['artista/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['artista/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['artista/loading'];
		      },
		      generos(){
        		return this.$store.getters['genero/listGeneros'];
        	  },
              /*searchGeneroArtista(){
                return this.$store.getters['genero/showGenero'];
              },*/
        },
        methods: {
        	deleteArtista(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar el artista?",
			        text: "El artista se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarlo!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('artista/deleteArtista',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	saveArtista(){
        		if(this.nameArtista != '' || this.nameArtista != null || this.genero != null || this.managerArtista != null){ 
        		if(this.artista){
                    var generoUpdate = null;
                    if(this.genero != null && this.genero != ''){
                        generoUpdate = this.genero;
                    }else{
                        generoUpdate = this.artista.id_genero;
                    }

        			this.$store.dispatch('artista/updateArtista',{artista: this.artista.id,nameArtista: this.nameArtista, managerArtista: this.managerArtista, genero: generoUpdate})
        			//console.log('estamos editando un artista');
        			$('#modalArtista').modal('hide');
        		}else{	
        			this.$store.dispatch('artista/newArtista',{nameArtista: this.nameArtista, managerArtista: this.managerArtista, genero: this.genero});
        			//console.log('estamos guardando un artista');
        			$('#modalArtista').modal('hide');
        		}
        	}
        		
        	},
        	viewArtista(id){
                  this.$store.commit('artista/viewArtista',id);
                  this.nameArtista = this.artista.nombre;
                  this.managerArtista = this.artista.manager;
                  this.generoArtista = this.artista.genero.nombre;
                  this.titleModalArtista = `Editar Artista: ${this.artista.nombre}`;
                  //this.$store.dispatch('genero/showGenero',this.artista.id_genero);
        	},
        	setNullArtista(){
        		this.$store.commit('artista/viewArtista',null);
        		this.titleModalArtista = 'Nuevo Artista';
        		this.nameArtista = null;
        		this.managerArtista = null;
                this.generoArtista = null;
                this.keyGeneros = null;
        		this.genero = null;
                this.$store.commit('genero/listGenerosSearch',null);
                this.$store.commit('genero/showGenero',null);
        	},
	         changePage(page){
    		  this.$store.commit('artista/changePage',page)
	          this.$store.dispatch('artista/listArtistas',page);
	        },
            searchGeneros(){
                this.$store.dispatch('genero/searchGeneros', this.keyGeneros);
            }
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>