import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       descuentosEventos: [],
       descuentoevento: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listDescuentosEventos: (state,getters,rootState) => {
          return state.descuentosEventos;
    },
    descuentoevento: (state,getters,rootState) => {
        return state.descuentoevento;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listDescuentosEventos: (state,descuentosEventos) => {
          state.descuentosEventos = descuentosEventos.data;
          state.pagination.totalItems = descuentosEventos.total;
          state.pagination.page = descuentosEventos.current_page;
          state.pagination.rowsPerPage = descuentosEventos.per_page;
       },
      viewDescuentoEvento(state,id){
        let auxDescuentoEvento = state.descuentosEventos.filter((item) => item.id == id);
        state.descuentoevento = auxDescuentoEvento[0];
      },
       listDescuentosEventosSearch: (state,descuentosEventos) => {
            if(descuentosEventos != null){ 
                state.descuentosEventos = descuentosEventos;
            }else{
              state.descuentosEventos = null;
            }
       },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listDescuentosEventos({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}descuentoevento?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listDescuentosEventos',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los descuentos por evento!", "error");
        console.error(error);
      });

    },
  newDescuentoEvento({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}descuentoevento`,
      {
        nombre: payload.nombre,
        id_evento: payload.id_evento,
        fecha_hora_inicio: payload.fecha_hora_inicio,
        fecha_hora_fin: payload.fecha_hora_fin,
        status: payload.status,
        tipo_descuento: payload.tipo_descuento,
        porcentaje: payload.porcentaje,
        monto: payload.monto,
        cantidad_compra: payload.cantidad_compra,
        cantidad_paga: payload.cantidad_paga,
        codigo_moneda: payload.codigo_moneda
      },
      {headers: headers})
      .then(response => {
        swal("Exito!", "Se creo el descuento por evento correctamente!", "success");
        store.dispatch('descuentoevento/listDescuentosEventos',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear el descuento por evento!", "error");
        console.error(error);
      });
  },
  updateDescuentoEvento({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}descuentoevento/${payload.id}`,
      {
        nombre: payload.nombre,
        id_evento: payload.id_evento,
        fecha_hora_inicio: payload.fecha_hora_inicio,
        fecha_hora_fin: payload.fecha_hora_fin,
        status: payload.status,
        tipo_descuento: payload.tipo_descuento,
        porcentaje: payload.porcentaje,
        monto: payload.monto,
        cantidad_compra: payload.cantidad_compra,
        cantidad_paga: payload.cantidad_paga,
        codigo_moneda: payload.codigo_moneda
      },
      {headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo el descuento por evento correctamente!", "success");
        store.dispatch('descuentoevento/listDescuentosEventos',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar el descuento por evento!", "error");
        console.error(error);
      });
  },
  deleteDescuentoEvento({state,getters,commit,rootState},descuentoevento){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}descuentoevento/${descuentoevento}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino el descuento por evento correctamente!", "success");
        store.dispatch('descuentoevento/listDescuentosEventos',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar el descuento por evento!","error");
        console.log(error);
     });
  },
    searchDescuentos({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarDescuentoEvento?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listDescuentosEventosSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener los descuentos!", "error");
        console.error(error);
      });

    },
  },
}