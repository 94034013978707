<template>
	<div>
		 <v-app id="inspire" v-if="palcos != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-gem"></i><strong> Listado de</strong> Palcos</h2>
                            <div class="text-right">
                            <button @click="setNullPalco" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalPalco">NUEVO PALCO</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="palcos" :fields="json_fields" worksheet="Listado de palcos" name="palcos.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='palcos'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado palcos...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td class="text-xs-center">{{ props.item.nombre }}</td>
      <td class="text-xs-center">{{ props.item.localidad.nombre }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewPalco(props.item.id)"
           data-toggle="modal" data-target="#modalPalco">
            edit
          </v-icon>
          <v-icon
            small
            @click="deletePalco(props.item.id)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalPalco" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titlemodalPalco }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                         <label for="namePalco">Nombre</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="namePalco" class="form-control"
                                    placeholder="Nombre del palco" required="" v-model="namePalco">
                            </div>
                        </div>
                      <label for="localidadPalco" v-if="localidadPalco != null">Localidad</label>
                          <div class="form-group" v-if="localidadPalco != null">
                            <div class="form-line">
                                <input type="text" id="localidadPalco" class="form-control"
                                    placeholder="Localidad del palco" :value="localidadPalco">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchLocalidades" class="form-control"
                                    placeholder="Buscar localidades..." v-model="keyLocalidades" @keyup.enter="searchLocalidades">
                            </div>
                        </div>
                        <div class="form-group" v-if="(localidades && localidades.length > 0)">
                        <v-select
                          :items="localidades"
                           item-text="nombre"
                           item-value="id"
                           v-model="localidad"
                          label="Seleccione una localidad"
                        ></v-select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button"  v-if="(namePalco != null && (localidad != null || localidadPalco != null))" class="btn btn-info waves-effect" @click="savePalco">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('palco/listPalcos', 1);
        },
		created(){
           document.title = 'Palcos - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('palco/listPalcos', 1);
        },
        data(){
        	return {
        		titlemodalPalco: null,
        		namePalco: null,
                localidadPalco: null,
                localidad: null,
                keyLocalidades: null,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id'
		          },
		          {text: 'Nombre', value: 'nombre',align: 'center' },
                  {text: 'Localidad', value: 'localidad.nombre',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id',
            'Nombre': 'nombre',
            'Localidad': 'localidad.nombre',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('palco/listPalcos', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	palcos(){
        		return this.$store.getters['palco/listPalcos'];
        	},
        	palco(){
        		return this.$store.getters['palco/palco'];
        	},
		    pagination(){
        		return this.$store.getters['palco/pagination'];
		      },
		     pages () {
		        return this.$store.getters['palco/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['palco/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['palco/loading'];
		      },
               localidades(){
                return this.$store.getters['localidad/listLocalidades'];
              },
        },
        methods: {
        	deletePalco(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar el palco?",
			        text: "El palco se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarlo!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('palco/deletePalco',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	savePalco(){
        		if(this.namePalco != '' || this.namePalco != null && 
                    ((this.localidad != '' || this.localidad != null) ||
                     (this.localidadPalco != '' || this.localidadPalco != null))){ 
        		if(this.palco){
                    var localidadUpdate = null;
                    if(this.localidad != null && this.localidad != ''){
                        localidadUpdate = this.localidad;
                    }else{
                        localidadUpdate = this.palco.id_localidad;
                    }
        			this.$store.dispatch('palco/updatePalco',{id: this.palco.id,nombre: this.namePalco, id_localidad: localidadUpdate})
        			//console.log('estamos editando un palco');
        			$('#modalPalco').modal('hide');
        		}else{	
        			this.$store.dispatch('palco/newPalco',{nombre: this.namePalco, id_localidad: this.localidad});
        			//console.log('estamos guardando un palco');
        			$('#modalPalco').modal('hide');
        		}
        	}
        		
        	},
        	viewPalco(id){
        	    this.$store.commit('palco/viewPalco',id);
        	    this.namePalco = this.palco.nombre;
                this.localidadPalco = this.palco.localidad.nombre;
                this.keyLocalidades = null;
                this.localidad = null;
        	    this.titlemodalPalco = `Editar Palco: ${this.palco.nombre}`;
        	},
        	setNullPalco(){
        		this.$store.commit('palco/viewPalco',null);
        		this.namePalco = null;
                this.localidadPalco = null;
                this.keyLocalidades = null;
                this.localidad = null;
        		this.titlemodalPalco = 'Nuevo Palco';
        	},
	         changePage(page){
    		  this.$store.commit('palco/changePage',page)
	          this.$store.dispatch('palco/listPalcos',page);
	        },
            searchLocalidades(){
                this.$store.dispatch('localidad/searchLocalidades', this.keyLocalidades);
            },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>