<template>
    <div>
    
    <div class="overlay"></div>

    <TopNav></TopNav>
    
    <LeftNav></LeftNav>
    
    <RightNav></RightNav>

   <transition appear name="fade" mode="out-in">
    <section class="content">
        <div class="container-fluid">
        <Breadcrumb></Breadcrumb>
        <router-view></router-view>
    </div>
    </section>
    </transition>

    </div>
</template>
<script>

import TopNav from './fragments/TopNav.vue';
import LeftNav from './fragments/LeftNav.vue';
import RightNav from './fragments/RightNav.vue';
import Breadcrumb from './fragments/Breadcrumb.vue';

    export default{
        components: {
            TopNav,
            LeftNav,
            RightNav,
            Breadcrumb,
        },

    }
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 1.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>