<template>
    <div>
         <v-app id="inspire" v-if="puestosPalcosEventos != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-ticket-alt"></i><strong> Listado de</strong> Puestos Palco Evento</h2>
                            <div class="text-right">
                            <button @click="setNullPuestoPalcoEvento" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalPuestoPalcoEvento">NUEVO REGISTRO</button>
                        </div>
                        <div>
                             <export-excel style="width: fit-content;" :data="puestosPalcosEventos" :fields="json_fields" worksheet="Listado de puestos palco evento" name="puestosPalcosEventos.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
            must-sort
            :items='puestosPalcosEventos'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado puestos palco evento...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id_palco_evento }}</td>
      <td class="text-xs-center">{{ props.item.palco.nombre }}</td>
      <td class="text-xs-center">{{ props.item.puesto.numero }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewPuestoPalcoEvento(props.item.id_palco_evento)"
           data-toggle="modal" data-target="#modalPuestoPalcoEvento">
            edit
          </v-icon>
          <v-icon
            small
            @click="deletePuestoPalcoEvento(props.item.id_palco_evento)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalPuestoPalcoEvento" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalPuestoPalcoEvento }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="row row-clearfix">
                       <div class="col-lg-6 col-md-6" v-if="puestopalcoevento == null">
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchEventos" class="form-control"
                                    placeholder="Buscar eventos..." v-model="keyEventos" @keyup.enter="searchEventos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(eventos && eventos.length > 0)">
                        <v-select
                          :items="eventos"
                           item-text="nombre"
                           item-value="id"
                           v-model="evento"
                          label="Seleccione un evento"
                        ></v-select>
                    </div>
                       </div>
                      <div class="col-md-6 col-lg-6" v-if="puestopalcoevento == null">
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchPalcos" class="form-control"
                                    placeholder="Buscar palcos..." v-model="keyPalcos" @keyup.enter="searchPalcos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(palcos && palcos.length > 0)">
                        <v-select
                          :items="palcos"
                           item-text="nombre"
                           item-value="id"
                           v-model="palco_e"
                          label="Seleccione un palco"
                        ></v-select>
                    </div>
                     </div>
                           <div class="col-md-12 col-lg-12">
                      <label for="palcoActual" v-if="palcoActual != null">Palco</label>
                          <div class="form-group" v-if="palcoActual != null">
                            <div class="form-line">
                                <input type="text" id="palcoActual" class="form-control"
                                    placeholder="Palco" :value="palcoActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchPalcos" class="form-control"
                                    placeholder="Buscar palcos..." v-model="keyPalcos" @keyup.enter="searchPalcos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(palcos && palcos.length > 0)">
                        <v-select
                          :items="palcos"
                           item-text="nombre"
                           item-value="id"
                           v-model="palco"
                          label="Seleccione un palco"
                        ></v-select>
                    </div>
                     </div>
                       <div class="col-md-12 col-lg-12">
                            <label for="puestoActual" v-if="puestoActual != null">Puesto</label>
                          <div class="form-group" v-if="puestoActual != null">
                            <div class="form-line">
                                <input type="text" id="puestoActual" class="form-control"
                                    placeholder="Puesto" :value="puestoActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="searchPuestos" class="form-control"
                                    placeholder="Buscar puestos..." v-model="keyPuestos" @keyup.enter="searchPuestos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(puestos && puestos.length > 0)">
                        <v-select
                          :items="puestos"
                           item-text="numero"
                           item-value="id"
                           v-model="puesto"
                          label="Seleccione un puesto"
                        ></v-select>
                    </div>
                       </div>
                 </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info waves-effect" @click="savePuestoPalcoEvento">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
    </div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

    export default {
        beforeCreate(){
           this.$store.dispatch('puestopalcoevento/listPuestosPalcosEventos', 1);
        },
        created(){
           document.title = 'Puestos palco evento - TicketShop';
        },
        async mounted(){
           //adminApp() 
           this.$store.dispatch('puestopalcoevento/listPuestosPalcosEventos', 1);
        },
        data(){
            return {
                titleModalPuestoPalcoEvento: null,
                evento: null,
                keyEventos: null,
                puestoActual: null,
                puesto: null,
                keyPuestos: null,
                palco_e: null,
                palcoActual: null,
                palco: null,
                keyPalcos: null,
                search: '',
                headers: [
                  {
                    text: 'Id',
                    align: 'left',
                    sortable: false,
                    value: 'id_palco_evento'
                  },
                  {text: 'Palco', value: 'palco.nombre',align: 'center' },
                  {text: 'Puesto', value: 'puesto.numero',align: 'center' },
                  {text: 'Acciones',value: '', align: 'right'}
             ],
             json_fields: {
            'Id': 'id_palco_evento',
            'Palco': 'palco.nombre',
            'Puesto': 'puesto.numero',

        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
            }
        },
        watch: {
            pagination: {
              async handler () {
                this.$store.dispatch('puestopalcoevento/listPuestosPalcosEventos', this.pagination.page);
              },
              deep: true
            }
          },
        computed: {
            puestosPalcosEventos(){
                return this.$store.getters['puestopalcoevento/listPuestosPalcosEventos'];
            },
            puestopalcoevento(){
                return this.$store.getters['puestopalcoevento/puestopalcoevento'];
            },
            pagination(){
                return this.$store.getters['puestopalcoevento/pagination'];
              },
             pages () {
                return this.$store.getters['puestopalcoevento/pages'];
              },
              totalItemCount(){
                return this.$store.getters['puestopalcoevento/totalItemCount'];
              },
              loading(){
                    return this.$store.getters['puestopalcoevento/loading'];
              },
               eventos(){
                return this.$store.getters['evento/listEventos'];
              },
                puestos(){
                return this.$store.getters['puesto/listPuestos'];
              },
                palcos(){
                return this.$store.getters['palco/listPalcos'];
              },
        },
        methods: {
            deletePuestoPalcoEvento(id_palco_evento){
                var self = this;
                    swal({
                    title: "¿Estas seguro de eliminar el registro?",
                    text: "El registro se eliminara permanentemente!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Si, Eliminarlo!",
                    cancelButtonText: "No, cancelar!",
                    closeOnConfirm: false,
                    closeOnCancel: false
                }, function (isConfirm) {
                    if (isConfirm) {
                        self.$store.dispatch('puestopalcoevento/deletePuestoPalcoEvento',id_palco_evento);
                    } else {
                        swal("Cancelado", "Se ha cancelado la operacion.", "error");
                    }
                });
            },
            savePuestoPalcoEvento(){
                if((this.puestopalcoevento != null || this.evento != null) &&
                  (this.puestopalcoevento != null || this.palco_e != null) &&
                  (this.puestoActual != null || this.puesto != null) &&
                  (this.palcoActual != null || this.palco != null)){ 
                if(this.puestopalcoevento){
                     var palcoUpdate = null;
                    if(this.palco != null && this.palco != ''){
                        palcoUpdate = this.palco;
                    }else{
                        palcoUpdate = this.puestopalcoevento.id_palco;
                    }
                       var puestoUpdate = null;
                    if(this.puesto != null && this.puesto != ''){
                        puestoUpdate = this.puesto;
                    }else{
                        puestoUpdate = this.puestopalcoevento.id_puesto;
                    }
                    this.$store.dispatch('puestopalcoevento/updatePuestoPalcoEvento',
                       {
                          id: this.puestopalcoevento.id_palco_evento,
                          id_palco_old: this.puestopalcoevento.id_palco,
                          id_palco_new: palcoUpdate,
                          id_puesto_old: this.puestopalcoevento.id_puesto,
                          id_puesto_new: puestoUpdate
                       });
                    //console.log('estamos editando un boletaEvento');
                    $('#modalPuestoPalcoEvento').modal('hide');
                }else{  
                    this.$store.dispatch('puestopalcoevento/newPuestoPalcoEvento',
                       {
                          id_evento_e: this.evento,
                          id_palco_e: this.palco_e,
                          id_palco: this.palco,
                          id_puesto: this.puesto
                       });
                    //console.log('estamos guardando un boletaEvento');
                    $('#modalPuestoPalcoEvento').modal('hide');
                }
            }
                
            },
            viewPuestoPalcoEvento(id){
                this.$store.commit('puestopalcoevento/viewPuestoPalcoEvento',id);
                this.palcoActual = this.puestopalcoevento.palco.nombre;
                this.puestoActual = this.puestopalcoevento.puesto.numero;
                this.evento = null;
                this.keyEventos = null;
                this.puesto = null;
                this.keyPuestos = null;
                this.palco = null;
                this.keyPalcos = null;
                this.palco_e = null;
                this.titleModalPuestoPalcoEvento = `Editar Registro`;
            },
            setNullPuestoPalcoEvento(){
                this.$store.commit('puestopalcoevento/viewPuestoPalcoEvento',null);
                this.palcoActual = null;
                this.puestoActual = null;
                this.evento = null;
                this.keyEventos = null;
                this.puesto = null;
                this.keyPuestos = null;
                this.palco = null;
                this.keyPalcos = null;
                this.palco_e = null;
                this.titleModalPuestoPalcoEvento = 'Nuevo Registro';
            },
             changePage(page){
              this.$store.commit('puestopalcoevento/changePage',page)
              this.$store.dispatch('puestopalcoevento/listPuestosPalcosEventos',page);
            },
              searchEventos(){
                this.$store.dispatch('evento/searchEventos', this.keyEventos);
            },
             searchPuestos(){
                this.$store.dispatch('puesto/searchPuestos', this.keyPuestos);
            },
               searchPalcos(){
                this.$store.dispatch('palco/searchPalcos', this.keyPalcos);
            },
        }
    }
</script>
<style scoped>
.changes{
    margin-right: 5px;
    margin-bottom: 12px;
}
</style>