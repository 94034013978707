import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       monedas: [],
       moneda: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listMonedas: (state,getters,rootState) => {
          return state.monedas;
    },
    moneda: (state,getters,rootState) => {
        return state.moneda;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listMonedas: (state,monedas) => {
          state.monedas = monedas.data;
          state.pagination.totalItems = monedas.total;
          state.pagination.page = monedas.current_page;
          state.pagination.rowsPerPage = monedas.per_page;
       },
      listMonedasSearch: (state,monedas) => {
            if(monedas != null){ 
                state.monedas = monedas;
            }else{
              state.monedas = null;
            }
       },
      viewMoneda(state,codigo_moneda){
        let auxMoneda = state.monedas.filter((item) => item.codigo_moneda == codigo_moneda);
        state.moneda = auxMoneda[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listMonedas({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}moneda?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listMonedas',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener las monedas!", "error");
        console.error(error);
      });

    },
  newMoneda({state,getters,commit,rootState},moneda){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}moneda`,
       { 
          codigo_moneda: moneda.codigo_moneda,
          descripcion: moneda.descripcion,
          simbolo: moneda.simbolo
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo la moneda correctamente!", "success");
        store.dispatch('moneda/listMonedas',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear la moneda!", "error");
        console.error(error);
      });
  },
  updateMoneda({state,getters,commit,rootState},moneda){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}moneda/${moneda.codigo_moneda}`,
        { 
          descripcion: moneda.descripcion,
          simbolo: moneda.simbolo
        },
        {headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo la moneda correctamente!", "success");
        store.dispatch('moneda/listMonedas',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar la moneda!", "error");
        console.error(error);
      });
  },
  deleteMoneda({state,getters,commit,rootState},moneda){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}moneda/${moneda}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino la moneda correctamente!", "success");
        store.dispatch('moneda/listMonedas',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar la moneda!","error");
        console.log(error);
     });
  },
    searchMonedas({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarMoneda?descripcion=${key}`,null,{headers: headers})
      .then(response => {
        commit('listMonedasSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener las monedas!", "error");
        console.error(error);
      });

    },
  },
}