import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       grupoVendedoresPunto: [],
       grupoVendedorPunto: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listGrupoVendedoresPunto: (state,getters,rootState) => {
          return state.grupoVendedoresPunto;
    },
    grupoVendedorPunto: (state,getters,rootState) => {
        return state.grupoVendedorPunto;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listGrupoVendedoresPunto: (state,grupoVendedoresPunto) => {
          state.grupoVendedoresPunto = grupoVendedoresPunto.data;
          state.pagination.totalItems = grupoVendedoresPunto.total;
          state.pagination.page = grupoVendedoresPunto.current_page;
          state.pagination.rowsPerPage = grupoVendedoresPunto.per_page;
       },
      viewGrupoVendedorPunto(state,payload){
        let auxGrupoVendedorPunto = state.grupoVendedoresPunto.filter((item) => 
          (item.id_grupo_vendedores == payload.id_grupo_vendedores && item.id_punto_venta == payload.id_punto_venta));
        state.grupoVendedorPunto = auxGrupoVendedorPunto[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listGrupoVendedoresPunto({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}grupovendedorespto?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listGrupoVendedoresPunto',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los grupos de vendedores!", "error");
        console.error(error);
      });

    },
  newGrupoVendedorPunto({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}grupovendedorespto`,
        {
          id_grupo_vendedores: payload.id_grupo_vendedores,
          id_punto_venta: payload.id_punto_venta,
         }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo el grupo vendedor por punto correctamente!", "success");
        store.dispatch('grupovendedorpunto/listGrupoVendedoresPunto',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear el grupo vendedor por punto!", "error");
        console.error(error);
      });
  },
  updateGrupoVendedorPunto({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}grupovendedorespto/${payload.id}`,
        {
          id_punto_venta_old: payload.id_punto_venta_old,
          id_punto_venta_new: payload.id_punto_venta_new,
        },
        {headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo el grupo vendedor por punto correctamente!", "success");
        store.dispatch('grupovendedorpunto/listGrupoVendedoresPunto',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar el grupo vendedor por punto!", "error");
        console.error(error);
      });
  },
  deleteGrupoVendedorPunto({state,getters,commit,rootState},grupoVendedorPunto){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}grupovendedorespto/${grupoVendedorPunto}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino el grupo vendedor por punto correctamente!", "success");
        store.dispatch('grupovendedorpunto/listGrupoVendedoresPunto',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar el grupo vendedor por punto!","error");
        console.log(error);
     });
  },
  },
}