import axios from 'axios';
import {configApi} from '../api/configApi';
import {router} from '../main.js';
import {store} from '@/store';
import {headers} from '../api/headers';

export const actions = {

      async doLogin({ commit }, loginData) {
      localStorage.clear();
      commit('changeLoader', true);
      commit('loginStart');
      await axios.post(`${configApi.apiUrl}login`,loginData)
      .then(response => {
        if(response.data.success.usuario.id_rol == 2){
          swal("Error!", "Acceso restringido a este sitio!", "error");
          store.state.loggingIn = null;
        }else{
          localStorage.setItem('accessTokenTicketClient', response.data.success.token);
          axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.success.token}`;
          commit('loginStop', null);
          commit('updateAccessToken', response.data.success.token);
          commit('user/updateDataUserLogged',response.data.success.usuario);
          router.replace('/');
        }
      }).catch(error => {
        console.log('error');
        commit('loginStop', error.response.data.error);
        commit('updateAccessToken', null);
        commit('changeLoader', false);
        swal("Datos incorrectos!", "Por favor verifique su usuario y contraseña!", "error");
      }).finally(()=> {
        commit('changeLoader', false);
      })
    },
    logout({ commit }) {
      commit('changeLoader', true);
      axios.post(`${configApi.apiUrl}logout`,null,{headers: headers})
      .then(response => {
        //localStorage.removeItem('accessTokenTicketClient');
        commit('user/updateDataUserLogged',{
              nombre: null,
              email: null,
              identificacion: null,
              telefono: null,
              direccion: null,
              ciudad: null,
              departamento: null,
              id_rol: null
            });
        commit('logout');
        localStorage.clear();
        router.go('/');
        commit('changeLoader', false);
      })
      .catch(error => {
        //console.error(error);
        //localStorage.removeItem('accessTokenTicketClient');
        commit('user/updateDataUserLogged',{
              nombre: null,
              email: null,
              identificacion: null,
              telefono: null,
              direccion: null,
              ciudad: null,
              departamento: null,
              id_rol: null
            });
        commit('logout');
        localStorage.clear();
        router.go('/');
        //commit('changeLoader', false);
      });
    },
    logoutLocal({commit}){
      localStorage.removeItem('accessTokenTicketClient');
      commit('user/updateDataUserLogged',{
        nombre: null,
        email: null,
        identificacion: null,
        telefono: null,
        direccion: null,
        ciudad: null,
        departamento: null,
        id_rol: null
      });
      commit('logout');
      localStorage.clear();
      router.push('/');
    },
    validateToken({commit}){
      axios.get(`${configApi.apiUrl}validateToken`,null,{headers: headers})
      .then(response => {
         if(response.data.status){
            commit('user/updateDataUserLogged',response.data.user);
         }else{
           store.dispatch('logoutLocal');
         }
      })
      .catch(error => {
       console.log('ocurrio un eror');
       store.dispatch('logoutLocal');
      });
    },
    resetPassword({ commit }, email) {
      commit('changeLoader', true);
      axios.post(`${configApi.apiUrl}creatreset`, {
          email: email,
      })
      .then(response => {
        commit('changeLoader', false);
        swal("Exito!", "Hemos enviado por correo electrónico su enlace de restablecimiento de contraseña!", "success");
        //console.log(response.data);
      })
      .catch(error => {
        commit('changeLoader', false);
        swal("Datos incorrectos!", "Por favor verifique su email!", "error");
      });
      },
      findTokenPasswordReset({commit},token){
        commit('changeLoader', true);
        axios.get(`${configApi.apiUrl}password/find/${token}`,null)
        .then(response => {
          //console.log(response.data);
          if(response.data.success){
            commit('tokenResetPassword',true);
            commit('changeLoader', false);
          }
        })
        .catch(error => {
            commit('tokenResetPassword',null);
            commit('changeLoader', false);
            swal("Token invalido!", "El token ya caduco o es invalido!", "error");
            console.error(error);
        });
      },
      resetPasswordToken({commit},payload){
          commit('changeLoader', true);
          axios.post(`${configApi.apiUrl}reset`,
          {
            email: payload.email,
            password: payload.password_new,
            password_confirmation: payload.password_new_confirmed,
            token: payload.token 
          })
          .then(response => {
              commit('changeLoader', false);
              swal("Exito!","La contraseña se restablecio correctamente!","success");
              router.push('/login');
          })
          .catch(error => {
              commit('changeLoader', false);
              swal("Error!","Ocurrio un error al restablecer su contraseña!","error");
          });
      },

};