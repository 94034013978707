<template>
	<div>
        <div class="card">
        <div id="aniimated-thumbnials" class="list-unstyled row clearfix">
            <div class="col-md-2 col-lg-2 col-sm-3 col-xs-12" v-for="imagen in imagenesShow" style="padding-right: 3px;padding-left: 3px;">
              <label>
                <input type="radio" name="imagen-select" :value="imagen.id" :id="'input_imagen_select'+imagen.id" class="input_imagen_select">
                <img :id="'imagencharged'+imagen.id" :src="imagen.url"
                 class="img-responsive img-thumbnail rounded"
                  :title="imagen.nombre"
                   >
                 </label>
            </div>
        </div>
      <div class="text-xs-center pt-2">
      <v-pagination v-model="paginationImagenes.page" :length="pagesImagenes"></v-pagination>
    </div>
     </div>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        props: [
            'imagenesActivas'
        ],
        beforeCreate(){
           this.$store.dispatch('imagen/listImagenes', 1);
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('imagen/listImagenes', 1);
        },
        created(){
          $("html,body").hover(function(){
            $(".input_imagen_select").prop('checked',false);
          });
        },
        data(){
        	return {
                dialog: false,
                notifications: false,
                sound: true,
                widgets: false,
        	}
        },
        watch: {
		    paginationImagenes: {
		      async handler () {
      	   		this.$store.dispatch('imagen/listImagenes', this.paginationImagenes.page);
		      },
		      deep: true
		    },
        imagenesShow:{
          async handler (){
             return this.imagenes.filter((imagen) => 
                  (this.imagenesActivas.filter((img) => img.id_imagen == imagen.id).length <= 0) 
              );
          },
          deep: true
        }
		  },
        computed: {
        	imagenes(){
        		return this.$store.getters['imagen/listImagenes'];
        	},
          imagenesShow(){
            return this.imagenes.filter((imagen) => 
                  (this.imagenesActivas.filter((img) => img.id_imagen == imagen.id).length <= 0)
            );
          },
		    paginationImagenes(){
        		return this.$store.getters['imagen/pagination'];
		      },
		     pagesImagenes () {
		        return this.$store.getters['imagen/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['imagen/totalItemCount'];
		      },
        },
        methods: {
	         changePage(page){
    		  this.$store.commit('imagen/changePage',page)
	          this.$store.dispatch('imagen/listImagenes',page);
	        },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
#aniimated-thumbnials img{
    margin-bottom: 5px;
    cursor: pointer;
    width:170px;
    height:120px;
}
#aniimated-thumbnials img:hover{
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.44);
}
.apunta{
  border:2px red solid;
}
input[type='radio']:checked + img {
      outline: 2px solid #1976d2;
      box-shadow: 0px 0px 10px;
}
</style>