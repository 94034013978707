import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       puestosPalcosEventos: [],
       puestopalcoevento: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listPuestosPalcosEventos: (state,getters,rootState) => {
          return state.puestosPalcosEventos;
    },
    puestopalcoevento: (state,getters,rootState) => {
        return state.puestopalcoevento;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listPuestosPalcosEventos: (state,puestosPalcosEventos) => {
          state.puestosPalcosEventos = puestosPalcosEventos.data;
          state.pagination.totalItems = puestosPalcosEventos.total;
          state.pagination.page = puestosPalcosEventos.current_page;
          state.pagination.rowsPerPage = puestosPalcosEventos.per_page;
       },
      viewPuestoPalcoEvento(state,id){
        let auxPuestoPalcoEvento = state.puestosPalcosEventos.filter((item) => item.id_palco_evento == id);
        state.puestopalcoevento = auxPuestoPalcoEvento[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listPuestosPalcosEventos({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}puestospalcoevento?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listPuestosPalcosEventos',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los puestos palcos eventos!", "error");
        console.error(error);
      });

    },
  newPuestoPalcoEvento({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}puestospalcoevento`,
        {
            id_evento_e: payload.id_evento_e,
            id_palco_e: payload.id_palco_e,
            id_palco: payload.id_palco,
            id_puesto: payload.id_puesto
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo el puesto palco evento correctamente!", "success");
        store.dispatch('puestopalcoevento/listPuestosPalcosEventos',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear el puesto palco evento!", "error");
        console.error(error);
      });
  },
  updatePuestoPalcoEvento({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}puestospalcoevento/${payload.id}`,
       {
            id_palco_old: payload.id_palco_old,
            id_puesto_old: payload.id_puesto_old,
            id_palco_new: payload.id_palco_new,
            id_puesto_new: payload.id_puesto_new
        },
        {headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo el puesto palco evento correctamente!", "success");
        store.dispatch('puestopalcoevento/listPuestosPalcosEventos',state.pagination.page);
      }) 
      .catch(error => {
        swal("Error!", "Error al actualizar el puesto palco evento!", "error");
        console.error(error);
      });
  },
  deletePuestoPalcoEvento({state,getters,commit,rootState},id_palco_evento){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}puestospalcoevento/${id_palco_evento}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino el puesto palco evento correctamente!", "success");
        store.dispatch('puestopalcoevento/listPuestosPalcosEventos',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar el puesto palco evento!","error");
        console.log(error);
     });
  },
  },
}