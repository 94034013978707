import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       temporadas: [],
       temporada: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listTemporadas: (state,getters,rootState) => {
          return state.temporadas;
    },
    temporada: (state,getters,rootState) => {
        return state.temporada;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listTemporadas: (state,temporadas) => {
          state.temporadas = temporadas.data;
          state.pagination.totalItems = temporadas.total;
          state.pagination.page = temporadas.current_page;
          state.pagination.rowsPerPage = temporadas.per_page;
       },
      listTemporadasSearch: (state,temporadas) => {
            if(temporadas != null){ 
                state.temporadas = temporadas;
            }else{
              state.temporadas = null;
            }
       },
      viewTemporada(state,id){
        let auxTemporada = state.temporadas.filter((item) => item.id == id);
        state.temporada = auxTemporada[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listTemporadas({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}temporada?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listTemporadas',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener las temporadas!", "error");
        console.error(error);
      });

    },
  newTemporada({state,getters,commit,rootState},temporada){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}temporada`,
        {nombre: temporada.nombre,status: temporada.status}
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo la temporada correctamente!", "success");
        store.dispatch('temporada/listTemporadas',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear la temporada!", "error");
        console.error(error);
      });
  },
  updateTemporada({state,getters,commit,rootState},temporada){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}temporada/${temporada.id}`,
        {nombre: temporada.nombre,status: temporada.status},
        {headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo la temporada correctamente!", "success");
        store.dispatch('temporada/listTemporadas',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar la temporada!", "error");
        console.error(error);
      });
  },
  deleteTemporada({state,getters,commit,rootState},temporada){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}temporada/${temporada}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino la temporada correctamente!", "success");
        store.dispatch('temporada/listTemporadas',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar la temporada!","error");
        console.log(error);
     });
  },
    searchTemporadas({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarTemporada?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listTemporadasSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener las temporadas!", "error");
        console.error(error);
      });

    },
  },
}