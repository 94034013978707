<template>
    <div>
         <v-app id="inspire" v-if="descuentosEventos != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-percentage"></i><strong> Listado de</strong> Descuentos por evento</h2>
                            <div class="text-right">
                            <button @click="setNullDescuentoEvento" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalDescuentoEvento">NUEVO REGISTRO</button>
                        </div>
                        <div>
                             <export-excel style="width: fit-content;" :data="descuentosEventos" :fields="json_fields" worksheet="Listado de descuentos por evento" name="descuentosEventos.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
            must-sort
            :items='descuentosEventos'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado descuentos por evento...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td class="text-xs-center">{{ props.item.nombre }}</td>
      <td class="text-xs-center">{{ props.item.evento.nombre }}</td>
      <td class="text-xs-center">{{ props.item.fecha_hora_inicio }}</td>
      <td class="text-xs-center">{{ props.item.fecha_hora_fin }}</td>
      <td class="text-xs-center">{{ props.item.status }}</td>
      <td class="text-xs-center">{{ props.item.tipo_descuento.nombre }}</td>
      <td class="text-xs-center">{{ props.item.porcentaje }}</td>
      <td class="text-xs-center">{{ props.item.monto }}</td>
      <td class="text-xs-center">{{ props.item.cantidad_compra }}</td>
      <td class="text-xs-center">{{ props.item.cantidad_paga }}</td>
      <td class="text-xs-center">{{ props.item.codigo_moneda.codigo_moneda }} - {{ props.item.codigo_moneda.descripcion }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewDescuentoEvento(props.item.id)"
           data-toggle="modal" data-target="#modalDescuentoEvento">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteDescuentoEvento(props.item.id)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalDescuentoEvento" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalDescuentoEvento }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                    <div class="col-md-4 col-lg-4">
                        <label for="nameDescuentoEvento">Nombre</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="nameDescuentoEvento" class="form-control"
                                    placeholder="Nombre" required="" v-model="nameDescuentoEvento">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                        <label for="fechaHoraInicioDescuentoEvento">Fecha hora inicio</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="fechaHoraInicioDescuentoEvento" class="form-control datepicker"
                                    placeholder="Fecha hora inicio" required="">
                            </div>
                        </div>
                    </div>
                        <div class="col-md-4 col-lg-4">
                        <label for="fechaHoraFinDescuentoEvento">Fecha hora fin</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="fechaHoraFinDescuentoEvento" class="form-control datepicker"
                                    placeholder="Fecha hora fin" required="">
                            </div>
                        </div>
                    </div>
                        <div class="col-md-4 col-lg-4">
                        <label for="statusDescuentoEvento">Estado</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="statusDescuentoEvento" class="form-control"
                                    placeholder="Estado" required="" v-model="statusDescuentoEvento">
                            </div>
                        </div>
                    </div>
                        <div class="col-md-4 col-lg-4">
                        <label for="porcentajeDescuentoEvento">Porcentaje</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="porcentajeDescuentoEvento" class="form-control"
                                    placeholder="Porcentaje" required="" v-model="porcentajeDescuentoEvento">
                            </div>
                        </div>
                    </div>
                        <div class="col-md-4 col-lg-4">
                        <label for="montoDescuentoEvento">Monto</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" step="any" id="montoDescuentoEvento" class="form-control"
                                    placeholder="Monto" required="" v-model="montoDescuentoEvento">
                            </div>
                        </div>
                    </div>
                        <div class="col-md-6 col-lg-6">
                        <label for="cantidadCompraDescuentoEvento">Cantidad compra</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="cantidadCompraDescuentoEvento" class="form-control"
                                    placeholder="Cantidad compra" required="" v-model="cantidadCompraDescuentoEvento">
                            </div>
                        </div>
                    </div>
                     <div class="col-md-6 col-lg-6">
                        <label for="cantidadPagaDescuentoEvento">Cantidad paga</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="cantidadPagaDescuentoEvento" class="form-control"
                                    placeholder="Cantidad paga" required="" v-model="cantidadPagaDescuentoEvento">
                            </div>
                        </div>
                    </div>
                      <div class="col-lg-12 col-md-12">
                              <label for="eventoActual" v-if="eventoActual != null">Evento</label>
                          <div class="form-group" v-if="eventoActual != null">
                            <div class="form-line">
                                <input type="text" id="eventoActual" class="form-control"
                                    placeholder="Evento" :value="eventoActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchEventos" class="form-control"
                                    placeholder="Buscar eventos..." v-model="keyEventos" @keyup.enter="searchEventos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(eventos && eventos.length > 0)">
                        <v-select
                          :items="eventos"
                           item-text="nombre"
                           item-value="id"
                           v-model="evento"
                          label="Seleccione un evento"
                        ></v-select>
                    </div>
                       </div>
                          <div class="col-md-6 col-lg-6">
                            <label for="tipoDescuentoActual" v-if="tipoDescuentoActual != null">Tipo de descuento</label>
                          <div class="form-group" v-if="tipoDescuentoActual != null">
                            <div class="form-line">
                                <input type="text" id="tipoDescuentoActual" class="form-control"
                                    placeholder="Tipo de descuento" :value="tipoDescuentoActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchTipoDescuento" class="form-control"
                                    placeholder="Buscar tipos de descuento..." v-model="keyTipoDescuento" @keyup.enter="searchTipoDescuento">
                            </div>
                        </div>
                        <div class="form-group" v-if="(tipoDescuentos && tipoDescuentos.length > 0)">
                        <v-select
                          :items="tipoDescuentos"
                           item-text="nombre"
                           item-value="id"
                           v-model="tipoDescuento"
                          label="Seleccione un tipo de descuento"
                        ></v-select>
                    </div>
                       </div>
                        <div class="col-md-6 col-lg-6">
                            <label for="monedaActual" v-if="monedaActual != null">Moneda</label>
                          <div class="form-group" v-if="monedaActual != null">
                            <div class="form-line">
                                <input type="text" id="monedaActual" class="form-control"
                                    placeholder="Moneda" :value="monedaActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchMonedas" class="form-control"
                                    placeholder="Buscar monedas..." v-model="keyMonedas" @keyup.enter="searchMonedas">
                            </div>
                        </div>
                        <div class="form-group" v-if="(monedas && monedas.length > 0)">
                        <v-select
                          :items="monedas"
                           item-text="descripcion"
                           item-value="codigo_moneda"
                           v-model="moneda"
                          label="Seleccione una moneda"
                        ></v-select>
                    </div>
                       </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info waves-effect" @click="saveDescuentoEvento">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
    </div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';
import moment from 'moment';

moment.locale('es');

    export default {
        beforeCreate(){
           this.$store.dispatch('descuentoevento/listDescuentosEventos', 1);
        },
        created(){
           document.title = 'Descuentos por evento - TicketShop';
        },
        async mounted(){
           //adminApp() 
           this.$store.dispatch('descuentoevento/listDescuentosEventos', 1);
        },
        data(){
            return {
                titleModalDescuentoEvento: null,
                nameDescuentoEvento: null,
                statusDescuentoEvento: null,
                porcentajeDescuentoEvento: null,
                montoDescuentoEvento: null,
                cantidadCompraDescuentoEvento: null,
                cantidadPagaDescuentoEvento: null,
                eventoActual: null,
                evento: null,
                keyEventos: null,
                monedaActual: null,
                moneda: null,
                keyMonedas: null,
                tipoDescuentoActual: null,
                tipoDescuento: null,
                keyTipoDescuento: null,
                search: '',
                headers: [
                  {
                    text: 'Id',
                    align: 'left',
                    sortable: false,
                    value: 'id'
                  },
                  {text: 'Nombre', value: 'nombre',align: 'center' },
                  {text: 'Evento', value: 'evento.nombre',align: 'center' },
                  {text: 'Fecha hora inicio', value: 'fecha_hora_inicio',align: 'center' },
                  {text: 'Fecha hora fin', value: 'fecha_hora_fin',align: 'center' },
                  {text: 'Estado', value: 'status',align: 'center' },
                  {text: 'Tipo descuento', value: 'tipo_descuento.nombre',align: 'center' },
                  {text: 'Porcentaje', value: 'porcentaje',align: 'center' },
                  {text: 'Monto', value: 'monto',align: 'center' },
                  {text: 'Cantidad compra', value: 'cantidad_compra',align: 'center' },
                  {text: 'Cantidad paga', value: 'cantidad_paga',align: 'center' },
                  {text: 'Moneda', value: 'codigo_moneda.codigo_moneda',align: 'center' },
                  {text: 'Acciones',value: '', align: 'right'}
             ],
             json_fields: {
            'Id': 'id',
            'Nombre': 'nombre',
            'Evento': 'evento.nombre',
            'Fecha hora inicio': 'fecha_hora_inicio',
            'Fecha hora fin': 'fecha_hora_fin',
            'Estado': 'status',
            'Tipo descuento': 'tipo_descuento.nombre',
            'Porcentaje': 'porcentaje',
            'Monto': 'monto',
            'Cantidad compra': 'cantidad_compra',
            'Cantidad paga': 'cantidad_paga',
            'Moneda': 'codigo_moneda.codigo_moneda',

        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
            }
        },
        watch: {
            pagination: {
              async handler () {
                this.$store.dispatch('descuentoevento/listDescuentosEventos', this.pagination.page);
              },
              deep: true
            }
          },
        computed: {
            descuentosEventos(){
                return this.$store.getters['descuentoevento/listDescuentosEventos'];
            },
            descuentoevento(){
                return this.$store.getters['descuentoevento/descuentoevento'];
            },
            pagination(){
                return this.$store.getters['descuentoevento/pagination'];
              },
             pages () {
                return this.$store.getters['descuentoevento/pages'];
              },
              totalItemCount(){
                return this.$store.getters['descuentoevento/totalItemCount'];
              },
              loading(){
                    return this.$store.getters['descuentoevento/loading'];
              },
                eventos(){
                return this.$store.getters['evento/listEventos'];
              },
                monedas(){
                return this.$store.getters['moneda/listMonedas'];
              },
                tipoDescuentos(){
                return this.$store.getters['tipodescuento/listTipoDescuento'];
              },
        },
        methods: {
            deleteDescuentoEvento(id){
                var self = this;
                    swal({
                    title: "¿Estas seguro de eliminar el registro?",
                    text: "El registro se eliminara permanentemente!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Si, Eliminarlo!",
                    cancelButtonText: "No, cancelar!",
                    closeOnConfirm: false,
                    closeOnCancel: false
                }, function (isConfirm) {
                    if (isConfirm) {
                        self.$store.dispatch('descuentoevento/deleteDescuentoEvento',id);
                    } else {
                        swal("Cancelado", "Se ha cancelado la operacion.", "error");
                    }
                });
            },
            saveDescuentoEvento(){
                if(this.nameDescuentoEvento != null && $("#fechaHoraInicioDescuentoEvento").val() != '' &&
                $("#fechaHoraFinDescuentoEvento").val() != '' && this.statusDescuentoEvento != null &&
                this.porcentajeDescuentoEvento != null && this.montoDescuentoEvento != null &&
                this.cantidadCompraDescuentoEvento != null && this.cantidadPagaDescuentoEvento != null &&
                (this.evento != null || this.eventoActual != null) &&
                (this.moneda != null || this.monedaActual != null) &&
                (this.tipoDescuento != null || this.tipoDescuentoActual != null)){ 
                if(this.descuentoevento){

                    var eventoUpdate = null;
                    if(this.evento != null && this.evento != ''){
                        eventoUpdate = this.evento;
                    }else{
                        eventoUpdate = this.descuentoevento.id_evento;
                    }
                      var monedaUpdate = null;
                    if(this.moneda != null && this.moneda != ''){
                        monedaUpdate = this.moneda;
                    }else{
                        monedaUpdate = this.descuentoevento.codigo_moneda.codigo_moneda;
                    }
                      var tipoDescuentoUpdate = null;
                    if(this.tipoDescuento != null && this.tipoDescuento != ''){
                        tipoDescuentoUpdate = this.tipoDescuento;
                    }else{
                        tipoDescuentoUpdate = this.descuentoevento.tipo_descuento.id;
                    }
                    this.$store.dispatch('descuentoevento/updateDescuentoEvento',
                    {
                        id: this.descuentoevento.id,
                        nombre: this.nameDescuentoEvento,
                        id_evento: eventoUpdate,
                        fecha_hora_inicio: $("#fechaHoraInicioDescuentoEvento").val(),
                        fecha_hora_fin: $("#fechaHoraFinDescuentoEvento").val(),
                        status: this.statusDescuentoEvento,
                        tipo_descuento: tipoDescuentoUpdate,
                        porcentaje: this.porcentajeDescuentoEvento,
                        monto: this.montoDescuentoEvento,
                        cantidad_compra: this.cantidadCompraDescuentoEvento,
                        cantidad_paga: this.cantidadPagaDescuentoEvento,
                        codigo_moneda: monedaUpdate
                    });
                    //console.log('estamos editando un descuentoevento');
                    $('#modalDescuentoEvento').modal('hide');
                }else{  
                    this.$store.dispatch('descuentoevento/newDescuentoEvento',
                        {
                            nombre: this.nameDescuentoEvento,
                            id_evento: this.evento,
                            fecha_hora_inicio: $("#fechaHoraInicioDescuentoEvento").val(),
                            fecha_hora_fin: $("#fechaHoraFinDescuentoEvento").val(),
                            status: this.statusDescuentoEvento,
                            tipo_descuento: this.tipoDescuento,
                            porcentaje: this.porcentajeDescuentoEvento,
                            monto: this.montoDescuentoEvento,
                            cantidad_compra: this.cantidadCompraDescuentoEvento,
                            cantidad_paga: this.cantidadPagaDescuentoEvento,
                            codigo_moneda: this.moneda
                        });
                    //console.log('estamos guardando un descuentoevento');
                    $('#modalDescuentoEvento').modal('hide');
                }
            }
                
            },
            viewDescuentoEvento(id){
                this.$store.commit('descuentoevento/viewDescuentoEvento',id);
                this.nameDescuentoEvento = this.descuentoevento.nombre;
                $("#fechaHoraInicioDescuentoEvento").val(moment(this.descuentoevento.fecha_hora_inicio).format('YYYY-MM-DD'));
                $("#fechaHoraFinDescuentoEvento").val(moment(this.descuentoevento.fecha_hora_fin).format('YYYY-MM-DD'));
                this.statusDescuentoEvento = this.descuentoevento.status;
                this.porcentajeDescuentoEvento = this.descuentoevento.porcentaje;
                this.montoDescuentoEvento = this.descuentoevento.monto;
                this.cantidadCompraDescuentoEvento = this.descuentoevento.cantidad_compra;
                this.cantidadPagaDescuentoEvento = this.descuentoevento.cantidad_paga;
                this.eventoActual = this.descuentoevento.evento.nombre;
                this.evento = null;
                this.keyEventos = null;
                this.tipoDescuentoActual = this.descuentoevento.tipo_descuento.nombre;
                this.tipoDescuento = null;
                this.keyTipoDescuento = null;
                this.monedaActual = `${this.descuentoevento.codigo_moneda.codigo_moneda} - ${this.descuentoevento.codigo_moneda.descripcion}`;
                this.moneda = null;
                this.keyMonedas = null;
                this.titleModalDescuentoEvento = `Editar Registro: ${this.descuentoevento.nombre}`;
            },
            setNullDescuentoEvento(){
                this.$store.commit('descuentoevento/viewDescuentoEvento',null);
                this.nameDescuentoEvento = null;
                $("#fechaHoraInicioDescuentoEvento").val('');
                $("#fechaHoraFinDescuentoEvento").val('');
                this.statusDescuentoEvento = null;
                this.porcentajeDescuentoEvento = null;
                this.montoDescuentoEvento = null;
                this.cantidadCompraDescuentoEvento = null;
                this.cantidadPagaDescuentoEvento = null;
                this.eventoActual = null;
                this.evento = null;
                this.keyEventos = null;
                this.tipoDescuentoActual = null;
                this.tipoDescuento = null;
                this.keyTipoDescuento = null;
                this.monedaActual = null;
                this.moneda = null;
                this.keyMonedas = null;
                this.titleModalDescuentoEvento = 'Nuevo Registro';
            },
             changePage(page){
              this.$store.commit('descuentoevento/changePage',page)
              this.$store.dispatch('descuentoevento/listDescuentosEventos',page);
            },
               searchEventos(){
                this.$store.dispatch('evento/searchEventos', this.keyEventos);
            },
             searchMonedas(){
                this.$store.dispatch('moneda/searchMonedas', this.keyMonedas);
            },
            searchTipoDescuento(){
                this.$store.dispatch('tipodescuento/searchTipoDescuento', this.keyTipoDescuento);
            },
        }
    }
</script>
<style scoped>
.changes{
    margin-right: 5px;
    margin-bottom: 12px;
}
</style>