<template>
	<div>
		 <v-app id="inspire" v-if="paises != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-map"></i><strong> Listado de</strong> Paises</h2>
                            <div class="text-right">
                            <button @click="setNullPais" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalPais">NUEVO PAIS</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="paises" :fields="json_fields" worksheet="Listado de paises" name="paises.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='paises'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado paises...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td class="text-xs-center">{{ props.item.descripcion }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewPais(props.item.id)"
           data-toggle="modal" data-target="#modalPais">
            edit
          </v-icon>
          <v-icon
            small
            @click="deletePais(props.item.id)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalPais" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="savePais">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalPais }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                        <label for="namePais">Descripcion</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="namePais" class="form-control"
                                    placeholder="Descripcion del pais" required="" v-model="namePais">
                            </div>
                        </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-info waves-effect">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('pais/listPaises', 1);
        },
		created(){
           document.title = 'Paises - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('pais/listPaises', 1);
        },
        data(){
        	return {
        		titleModalPais: null,
        		namePais: null,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id'
		          },
		          {text: 'Descripcion', value: 'descripcion',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id',
            'Descripcion': 'descripcion',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('pais/listPaises', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	paises(){
        		return this.$store.getters['pais/listPaises'];
        	},
        	pais(){
        		return this.$store.getters['pais/pais'];
        	},
		    pagination(){
        		return this.$store.getters['pais/pagination'];
		      },
		     pages () {
		        return this.$store.getters['pais/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['pais/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['pais/loading'];
		      },
        },
        methods: {
        	deletePais(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar el paispais?",
			        text: "El paispais se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarlo!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('pais/deletePais',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	savePais(){
        		if(this.namePais != '' || this.namePais != null){ 
        		if(this.pais){
        			this.$store.dispatch('pais/updatePais',{pais: this.pais.id,namePais: this.namePais})
        			//console.log('estamos editando un pais');
        			$('#modalPais').modal('hide');
        		}else{	
        			this.$store.dispatch('pais/newPais',this.namePais);
        			//console.log('estamos guardando un pais');
        			$('#modalPais').modal('hide');
        		}
        	}
        		
        	},
        	viewPais(id){
        	    this.$store.commit('pais/viewPais',id);
        	    this.namePais = this.pais.descripcion;
        	    this.titleModalPais = `Editar Pais: ${this.pais.descripcion}`;
        	},
        	setNullPais(){
        		this.$store.commit('pais/viewPais',null);
        		this.namePais = null;
        		this.titleModalPais = 'Nuevo Pais';
        	},
	         changePage(page){
    		  this.$store.commit('pais/changePage',page)
	          this.$store.dispatch('pais/listPaises',page);
	        },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>