import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       paises: [],
       pais: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listPaises: (state,getters,rootState) => {
          return state.paises;
    },
    pais: (state,getters,rootState) => {
        return state.pais;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listPaises: (state,paises) => {
          state.paises = paises.data;
          state.pagination.totalItems = paises.total;
          state.pagination.page = paises.current_page;
          state.pagination.rowsPerPage = paises.per_page;
       },
       listPaisesSearch: (state,paises) => {
            if(paises != null){ 
                state.paises = paises;
            }else{
              state.paises = null;
            }
       },
      viewPais(state,id){
        let auxPais = state.paises.filter((item) => item.id == id);
        state.pais = auxPais[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listPaises({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}pais?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listPaises',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los paises!", "error");
        console.error(error);
      });

    },
  newPais({state,getters,commit,rootState},namePais){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}pais`,{descripcion: namePais},{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo el pais correctamente!", "success");
        store.dispatch('pais/listPaises',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear el pais!", "error");
        console.error(error);
      });
  },
  updatePais({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}pais/${payload.pais}`,{descripcion: payload.namePais},{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo el pais correctamente!", "success");
        store.dispatch('pais/listPaises',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar el pais!", "error");
        console.error(error);
      });
  },
  deletePais({state,getters,commit,rootState},pais){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}pais/${pais}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino el pais correctamente!", "success");
        store.dispatch('pais/listPaises',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar el pais!","error");
        console.log(error);
     });
  },
      searchPaises({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarPais?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listPaisesSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener los paises!", "error");
        console.error(error);
      });

    },
  },
}