import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       artistas: [],
       generos: [],
       artista: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listArtistas: (state,getters,rootState) => {
          return state.artistas;
    },
    artista: (state,getters,rootState) => {
        return state.artista;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listArtistas: (state,artistas) => {
          state.artistas = artistas.data;
          state.pagination.totalItems = artistas.total;
          state.pagination.page = artistas.current_page;
          state.pagination.rowsPerPage = artistas.per_page;
       },
      viewArtista(state,id){
        let auxArtista = state.artistas.filter((item) => item.id == id);
        state.artista = auxArtista[0];
      },
           listArtistasSearch: (state,artistas) => {
            if(artistas != null){ 
                state.artistas = artistas;
            }else{
              state.artistas = null;
            }
       },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listArtistas({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}listado_detalle_artistas?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.artist);
        commit('listArtistas',response.data.data.artist);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los artistas!", "error");
        console.error(error);
      });

    },
  newArtista({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}artista`,
        {
          nombre: payload.nameArtista,
          manager: payload.managerArtista,
          id_genero: payload.genero
        },
        {headers: headers})
      .then(response => {
        swal("Exito!", "Se creo el artista correctamente!", "success");
        store.dispatch('artista/listArtistas',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear el artista!", "error");
        console.error(error);
      });
  },
  updateArtista({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}artista/${payload.artista}`,
        {
          nombre: payload.nameArtista,
          manager: payload.managerArtista,
          id_genero: payload.genero
        },
        {headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo el artista correctamente!", "success");
        store.dispatch('artista/listArtistas',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar el artista!", "error");
        console.error(error);
      });
  },
  deleteArtista({state,getters,commit,rootState},artista){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}artista/${artista}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino el artista correctamente!", "success");
        store.dispatch('artista/listArtistas',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar el artista!","error");
        console.log(error);
     });
  },
      searchArtistas({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarArtistas?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listArtistasSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener los artistas!", "error");
        console.error(error);
      });

    },
  },
}