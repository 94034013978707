<template>
	<div>
		 <v-app id="inspire" v-if="tiposCosto != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-dollar-sign"></i><strong> Listado de</strong> Tipos de costo</h2>
                            <div class="text-right">
                            <button @click="setNullTipoCosto" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalTipoCosto">NUEVO TIPO DE COSTO</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="tiposCosto" :fields="json_fields" worksheet="Listado de tipos de costo" name="tiposCosto.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='tiposCosto'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado tipos de costo...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td class="text-xs-center">{{ props.item.descripcion }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewTipoCosto(props.item.id)"
           data-toggle="modal" data-target="#modalTipoCosto">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteTipoCosto(props.item.id)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalTipoCosto" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="saveTipoCosto">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalTipoCosto }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                        <label for="descripcionTipoCosto">Descripcion</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="descripcionTipoCosto" class="form-control"
                                    placeholder="Descripcion del tipo de costo" required="" v-model="descripcionTipoCosto">
                            </div>
                        </div>
                </div>
                <div class="modal-footer">
                    <button v-if="descripcionTipoCosto != null" type="submit" class="btn btn-info waves-effect">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('tipocosto/listTiposCosto', 1);
        },
		created(){
           document.title = 'Tipos de costo - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('tipocosto/listTiposCosto', 1);
        },
        data(){
        	return {
        		titleModalTipoCosto: null,
        		descripcionTipoCosto: null,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id'
		          },
		          {text: 'Descripcion', value: 'descripcion',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id',
            'Descripcion': 'descripcion',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('tipocosto/listTiposCosto', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	tiposCosto(){
        		return this.$store.getters['tipocosto/listTiposCosto'];
        	},
        	tipocosto(){
        		return this.$store.getters['tipocosto/tipocosto'];
        	},
		    pagination(){
        		return this.$store.getters['tipocosto/pagination'];
		      },
		     pages () {
		        return this.$store.getters['tipocosto/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['tipocosto/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['tipocosto/loading'];
		      },
        },
        methods: {
        	deleteTipoCosto(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar el tipo de costo?",
			        text: "El tipo de costo se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarlo!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('tipocosto/deleteTipoCosto',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	saveTipoCosto(){
        		if(this.descripcionTipoCosto != '' || this.descripcionTipoCosto != null){ 
        		if(this.tipocosto){
        			this.$store.dispatch('tipocosto/updateTipoCosto',{id: this.tipocosto.id,descripcion: this.descripcionTipoCosto})
        			//console.log('estamos editando un tipocosto');
        			$('#modalTipoCosto').modal('hide');
        		}else{	
        			this.$store.dispatch('tipocosto/newTipoCosto',this.descripcionTipoCosto);
        			//console.log('estamos guardando un tipocosto');
        			$('#modalTipoCosto').modal('hide');
        		}
        	}
        		
        	},
        	viewTipoCosto(id){
        	    this.$store.commit('tipocosto/viewTipoCosto',id);
        	    this.descripcionTipoCosto = this.tipocosto.descripcion;
        	    this.titleModalTipoCosto = `Editar Tipo de Costo: ${this.tipocosto.descripcion}`;
        	},
        	setNullTipoCosto(){
        		this.$store.commit('tipocosto/viewTipoCosto',null);
        		this.descripcionTipoCosto = null;
        		this.titleModalTipoCosto = 'Nuevo Tipo de Costo';
        	},
	         changePage(page){
    		  this.$store.commit('tipocosto/changePage',page)
	          this.$store.dispatch('tipocosto/listTiposCosto',page);
	        },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>