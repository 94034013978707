import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       grupoVendedores: [],
       grupoVendedor: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listGrupoVendedores: (state,getters,rootState) => {
          return state.grupoVendedores;
    },
    grupoVendedor: (state,getters,rootState) => {
        return state.grupoVendedor;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listGrupoVendedores: (state,grupoVendedores) => {
          state.grupoVendedores = grupoVendedores.data;
          state.pagination.totalItems = grupoVendedores.total;
          state.pagination.page = grupoVendedores.current_page;
          state.pagination.rowsPerPage = grupoVendedores.per_page;
       },
       listGrupoVendedoresSearch: (state,grupoVendedores) => {
            if(grupoVendedores != null){ 
                state.grupoVendedores = grupoVendedores;
            }else{
              state.grupoVendedores = null;
            }
       },
      viewGrupoVendedor(state,id){
        let auxGrupoVendedor = state.grupoVendedores.filter((item) => item.id == id);
        state.grupoVendedor = auxGrupoVendedor[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listGrupoVendedores({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}grupsvendedore?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listGrupoVendedores',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los grupos de vendedores!", "error");
        console.error(error);
      });

    },
  newGrupoVendedor({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}grupsvendedore`,
        {
          nombre: payload.nameGrupoVendedor,
          caracteristica: payload.caracteristica,
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo el grupo vendedor correctamente!", "success");
        store.dispatch('grupovendedor/listGrupoVendedores',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear el grupo vendedor!", "error");
        console.error(error);
      });
  },
  updateGrupoVendedor({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}grupsvendedore/${payload.id}`,
        {
          nombre: payload.nameGrupoVendedor,
          caracteristica: payload.caracteristica,
        },
        {headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo el grupo vendedor correctamente!", "success");
        store.dispatch('grupovendedor/listGrupoVendedores',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar el grupo vendedor!", "error");
        console.error(error);
      });
  },
  deleteGrupoVendedor({state,getters,commit,rootState},grupoVendedor){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}grupsvendedore/${grupoVendedor}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino el grupo vendedor correctamente!", "success");
        store.dispatch('grupovendedor/listGrupoVendedores',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar el grupo vendedor!","error");
        console.log(error);
     });
  },
  searchGrupoVendedores({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarGrupoVendedores?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listGrupoVendedoresSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener los grupos vendedores!", "error");
        console.error(error);
      });

    },
  },
}