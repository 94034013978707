<template>
	<div>
		  <div class="block-header">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <ul class="breadcrumb breadcrumb-style ">
                            <li class="breadcrumb-item">
                                <h4 class="page-title">Dashboard</h4>
                            </li>
                            <li class="breadcrumb-item bcrumb-1">
                                <router-link :to="{name: 'dashboard'}">
                                    <i class="fas fa-home"></i>
                                     Inicio
                                </router-link>
                            </li>
                            <li v-if="$route.name != 'dashboard'" class="breadcrumb-item active">{{ $route.name }}</li>
                        </ul>
                    </div>
                </div>
            </div>
	</div>
</template>
<script>
	export default{
		created(){
        }
	}
</script>
<style scoped>
.breadcrumb-item{
	text-transform:capitalize;
}
</style>