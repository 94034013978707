<template>
	<div>
		 <v-app id="inspire" v-if="condicionesEventos != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-book-open"></i><strong> Listado de</strong> Condiciones por evento</h2>
                            <div class="text-right">
                            <button @click="setNullCondicionEvento" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalCondicionEvento">NUEVO REGISTRO</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="condicionesEventos" :fields="json_fields" worksheet="Listado de condiciones por evento" name="condicionesEventos.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='condicionesEventos'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado condiciones por evento...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id_evento }}</td>
      <td class="text-xs-center">{{ props.item.evento.nombre }}</td>
      <td class="text-xs-center">{{ props.item.condicion.descripcion }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewCondicionEvento(props.item.id_evento,props.item.id_condiciones)"
           data-toggle="modal" data-target="#modalCondicionEvento">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteCondicionEvento(props.item.id_evento)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalCondicionEvento" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalCondicionEvento }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                     <label for="condicionActual" v-if="condicionActual != null">Condicion</label>
                          <div class="form-group" v-if="condicionActual != null">
                            <div class="form-line">
                                <input type="text" id="condicionActual" class="form-control"
                                    placeholder="Condicion" :value="condicionActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchCondiciones" class="form-control"
                                    placeholder="Buscar condiciones..." v-model="keyCondiciones" @keyup.enter="searchCondiciones">
                            </div>
                        </div>
                        <div class="form-group" v-if="(condiciones && condiciones.length > 0)">
                        <v-select
                          :items="condiciones"
                           item-text="descripcion"
                           item-value="id"
                           v-model="condicion"
                          label="Seleccione una condicion"
                        ></v-select>
                    </div>
                      <label for="eventoActual" v-if="eventoActual != null">Evento</label>
                          <div class="form-group" v-if="eventoActual != null">
                            <div class="form-line">
                                <input type="text" id="eventoActual" class="form-control"
                                    placeholder="Evento" :value="eventoActual">
                            </div>
                        </div>
                        <div class="form-group" v-if="eventoActual == null">
                            <div class="form-line">
                                <input type="text" id="searchEventos" class="form-control"
                                    placeholder="Buscar eventos..." v-model="keyEventos" @keyup.enter="searchEventos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(eventos && eventos.length > 0) && (eventoActual == null)">
                        <v-select
                          :items="eventos"
                           item-text="nombre"
                           item-value="id"
                           v-model="evento"
                          label="Seleccione un evento"
                        ></v-select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button"  v-if="(condicion != null || condicionActual != null) && (evento != null || eventoActual != null)" class="btn btn-info waves-effect" @click="saveCondicionEvento">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('condicionevento/listCondicionesEventos', 1);
        },
		created(){
           document.title = 'Condiciones por Evento - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('condicionevento/listCondicionesEventos', 1);
        },
        data(){
        	return {
        		titleModalCondicionEvento: null,
        		evento: null,
                eventoActual: null,
                keyEventos: null,
                condicionActual: null,
                condicion: null,
                keyCondiciones: null,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id_evento'
		          },
		          {text: 'Evento', value: 'evento.nombre',align: 'center' },
                  {text: 'Condicion', value: 'condicion.descripcion',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id_evento',
            'Evento': 'evento.nombre',
            'Condicion': 'condicion.descripcion',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('condicionevento/listCondicionesEventos', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	condicionesEventos(){
        		return this.$store.getters['condicionevento/listCondicionesEventos'];
        	},
        	condicionEvento(){
        		return this.$store.getters['condicionevento/condicionEvento'];
        	},
		    pagination(){
        		return this.$store.getters['condicionevento/pagination'];
		      },
		     pages () {
		        return this.$store.getters['condicionevento/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['condicionevento/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['condicionevento/loading'];
		      },
               eventos(){
                return this.$store.getters['evento/listEventos'];
              },
               condiciones(){
                return this.$store.getters['condicion/listCondiciones'];
              },
        },
        methods: {
        	deleteCondicionEvento(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar el registro?",
			        text: "El registro se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarlo!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('condicionevento/deleteCondicionEvento',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	saveCondicionEvento(){
        		if(((this.evento != '' || this.evento != null) ||
                     (this.eventoActual != '' || this.eventoActual != null)) && 
                    ((this.condicion != '' || this.condicion != null) ||
                     (this.condicionActual != '' || this.condicionActual != null))){ 
        		if(this.condicionEvento){
                    var condicionUpdate = null;
                    if(this.condicion != null && this.condicion != ''){
                        condicionUpdate = this.condicion;
                    }else{
                        condicionUpdate = this.condicionEvento.id_condiciones;
                    }
        			this.$store.dispatch('condicionevento/updateCondicionEvento',{id: this.condicionEvento.id_evento,id_condicion_old: this.condicionEvento.id_condiciones, id_condicion_new: condicionUpdate})
        			//console.log('estamos editando un palco');
        			$('#modalCondicionEvento').modal('hide');
        		}else{	
        			this.$store.dispatch('condicionevento/newCondicionEvento',{id_evento: this.evento, id_condicion: this.condicion});
        			//console.log('estamos guardando un palco');
        			$('#modalCondicionEvento').modal('hide');
        		}
        	}
        		
        	},
        	viewCondicionEvento(id_evento,id_condicion){
        	    this.$store.commit('condicionevento/viewCondicionEvento',{id_evento,id_condicion});
        	    this.eventoActual = this.condicionEvento.evento.nombre;
                this.condicionActual = this.condicionEvento.condicion.descripcion;
                this.keyEventos = null;
                this.keyCondiciones = null;
                this.evento = null;
                this.condicion = null;
        	    this.titleModalCondicionEvento = `Editar Registro: ${this.condicionEvento.id_evento}`;
        	},
        	setNullCondicionEvento(){
        		this.$store.commit('condicionevento/viewCondicionEvento',{id_evento:null,id_condicion:null});
        		this.eventoActual = null;
                this.condicionActual = null;
                this.keyEventos = null;
                this.keyCondiciones = null;
                this.evento = null;
                this.condicion = null;
        		this.titleModalCondicionEvento = 'Nuevo Registro';
        	},
	         changePage(page){
    		  this.$store.commit('condicionevento/changePage',page)
	          this.$store.dispatch('condicionevento/listCondicionesEventos',page);
	        },
            searchEventos(){
                this.$store.dispatch('evento/searchEventos', this.keyEventos);
            },
            searchCondiciones(){
                this.$store.dispatch('condicion/searchCondiciones', this.keyCondiciones);
            },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>