<template>
	<div>
		 <v-app id="inspire" v-if="clientes != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-hands-helping"></i><strong> Listado de</strong> Clientes</h2>
                            <div class="text-right">
                            <button @click="setNullCliente" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalCliente">NUEVO CLIENTE</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="clientes" :fields="json_fields" worksheet="Listado de clientes" name="clientes.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='clientes'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado clientes...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td class="text-xs-center">{{ props.item.Identificacion }}</td>
      <td class="text-xs-center">{{ props.item.tipo_identificacion }}</td>
      <td class="text-xs-center">{{ props.item.nombrerazon }}</td>
      <td class="text-xs-center">{{ props.item.direccion }}</td>
      <td class="text-xs-center">{{ props.item.ciudad }}</td>
      <td class="text-xs-center">{{ props.item.departamento }}</td>
      <td class="text-xs-center">{{ props.item.tipo_cliente }}</td>
      <td class="text-xs-center">{{ props.item.email }}</td>
      <td class="text-xs-center">{{ props.item.telefono }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewCliente(props.item.id)"
           data-toggle="modal" data-target="#modalCliente">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteCliente(props.item.id)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade bd-example-modal-lg" id="modalCliente" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="saveCliente">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalCliente }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                    <div class="col-md-6 col-lg-6">
                         <label for="identificacionCliente">Identificacion</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="identificacionCliente" class="form-control"
                                    placeholder="Identificacion del cliente" required="" v-model="identificacionCliente">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                        <label for="tipoIdentificacion">Tipo identificacion</label>
                        <div class="form-group">
                        <br>
                        <div class="switch">
                                    <label>Inactivo
                                        <input id="tipoIdentificacion" type="checkbox" v-model="tipoIdentificacion">
                                        <span class="lever"></span>Activo</label>
                                </div>
                         </div>
                     </div>
                     <div class="col-md-6 col-lg-6">
                        <label for="nameCliente">Nombre o razon</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="nameCliente" class="form-control"
                                    placeholder="Nombre o razon del cliente" required="" v-model="nameCliente">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                          <label for="direccionCliente">Direccion</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="direccionCliente" class="form-control"
                                    placeholder="Direccion del cliente" required="" v-model="direccionCliente">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                         <label for="ciudadCliente">Ciudad</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="ciudadCliente" class="form-control"
                                    placeholder="Ciudad del cliente" required="" v-model="ciudadCliente">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                         <label for="departamentoCliente">Departamento</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="departamentoCliente" class="form-control"
                                    placeholder="Departamento del cliente" required="" v-model="departamentoCliente">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                       <label for="tipoCliente">Tipo cliente</label>
                        <div class="form-group">
                        <br>
                        <div class="switch">
                                    <label>Inactivo
                                        <input id="tipoCliente" type="checkbox" v-model="tipoCliente">
                                        <span class="lever"></span>Activo</label>
                                </div>
                         </div>
                     </div>
                     <div class="col-md-6 col-lg-6">
                         <label for="emailCliente">Email</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="email" id="emailCliente" class="form-control"
                                    placeholder="Email del cliente" required="" v-model="emailCliente">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6">
                          <label for="telefonoCliente">Telefono</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="tel" id="telefonoCliente" class="form-control"
                                    placeholder="Telefono del cliente" required="" v-model="telefonoCliente">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-info waves-effect">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('cliente/listClientes', 1);
        },
		created(){
           document.title = 'Clientes - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('cliente/listClientes', 1);
        },
        data(){
        	return {
        		titleModalCliente: null,
        		nameCliente: null,
                identificacionCliente: null,
                tipoIdentificacion: false,
                direccionCliente: null,
                emailCliente: null,
                telefonoCliente: null,
                departamentoCliente: null,
                ciudadCliente: null,
                tipoCliente: false,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id'
		          },
		          {text: 'Identificacion', value: 'Identificacion',align: 'center' },
                  {text: 'Tipo identificacion', value: 'tipo_identificacion',align: 'center' },
                  {text: 'Nombre o razon', value: 'nombrerazon',align: 'center' },
                  {text: 'Ciudad', value: 'ciudad',align: 'center' },
                  {text: 'Departamento', value: 'departamento',align: 'center' },
                  {text: 'Direccion', value: 'direccion',align: 'center' },
                  {text: 'Tipo cliente', value: 'tipo_cliente',align: 'center' },
                  {text: 'Email', value: 'email',align: 'center' },
                  {text: 'Telefono', value: 'telefono',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id',
            'Identificacion': 'Identificacion',
            'Tipo identificacion': 'tipo_identificacion',
            'Nombre o razon': 'nombrerazon',
            'Ciudad': 'ciudad',
            'Departamento': 'departamento',
            'Direccion': 'direccion',
            'Tipo cliente': 'tipo_cliente',
            'Email': 'email',
            'Telefono': 'telefono',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('cliente/listClientes', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	clientes(){
        		return this.$store.getters['cliente/listClientes'];
        	},
        	cliente(){
        		return this.$store.getters['cliente/cliente'];
        	},
		    pagination(){
        		return this.$store.getters['cliente/pagination'];
		      },
		     pages () {
		        return this.$store.getters['cliente/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['cliente/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['cliente/loading'];
		      },
        },
        methods: {
        	deleteCliente(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar el cliente?",
			        text: "El cliente se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarlo!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('cliente/deleteCliente',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	saveCliente(){
        		if(this.nameCliente != '' || this.nameCliente != null &&
                    this.identificacionCliente != '' || this.identificacionCliente != null &&
                    this.tipoIdentificacion != '' || this.tipoIdentificacion != null &&
                    this.ciudadCliente != '' || this.ciudadCliente != null &&
                    this.departamentoCliente != '' || this.departamentoCliente != null &&
                    this.tipoCliente != '' || this.tipoCliente != null &&
                    this.emailCliente != '' || this.emailCliente != null &&
                    this.telefonoCliente != '' || this.telefonoCliente != null &&
                    this.direccionCliente != '' || this.direccionCliente != null){ 
        		if(this.cliente){
        			this.$store.dispatch('cliente/updateCliente',{id: this.cliente.id,nombrerazon: this.nameCliente, identificacion: this.identificacionCliente, tipo_identificacion: this.tipoIdentificacion, ciudad: this.ciudadCliente, departamento: this.departamentoCliente, tipo_cliente: this.tipoCliente, direccion: this.direccionCliente, email: this.emailCliente, telefono: this.telefonoCliente})
        			//console.log('estamos editando un cliente');
        			$('#modalCliente').modal('hide');
        		}else{	
        			this.$store.dispatch('cliente/newCliente',{nombrerazon: this.nameCliente, identificacion: this.identificacionCliente, tipo_identificacion: this.tipoIdentificacion, ciudad: this.ciudadCliente, departamento: this.departamentoCliente, tipo_cliente: this.tipoCliente, direccion: this.direccionCliente, email: this.emailCliente, telefono: this.telefonoCliente});
        			//console.log('estamos guardando un cliente');
        			$('#modalCliente').modal('hide');
        		}
        	}
        		
        	},
        	viewCliente(id){
        	    this.$store.commit('cliente/viewCliente',id);
        	    this.nameCliente = this.cliente.nombrerazon;
                this.identificacionCliente = this.cliente.Identificacion;
                this.tipoIdentificacion = this.cliente.tipo_identificacion;
                this.ciudadCliente = this.cliente.ciudad;
                this.departamentoCliente = this.cliente.departamento;
                this.tipoCliente = this.cliente.tipo_cliente;
                this.direccionCliente = this.cliente.direccion;
                this.emailCliente = this.cliente.email;
                this.telefonoCliente = this.cliente.telefono;
        	    this.titleModalCliente = `Editar Cliente: ${this.cliente.nombrerazon}`;
        	},
        	setNullCliente(){
        		this.$store.commit('cliente/viewCliente',null);
        		this.nameCliente = null;
                this.identificacionCliente = null;
                this.tipoIdentificacion = false;
                this.ciudadCliente = null;
                this.departamentoCliente = null;
                this.tipoCliente = false;
                this.emailCliente = null;
                this.direccionCliente = null;
                this.telefonoCliente = null;
        		this.titleModalCliente = 'Nuevo Cliente';
        	},
	         changePage(page){
    		  this.$store.commit('cliente/changePage',page)
	          this.$store.dispatch('cliente/listClientes',page);
	        },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>