import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       cuponeras: [],
       cuponera: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listCuponeras: (state,getters,rootState) => {
          return state.cuponeras;
    },
    cuponera: (state,getters,rootState) => {
        return state.cuponera;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listCuponeras: (state,cuponeras) => {
          state.cuponeras = cuponeras.data;
          state.pagination.totalItems = cuponeras.total;
          state.pagination.page = cuponeras.current_page;
          state.pagination.rowsPerPage = cuponeras.per_page;
       },
      listCuponerasSearch: (state,cuponeras) => {
            if(cuponeras != null){ 
                state.cuponeras = cuponeras;
            }else{
              state.cuponeras = null;
            }
       },
      viewCuponera(state,id){
        let auxCuponera = state.cuponeras.filter((item) => item.id == id);
        state.cuponera = auxCuponera[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listCuponeras({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}cuponera?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listCuponeras',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener las cuponeras!", "error");
        console.error(error);
      });

    },
  newCuponera({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}cuponera`,
       {
          nombre: payload.nameCuponera,
          fecha_inicio: payload.fechaInicio,
          fecha_fin: payload.fechaFin,
          status: payload.status
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo la cuponera correctamente!", "success");
        store.dispatch('cuponera/listCuponeras',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear la cuponera!", "error");
        console.error(error);
      });
  },
  updateCuponera({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}cuponera/${payload.id}`,
        {
          nombre: payload.nameCuponera,
          fecha_inicio: payload.fechaInicio,
          fecha_fin: payload.fechaFin,
          status: payload.status
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo la cuponera correctamente!", "success");
        store.dispatch('cuponera/listCuponeras',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar la cuponera!", "error");
        console.error(error);
      });
  },
  deleteCuponera({state,getters,commit,rootState},cuponera){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}cuponera/${cuponera}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino la cuponera correctamente!", "success");
        store.dispatch('cuponera/listCuponeras',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar la cuponera!","error");
        console.log(error);
     });
  },
  searchCuponeras({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarCuponera?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listCuponerasSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener las cuponeras!", "error");
        console.error(error);
      });

    },
  },
}