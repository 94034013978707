<template>
    <div>
         <v-app id="inspire" v-if="boletasPreventas != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-hand-holding-usd"></i><strong> Listado de</strong> Boletas por Preventa</h2>
                            <div class="text-right">
                            <button @click="setNullBoletaPreventa" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalBoletaPreventa">NUEVO REGISTRO</button>
                        </div>
                        <div>
                             <export-excel style="width: fit-content;" :data="boletasPreventas" :fields="json_fields" worksheet="Listado de boletas por preventa" name="boletasPreventas.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
            must-sort
            :items='boletasPreventas'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado boletas por preventa...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id_boleta }}</td>
      <td class="text-xs-center">{{ props.item.preventum.nombre }}</td>
      <td class="text-xs-center">{{ props.item.precio_venta }}</td>
      <td class="text-xs-center">{{ props.item.precio_servicio }}</td>
      <td class="text-xs-center">{{ props.item.impuesto }}</td>
      <td class="text-xs-center">{{ props.item.moneda.codigo_moneda }} - {{ props.item.moneda.descripcion }}</td>
      <td class="text-xs-center">{{ props.item.status }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewBoletaPreventa(props.item.id_boleta)"
           data-toggle="modal" data-target="#modalBoletaPreventa">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteBoletaPreventa(props.item.id_boleta)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalBoletaPreventa" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalBoletaPreventa }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                      <div class="col-md-6 col-lg-6">
                        <label for="precioVentaBoletaPreventa">Precio venta</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" step="any" id="precioVentaBoletaPreventa" class="form-control"
                                    placeholder="Precio venta" required="" v-model="precioVentaBoletaPreventa">
                            </div>
                        </div>
                      </div>
                       <div class="col-md-6 col-lg-6">
                          <label for="precioServicioBoletaPreventa">Precio servicio</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" step="any" id="precioServicioBoletaPreventa" class="form-control"
                                    placeholder="Precio servicio" required="" v-model="precioServicioBoletaPreventa">
                            </div>
                        </div>
                       </div>
                       <div class="col-md-6 col-lg-6">
                          <label for="impuestoBoletaPreventa">Impuesto</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" step="any" id="impuestoBoletaPreventa" class="form-control"
                                    placeholder="Impuesto" required="" v-model="impuestoBoletaPreventa">
                            </div>
                        </div>
                       </div>
                         <div class="col-md-6 col-lg-6">
                          <label for="statusBoletaPreventa">Estado</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="statusBoletaPreventa" class="form-control"
                                    placeholder="Estado" required="" v-model="statusBoletaPreventa">
                            </div>
                        </div>
                       </div>
                       <div class="col-lg-6 col-md-6" v-if="boletapreventa == null">
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchEventos" class="form-control"
                                    placeholder="Buscar eventos..." v-model="keyEventos" @keyup.enter="searchEventos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(eventos && eventos.length > 0)">
                        <v-select
                          :items="eventos"
                           item-text="nombre"
                           item-value="id"
                           v-model="evento"
                          label="Seleccione un evento"
                        ></v-select>
                    </div>
                       </div>
                       <div class="col-md-6 col-lg-6" v-if="boletapreventa == null">
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="searchPuestos" class="form-control"
                                    placeholder="Buscar puestos..." v-model="keyPuestos" @keyup.enter="searchPuestos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(puestos && puestos.length > 0)">
                        <v-select
                          :items="puestos"
                           item-text="numero"
                           item-value="id"
                           v-model="puesto"
                          label="Seleccione un puesto"
                        ></v-select>
                    </div>
                       </div>
                       <div class="col-md-6 col-lg-6">
                            <label for="monedaBoletaPreventa" v-if="monedaBoletaPreventa != null">Moneda</label>
                          <div class="form-group" v-if="monedaBoletaPreventa != null">
                            <div class="form-line">
                                <input type="text" id="monedaBoletaPreventa" class="form-control"
                                    placeholder="Moneda" :value="monedaBoletaPreventa">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchMonedas" class="form-control"
                                    placeholder="Buscar monedas..." v-model="keyMonedas" @keyup.enter="searchMonedas">
                            </div>
                        </div>
                        <div class="form-group" v-if="(monedas && monedas.length > 0)">
                        <v-select
                          :items="monedas"
                           item-text="descripcion"
                           item-value="codigo_moneda"
                           v-model="moneda"
                          label="Seleccione una moneda"
                        ></v-select>
                    </div>
                       </div>
                         <div class="col-md-6 col-lg-6">
                            <label for="preventaActual" v-if="preventaActual != null">Preventa</label>
                          <div class="form-group" v-if="preventaActual != null">
                            <div class="form-line">
                                <input type="text" id="preventaActual" class="form-control"
                                    placeholder="Preventa" :value="preventaActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchPreventas" class="form-control"
                                    placeholder="Buscar preventas..." v-model="keyPreventas" @keyup.enter="searchPreventas">
                            </div>
                        </div>
                        <div class="form-group" v-if="(preventas && preventas.length > 0)">
                        <v-select
                          :items="preventas"
                           item-text="nombre"
                           item-value="id"
                           v-model="preventa"
                          label="Seleccione una preventa"
                        ></v-select>
                    </div>
                       </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info waves-effect" @click="saveBoletaPreventa">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
    </div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

    export default {
        beforeCreate(){
           this.$store.dispatch('boletapreventa/listBoletasPreventas', 1);
        },
        created(){
           document.title = 'Boletas por preventa - TicketShop';
        },
        async mounted(){
           //adminApp() 
           this.$store.dispatch('boletapreventa/listBoletasPreventas', 1);
        },
        data(){
            return {
                titleModalBoletaPreventa: null,
                precioVentaBoletaPreventa: null,
                precioServicioBoletaPreventa: null,
                statusBoletaPreventa: null,
                impuestoBoletaPreventa: null,
                evento: null,
                keyEventos: null,
                monedaBoletaPreventa: null,
                moneda: null,
                keyMonedas: null,
                puesto: null,
                keyPuestos: null,
                preventaActual: null,
                preventa: null,
                keyPreventas: null,
                search: '',
                headers: [
                  {
                    text: 'Id',
                    align: 'left',
                    sortable: false,
                    value: 'id_boleta'
                  },
                  {text: 'Preventa', value: 'preventum.nombre',align: 'center' },
                  {text: 'Precio venta', value: 'precio_venta',align: 'center' },
                  {text: 'Precio servicio', value: 'precio_servicio',align: 'center' },
                  {text: 'Impuesto', value: 'impuesto',align: 'center' },
                  {text: 'Moneda', value: 'moneda.codigo_moneda',align: 'center' },
                  {text: 'Estado', value: 'status',align: 'center' },
                  {text: 'Acciones',value: '', align: 'right'}
             ],
             json_fields: {
            'Id': 'id_boleta',
            'Preventa': 'preventum.nombre',
            'Precio venta': 'precio_venta',
            'Precio servicio': 'precio_servicio',
            'Impuesto': 'impuesto',
            'Moneda': 'moneda.codigo_moneda',
            'Estado': 'status',

        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
            }
        },
        watch: {
            pagination: {
              async handler () {
                this.$store.dispatch('boletapreventa/listBoletasPreventas', this.pagination.page);
              },
              deep: true
            }
          },
        computed: {
            boletasPreventas(){
                return this.$store.getters['boletapreventa/listBoletasPreventas'];
            },
            boletapreventa(){
                return this.$store.getters['boletapreventa/boletapreventa'];
            },
            pagination(){
                return this.$store.getters['boletapreventa/pagination'];
              },
             pages () {
                return this.$store.getters['boletapreventa/pages'];
              },
              totalItemCount(){
                return this.$store.getters['boletapreventa/totalItemCount'];
              },
              loading(){
                    return this.$store.getters['boletapreventa/loading'];
              },
               eventos(){
                return this.$store.getters['evento/listEventos'];
              },
                puestos(){
                return this.$store.getters['puesto/listPuestos'];
              },
                monedas(){
                return this.$store.getters['moneda/listMonedas'];
              },
               preventas(){
                return this.$store.getters['preventa/listPreventas'];
              },
        },
        methods: {
            deleteBoletaPreventa(id){
                var self = this;
                    swal({
                    title: "¿Estas seguro de eliminar el registro?",
                    text: "El registro se eliminara permanentemente!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Si, Eliminarlo!",
                    cancelButtonText: "No, cancelar!",
                    closeOnConfirm: false,
                    closeOnCancel: false
                }, function (isConfirm) {
                    if (isConfirm) {
                        self.$store.dispatch('boletapreventa/deleteBoletaPreventa',id);
                    } else {
                        swal("Cancelado", "Se ha cancelado la operacion.", "error");
                    }
                });
            },
            saveBoletaPreventa(){
                if((this.precioVentaBoletaPreventa != null) && 
                  (this.precioServicioBoletaPreventa != null) &&
                  (this.impuestoBoletaPreventa != null) &&
                  (this.statusBoletaPreventa != null) &&
                  (this.boletapreventa != null || this.evento != null) &&
                  (this.boletapreventa != null || this.puesto != null) &&
                  (this.monedaBoletaPreventa != null || this.moneda != null) &&
                  (this.preventaActual != null || this.preventa != null)){ 
                if(this.boletapreventa){
                      var monedaUpdate = null;
                    if(this.moneda != null && this.moneda != ''){
                        monedaUpdate = this.moneda;
                    }else{
                        monedaUpdate = this.boletapreventa.moneda.codigo_moneda;
                    }
                    var preventaUpdate = null;
                    if(this.preventa != null && this.preventa != ''){
                        preventaUpdate = this.preventa;
                    }else{
                        preventaUpdate = this.boletapreventa.id_preventa;
                    }
                    this.$store.dispatch('boletapreventa/updateBoletaPreventa',
                       {
                          id: this.boletapreventa.id_boleta,
                          id_preventa: preventaUpdate,
                          precio_venta: this.precioVentaBoletaPreventa,
                          precio_servicio: this.precioServicioBoletaPreventa,
                          impuesto: this.impuestoBoletaPreventa,
                          status: this.statusBoletaPreventa,
                          codigo_moneda: monedaUpdate
                       });
                    //console.log('estamos editando un boletapreventa');
                    $('#modalBoletaPreventa').modal('hide');
                }else{  
                    this.$store.dispatch('boletapreventa/newBoletaPreventa',
                       {
                          id_evento: this.evento,
                          id_puesto: this.puesto,
                          id_preventa: this.preventa,
                          precio_venta: this.precioVentaBoletaPreventa,
                          precio_servicio: this.precioServicioBoletaPreventa,
                          impuesto: this.impuestoBoletaPreventa,
                          status: this.statusBoletaPreventa,
                          codigo_moneda: this.moneda
                       });
                    //console.log('estamos guardando un boletapreventa');
                    $('#modalBoletaPreventa').modal('hide');
                }
            }
                
            },
            viewBoletaPreventa(id){
                this.$store.commit('boletapreventa/viewBoletaPreventa',id);
                this.precioVentaBoletaPreventa = this.boletapreventa.precio_venta;
                this.precioServicioBoletaPreventa = this.boletapreventa.precio_servicio;
                this.statusBoletaPreventa = this.boletapreventa.status;
                this.impuestoBoletaPreventa = this.boletapreventa.impuesto;
                this.evento = null;
                this.keyEventos = null;
                this.preventaActual = this.boletapreventa.preventum.nombre;
                this.preventa = null;
                this.keyPreventas = null;
                if(this.boletapreventa.moneda != null){ 
                    this.monedaBoletaPreventa = `${this.boletapreventa.moneda.codigo_moneda} ${this.boletapreventa.moneda.descripcion}`;
                }else{
                    this.monedaBoletaPreventa = null;
                }
                this.moneda = null;
                this.keyMonedas = null;
                this.puesto = null;
                this.keyPuestos = null;
                this.titleModalBoletaPreventa = `Editar Registro`;
            },
            setNullBoletaPreventa(){
                this.$store.commit('boletapreventa/viewBoletaPreventa',null);
                this.precioVentaBoletaPreventa = null;
                this.precioServicioBoletaPreventa = null;
                this.statusBoletaPreventa = null;
                this.impuestoBoletaPreventa = null;
                this.evento = null;
                this.keyEventos = null;
                this.preventaActual = null;
                this.preventa = null;
                this.keyPreventas = null;
                this.monedaBoletaPreventa = null;
                this.moneda = null;
                this.keyMonedas = null;
                this.puesto = null;
                this.keyPuestos = null;
                this.titleModalBoletaPreventa = 'Nuevo Registro';
            },
             changePage(page){
              this.$store.commit('boletapreventa/changePage',page)
              this.$store.dispatch('boletapreventa/listBoletasPreventas',page);
            },
              searchEventos(){
                this.$store.dispatch('evento/searchEventos', this.keyEventos);
            },
             searchPuestos(){
                this.$store.dispatch('puesto/searchPuestos', this.keyPuestos);
            },
             searchMonedas(){
                this.$store.dispatch('moneda/searchMonedas', this.keyMonedas);
            },
             searchPreventas(){
                this.$store.dispatch('preventa/searchPreventas', this.keyPreventas);
            },
        }
    }
</script>
<style scoped>
.changes{
    margin-right: 5px;
    margin-bottom: 12px;
}
</style>