import axios from 'axios';
import {configApi} from '../../../api/configApi';
import {router} from '../../../main.js';
import {headers} from '../../../api/headers';
import {store} from '../../index';

export default {
  namespaced: true,
  state: {
    eventosInforme: null,
    tableEventoInforme: null,
    reportes:null,
    json_fields: null,
    headers: null,
    valor_total: "0",
    cantidad_total: "0",
  },
  actions: {
    async getEventos({state,getters,commit,rootState}){
      store.commit('changeLoader', true);
      await axios.get(`${configApi.apiUrl}app/v1/get_eventos_usuario`,null,{headers: headers})
      .then(response => {
        state.eventosInforme = response.data.data
      })
      .catch(error => {
        swal("Error!", "Error al obtener los eventos!", "error");
      })
      .finally(()=> {
        store.commit('changeLoader', false);
      })

    },
    async getInformesEvento({state,getters,commit,rootState}, payload){
      store.commit('changeLoader', true);
      await axios.post(`${configApi.apiUrl}app/v1/get_informe_Detalle_evento`,payload,null)
      .then(response => { 
        store.state.datosreporte = response.data
      })
      .catch(error => {
        swal("Error!", "Error al obtener los eventos!", "error");
      })
      .finally(()=>{
        store.commit('changeLoader', false);
      })
    },
    async getInformesMarcacionesEvento({state,getters,commit,rootState}, payload){
      store.commit('changeLoader', true);
      await axios.post(`${configApi.apiUrl}app/v1/get_informe_marcaciones`,payload,null)
      .then(response => {
       // console.log(response)
      })
      .catch(error => {
        swal("Error!", "Error al obtener los eventos!", "error");
      })
      .finally(()=>{
        store.commit('changeLoader', false);
      })
    },
    async getInformesAforoEvento({state,getters,commit,rootState}, payload){
      store.commit('changeLoader', true);
      await axios.post(`${configApi.apiUrl}app/v1/get_informe_marcaciones`,payload,null)
      .then(response => {
         state.tableEventoInforme = response.data 
        state.tboletas = response.data.tboletas 
        state.taforo = response.data.taforo  
        state.tporcentaje = response.data.tporcentaje 
        state.aforohabilitado = response.data.aforohabilitado 
         })
      .catch(error => {
        swal("Error!", "Error al obtener los eventos! ", "error");
      })
      .finally(()=>{
        store.commit('changeLoader', false);
      })
    },
    async getInformesVentasEvento({state,getters,commit,rootState}, payload){
      store.commit('changeLoader', true);
      await axios.post(`${configApi.apiUrl}app/v1/get_informe_detalle_ventas`,payload,null)
      .then(response => {
        state.tableEventoInforme = response.data 
        state.tboletas = response.data.tboletas 
        state.taforo = response.data.taforo  
        state.tporcentaje = response.data.tporcentaje 
        state.aforohabilitado = response.data.aforohabilitado 
         })
      .catch(error => {
        swal("Error!", "Error al obtener los eventos! ", "error");
      })
      .finally(()=>{
        store.commit('changeLoader', false);
      })
    },
  }
}