import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       boletasPreventas: [],
       boletapreventa: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listBoletasPreventas: (state,getters,rootState) => {
          return state.boletasPreventas;
    },
    boletapreventa: (state,getters,rootState) => {
        return state.boletapreventa;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listBoletasPreventas: (state,boletasPreventas) => {
          state.boletasPreventas = boletasPreventas.data;
          state.pagination.totalItems = boletasPreventas.total;
          state.pagination.page = boletasPreventas.current_page;
          state.pagination.rowsPerPage = boletasPreventas.per_page;
       },
      viewBoletaPreventa(state,id){
        let auxBoletaPreventa = state.boletasPreventas.filter((item) => item.id_boleta == id);
        state.boletapreventa = auxBoletaPreventa[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listBoletasPreventas({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}boletasprevent?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listBoletasPreventas',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener las boletas por preventa!", "error");
        console.error(error);
      });

    },
  newBoletaPreventa({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}boletasprevent`,
        {
            id_evento: payload.id_evento,
            id_puesto: payload.id_puesto,
            id_preventa: payload.id_preventa,
            precio_venta: payload.precio_venta,
            precio_servicio: payload.precio_servicio,
            impuesto: payload.impuesto,
            status: payload.status,
            codigo_moneda: payload.codigo_moneda
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo la boleta por preventa correctamente!", "success");
        store.dispatch('boletapreventa/listBoletasPreventas',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear la boleta por preventa!", "error");
        console.error(error);
      });
  },
  updateBoletaPreventa({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}boletasprevent/${payload.id}`,
       {
            id_preventa: payload.id_preventa,
            precio_venta: payload.precio_venta,
            precio_servicio: payload.precio_servicio,
            impuesto: payload.impuesto,
            status: payload.status,
            codigo_moneda: payload.codigo_moneda
        },
        {headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo la boleta por preventa correctamente!", "success");
        store.dispatch('boletapreventa/listBoletasPreventas',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar la boleta por preventa!", "error");
        console.error(error);
      });
  },
  deleteBoletaPreventa({state,getters,commit,rootState},boletapreventa){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}boletasprevent/${boletapreventa}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino la boleta por preventa correctamente!", "success");
        store.dispatch('boletapreventa/listBoletasPreventas',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar la boleta por preventa!","error");
        console.log(error);
     });
  },
  },
}