<template>
  <div>
  	<div v-if="$store.state.loader" id="loader">
  		<div class="loader">
  	<GridLoader :color="color" :size="size" :margin="margin" :radius="radius" width="2px" height="10px"></GridLoader>
  </div>
  </div>
  	<transition appear>
    <router-view></router-view>
</transition>
  </div>
</template>

<script>
import GridLoader from 'vue-spinner/src/GridLoader.vue'

export default { 
	mounted(){
		var self = this;
		$(window).ready(function(){
		    self.$store.commit('changeLoader', false);
		});
	},
	components: {
		GridLoader,
	},
	data(){
		return{
			  color: '#ffcb30',
		      size: '30px',
		      margin: '5px',
		      radius: '100%',
		}
	},

}
</script>

<style>
/*.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}*/
div#loader {
   position: fixed;
    height: 100%;
    width: 100%;
    z-index: 9999999999999;
    text-align: center;
    background-color: rgba(236, 236, 236, 0.97);
    top: 0;
}
#loader .loader{
      display: -webkit-inline-box;
    padding-top: 20%;
}
.v-table__overflow {
    padding: 0px 30px;
    max-height: 300px !important;
    overflow: scroll !important;
}
div#inspire {
    background-color: #f1f2f7;
}
.application--wrap {
    min-height: 100% !important;
}
.container-table-informes{
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 0 5px 1px rgb(219, 219, 219);
    margin-top: 2rem;
  }
</style>