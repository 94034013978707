import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       generos: [],
       genero: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listGeneros: (state,getters,rootState) => {
          return state.generos;
    },
    genero: (state,getters,rootState) => {
        return state.genero;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
    showGenero(state,getters,rootState){
      return state.genero;
    }
  },
  mutations: {
        listGeneros: (state,generos) => {
          state.generos = generos.data;
          state.pagination.totalItems = generos.total;
          state.pagination.page = generos.current_page;
          state.pagination.rowsPerPage = generos.per_page;
       },
       listGenerosSearch: (state,generos) => {
            if(generos != null){ 
                state.generos = generos;
            }else{
              state.generos = null;
            }
       },
      viewGenero(state,id){
        let auxGenero = state.generos.filter((item) => item.id == id);
        state.genero = auxGenero[0];
      },
      showGenero(state,genero){
            if(genero != null){ 
            state.genero = genero;
            }else{
              state.genero = null;
            }
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listGeneros({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}genero?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listGeneros',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los generos!", "error");
        console.error(error);
      });

    },
  newGenero({state,getters,commit,rootState},nameGenero){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}genero`,{nombre: nameGenero},{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo el genero correctamente!", "success");
        store.dispatch('genero/listGeneros',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear el genero!", "error");
        console.error(error);
      });
  },
  updateGenero({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}genero/${payload.genero}`,{nombre: payload.nameGenero},{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo el genero correctamente!", "success");
        store.dispatch('genero/listGeneros',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar el genero!", "error");
        console.error(error);
      });
  },
  deleteGenero({state,getters,commit,rootState},genero){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}genero/${genero}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino el genero correctamente!", "success");
        store.dispatch('genero/listGeneros',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar el genero!","error");
        console.log(error);
     });
  },
      searchGeneros({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarGenero?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listGenerosSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener los generos!", "error");
        console.error(error);
      });

    },
    showGenero({state,getters,commit,rootState},genero){
       store.dispatch('validateToken');
       axios.get(`${configApi.apiUrl}genero/${genero}`,null,{headers: headers})
          .then(response => {
            //console.log(response.data.data);
            commit('showGenero',response.data.data);
          })
          .catch(error => {
            swal("Error!", "Error al obtener el genero del artista!", "error");
            console.error(error);
          });
    },
  },
}