import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       puestosPalcos: [],
       puestoPalco: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listPuestosPalcos: (state,getters,rootState) => {
          return state.puestosPalcos;
    },
    puestoPalco: (state,getters,rootState) => {
        return state.puestoPalco;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listPuestosPalcos: (state,puestosPalcos) => {
          state.puestosPalcos = puestosPalcos.data;
          state.pagination.totalItems = puestosPalcos.total;
          state.pagination.page = puestosPalcos.current_page;
          state.pagination.rowsPerPage = puestosPalcos.per_page;
       },
    listPuestosPalcosSearch: (state,puestosPalcos) => {
            if(puestosPalcos != null){ 
                state.puestosPalcos = puestosPalcos;
            }else{
              state.puestosPalcos = null;
            }
       },
      viewPuestoPalco(state,payload){
        let auxPuestoPalco = state.puestosPalcos.filter((item) => 
          (item.id_palco == payload.id_palco && item.id_puesto == payload.id_puesto));
        state.puestoPalco = auxPuestoPalco[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listPuestosPalcos({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}puestospalco?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listPuestosPalcos',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los puestos por palco!", "error");
        console.error(error);
      });

    },
  newPuestoPalco({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}puestospalco`,
        {
          id_palco: payload.id_palco,
          id_puesto: payload.id_puesto,
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo el puesto por palco correctamente!", "success");
        store.dispatch('puestopalco/listPuestosPalcos',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear el puesto por palco!", "error");
        console.error(error);
      });
  },
  updatePuestoPalco({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}puestospalco/${payload.id}`,
        {
          id_puesto_old: payload.id_puesto_old,
          id_puesto_new: payload.id_puesto_new,
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo el puesto por palco correctamente!", "success");
        store.dispatch('puestopalco/listPuestosPalcos',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar el puesto por palco!", "error");
        console.error(error);
      });
  },
  deletePuestoPalco({state,getters,commit,rootState},puestoPalco){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}puestospalco/${puestoPalco}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino el puesto por palco correctamente!", "success");
        store.dispatch('puestopalco/listPuestosPalcos',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar el puesto por palco!","error");
        console.log(error);
     });
  },
  searchPalcos({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarPalco?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listPuestosPalcosSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener los puestos por palco!", "error");
        console.error(error);
      });

    },
  },
}