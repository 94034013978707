<template>
	<div>
		 <v-app id="inspire" v-if="eventos != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-ticket-alt"></i><strong> Listado de</strong> Eventos</h2>
                            <div class="text-right">
                            <button @click="setNullEvento" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalEvento">NUEVO EVENTO</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="eventos" :fields="json_fields" worksheet="Listado de eventos" name="eventos.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='eventos'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado eventos...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td class="text-xs-center">{{ props.item.nombre }}</td>
      <td class="text-xs-center">{{ props.item.fecha_evento }}</td>
      <td class="text-xs-center">{{ props.item.hora_inicio }}</td>
      <td class="text-xs-center">{{ props.item.hora_apertura }}</td>
      <td class="text-xs-center">{{ props.item.hora_finalizacion }}</td>
      <td class="text-xs-center">{{ props.item.codigo_pulep }}</td>
      <td class="text-xs-center"><div v-if="props.item.tipoevento != null">{{ props.item.tipoevento.nombre }}</div></td>
      <td class="text-xs-center">{{ props.item.domicilios }}</td>
      <td class="text-xs-center">{{ props.item.venta_linea }}</td>
      <td class="text-xs-center">{{ props.item.auditorio.nombre }}</td>
      <td class="text-xs-center">{{ props.item.cliente.nombrerazon }}</td>
      <td class="text-xs-center"><div v-if="props.item.temporada != null">{{ props.item.temporada.nombre }}</div></td>
      <td class="text-xs-center">{{ props.item.status }}</td>
      <td class="text-xs-center">{{ props.item.fecha_inicio_venta_internet }}</td>
      <td class="text-xs-center">{{ props.item.fecha_inicio_venta_puntos }}</td>
      <td class="text-xs-center">{{ props.item.monto_minimo }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewEvento(props.item.id)"
           data-toggle="modal" data-target="#modalEvento">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteEvento(props.item.id)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade bd-example-modal-lg" id="modalEvento" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog modal-lg modalEvento" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalEvento }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                    <div class="col-lg-4 col-md-4 col-sm-6">
                        <label for="nameEvento">Nombre</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="nameEvento" class="form-control"
                                    placeholder="Nombre del evento" required="" v-model="nameEvento">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6">
                             <label for="fechaEvento">Fecha evento</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="fechaEvento" class="form-control datepicker"
                                    placeholder="AAAA-MM-DD" required="">
                            </div>
                        </div>
                    </div>
                       <div class="col-lg-4 col-md-4 col-sm-6">
                             <label for="horaInicio">Hora inicio</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="horaInicio" class="form-control timepicker"
                                    placeholder="HH:mm">
                            </div>
                        </div>
                    </div>
                         <div class="col-lg-4 col-md-4 col-sm-6">
                             <label for="horaApertura">Hora apertura</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="horaApertura" class="form-control timepicker"
                                    placeholder="HH:mm">
                            </div>
                        </div>
                    </div>
                         <div class="col-lg-4 col-md-4 col-sm-6">
                             <label for="horaFinalizacion">Hora finalizacion</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="horaFinalizacion" class="form-control timepicker"
                                    placeholder="HH:mm">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6">
                        <label for="codigoPulep">Codigo pulep</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="codigoPulep" class="form-control"
                                    placeholder="Codigo pulep del evento" v-model="codigoPulep">
                            </div>
                        </div>
                    </div>
                      <div class="col-lg-4 col-md-4 col-sm-6">
                        <label for="domiciliosEvento">Domicilios</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="domiciliosEvento" class="form-control"
                                    placeholder="Domicilios del evento" v-model="domiciliosEvento">
                            </div>
                        </div>
                    </div>
                       <div class="col-lg-4 col-md-4 col-sm-6">
                        <label for="ventaLineaEvento">Venta en linea</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="ventaLineaEvento" class="form-control"
                                    placeholder="Venta en linea del evento" v-model="ventaLineaEvento">
                            </div>
                        </div>
                    </div>
                     <div class="col-lg-4 col-md-4 col-sm-6">
                        <label for="statusEvento">Status</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="statusEvento" class="form-control"
                                    placeholder="Status del evento" v-model="statusEvento">
                            </div>
                        </div>
                    </div>
                       <div class="col-lg-4 col-md-4 col-sm-6">
                             <label for="fechaInicioVentaInternet">Fecha inicio venta internet</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="fechaInicioVentaInternet" class="form-control datepicker"
                                    placeholder="AAAA-MM-DD">
                            </div>
                        </div>
                    </div>
                     <div class="col-lg-4 col-md-4 col-sm-6">
                        <label for="fechaInicioVentaPuntos">Fecha inicio venta puntos</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="fechaInicioVentaPuntos" class="form-control"
                                    placeholder="Fecha inicio venta puntos" required="" v-model="fechaInicioVentaPuntos">
                            </div>
                        </div>
                    </div>
                      <div class="col-lg-4 col-md-4 col-sm-6">
                        <label for="montoMinimo">Monto minimo</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="montoMinimo" class="form-control"
                                    placeholder="Monto minimo" required="" v-model="montoMinimo" step="any">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6">
                        <label for="tipoEventoActual" v-if="tipoEventoActual != null">Tipo Evento</label>
                          <div class="form-group" v-if="tipoEventoActual != null">
                            <div class="form-line">
                                <input type="text" id="tipoEventoActual" class="form-control"
                                    placeholder="Tipo evento" :value="tipoEventoActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchTipoEventos" class="form-control"
                                    placeholder="Buscar tipos de evento..." v-model="keyTipoEvento" @keyup.enter="searchTipoEventos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(tipoEventos && tipoEventos.length > 0)">
                        <v-select
                          :items="tipoEventos"
                           item-text="nombre"
                           item-value="id"
                           v-model="tipoEvento"
                          label="Seleccione un tipo de evento"
                        ></v-select>
                    </div>
                    </div>
                      <div class="col-lg-4 col-md-4 col-sm-6">
                        <label for="auditorioActual" v-if="auditorioActual != null">Auditorio</label>
                          <div class="form-group" v-if="auditorioActual != null">
                            <div class="form-line">
                                <input type="text" id="auditorioActual" class="form-control"
                                    placeholder="Tipo evento" :value="auditorioActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchAuditorios" class="form-control"
                                    placeholder="Buscar auditorios..." v-model="keyAuditorios" @keyup.enter="searchAuditorios">
                            </div>
                        </div>
                        <div class="form-group" v-if="(auditorios && auditorios.length > 0)">
                        <v-select
                          :items="auditorios"
                           item-text="nombre"
                           item-value="id"
                           v-model="auditorio"
                          label="Seleccione un auditorio"
                        ></v-select>
                    </div>
                    </div>
                        <div class="col-lg-4 col-md-4 col-sm-6">
                        <label for="clienteActual" v-if="clienteActual != null">Cliente</label>
                          <div class="form-group" v-if="clienteActual != null">
                            <div class="form-line">
                                <input type="text" id="clienteActual" class="form-control"
                                    placeholder="Cliente del evento" :value="clienteActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchClientes" class="form-control"
                                    placeholder="Buscar clientes..." v-model="keyClientes" @keyup.enter="searchClientes">
                            </div>
                        </div>
                        <div class="form-group" v-if="(clientes && clientes.length > 0)">
                        <v-select
                          :items="clientes"
                           item-text="nombrerazon"
                           item-value="id"
                           v-model="cliente"
                          label="Seleccione un cliente"
                        ></v-select>
                    </div>
                    </div>
                       <div class="col-lg-4 col-md-4 col-sm-6">
                        <label for="temporadaActual" v-if="temporadaActual != null">Temporada</label>
                          <div class="form-group" v-if="temporadaActual != null">
                            <div class="form-line">
                                <input type="text" id="temporadaActual" class="form-control"
                                    placeholder="Temporada del evento" :value="temporadaActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchTemporadas" class="form-control"
                                    placeholder="Buscar temporadas..." v-model="keyTemporadas" @keyup.enter="searchTemporadas">
                            </div>
                        </div>
                        <div class="form-group" v-if="(temporadas && temporadas.length > 0)">
                        <v-select
                          :items="temporadas"
                           item-text="nombre"
                           item-value="id"
                           v-model="temporada"
                          label="Seleccione una temporada"
                        ></v-select>
                    </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                     type="button" class="btn btn-info waves-effect" @click="saveEvento">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('evento/listEventos', 1);
        },
		created(){
           document.title = 'Eventos - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('evento/listEventos', 1);
        },
        data(){
        	return {
        		titleModalEvento: null,
        		nameEvento: null,
                codigoPulep: null,
                domiciliosEvento: null,
                ventaLineaEvento: null,
                statusEvento: null,
                fechaInicioVentaPuntos:null,
                tipoEventoActual:null,
                keyTipoEvento:null,
                tipoEvento:null,
                auditorioActual: null,
                keyAuditorios: null,
                auditorio: null,
                clienteActual: null,
                keyClientes: null,
                cliente: null,
                temporadaActual: null,
                keyTemporadas: null,
                temporada: null,
                montoMinimo: null,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id'
		          },
		          {text: 'Nombre', value: 'nombre',align: 'center' },
                  {text: 'Fecha evento', value: 'fecha_inicio',align: 'center' },
                  {text: 'Hora inicio', value: 'hora_inicio',align: 'center' },
                  {text: 'Hora apertura', value: 'hora_apertura',align: 'center' },
                  {text: 'Hora finalizacion', value: 'hora_finalizacion',align: 'center' },
                  {text: 'Codigo pulep', value: 'codigo_pulep',align: 'center' },
                  {text: 'Tipo evento', value: 'id_tipo_evento',align: 'center' },
                  {text: 'Domicilios', value: 'domicilios',align: 'center' },
                  {text: 'Venta en linea', value: 'venta_linea',align: 'center' },
                  {text: 'Auditorio', value: 'auditorio.nombre',align: 'center' },
                  {text: 'Cliente', value: 'cliente.nombrerazon',align: 'center' },
                  {text: 'Temporada', value: 'id_temporada',align: 'center' },
                  {text: 'Status', value: 'status',align: 'center' },
                  {text: 'Fecha inicio venta internet', value: 'fecha_inicio_venta_internet',align: 'center' },
                  {text: 'Fecha inicio venta puntos', value: 'fecha_inicio_venta_puntos',align: 'center' },
                  {text: 'Monto minimo', value: 'monto_minimo',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id',
            'Nombre': 'nombre',
            'Fecha evento': 'fecha_inicio',
            'Hora inicio': 'hora_inicio',
            'Hora apertura': 'hora_apertura',
            'Hora finalizacion': 'hora_finalizacion',
            'Codigo pulep': 'codigo_pulep',
            'Id Tipo evento': 'id_tipo_evento',
            'Domicilios': 'domicilios',
            'Venta en linea': 'venta_linea',
            'Auditorio': 'auditorio.nombre',
            'Cliente': 'cliente.nombrerazon',
            'Id Temporada': 'id_temporada',
            'Status': 'status',
            'Fecha inicio venta internet': 'fecha_inicio_venta_internet',
            'Fecha inicio venta puntos': 'fecha_inicio_venta_puntos',
            'Monto minimo': 'monto_minimo',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('evento/listEventos', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	eventos(){
        		return this.$store.getters['evento/listEventos'];
        	},
        	evento(){
        		return this.$store.getters['evento/evento'];
        	},
		    pagination(){
        		return this.$store.getters['evento/pagination'];
		      },
		     pages () {
		        return this.$store.getters['evento/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['evento/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['evento/loading'];
		      },
               auditorios(){
                return this.$store.getters['auditorio/listAuditorios'];
              },
               clientes(){
                return this.$store.getters['cliente/listClientes'];
              },
               tipoEventos(){
                return this.$store.getters['tipoevento/listTipoEvento'];
              },
               temporadas(){
                return this.$store.getters['temporada/listTemporadas'];
              },
        },
        methods: {
        	deleteEvento(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar el evento?",
			        text: "El evento se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarlo!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('evento/deleteEvento',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	saveEvento(){
        		if((this.nameEvento != '' || this.nameEvento != null) &&
                    (this.fechaInicioVentaPuntos != '' || this.fechaInicioVentaPuntos != null) &&
                    ($("#fechaEvento").val() != '') &&
                    ((this.auditorio != null || this.auditorioActual != null) && 
                    ((this.auditorio != '' || this.auditorioActual != ''))) &&
                    ((this.cliente != null || this.clienteActual != null) && 
                    ((this.cliente != '' || this.clienteActual != '')))){ 
        		if(this.evento){

                    var auditorioUpdate = null;
                    if(this.auditorio != null && this.auditorio != ''){
                        auditorioUpdate = this.auditorio;
                    }else{
                        auditorioUpdate = this.evento.id_auditorio;
                    }
                      var clienteUpdate = null;
                    if(this.cliente != null && this.cliente != ''){
                        clienteUpdate = this.cliente;
                    }else{
                        clienteUpdate = this.evento.id_cliente;
                    }
                      var temporadaUpdate = null;
                    if(this.temporada != null && this.temporada != ''){
                        temporadaUpdate = this.temporada;
                    }else{
                        temporadaUpdate = this.evento.id_temporada;
                    }
                      var tipoEventoUpdate = null;
                    if(this.tipoEvento != null && this.tipoEvento != ''){
                        tipoEventoUpdate = this.tipoEvento;
                    }else{
                        tipoEventoUpdate = this.evento.id_tipo_evento;
                    }

        			this.$store.dispatch('evento/updateEvento',
                        {
                            id: this.evento.id,
                            nombre: this.nameEvento,
                            fecha_evento: $("#fechaEvento").val(),
                            hora_inicio: $("#horaInicio").val(),
                            hora_apertura: $("#horaApertura").val(),
                            hora_finalizacion: $("#horaFinalizacion").val(),
                            fecha_inicio_venta_internet: $("#fechaInicioVentaInternet").val(),
                            codigo_pulep: this.codigoPulep,
                            id_tipo_evento: tipoEventoUpdate,
                            domicilios: this.domiciliosEvento,
                            venta_linea: this.ventaLineaEvento,
                            id_auditorio: auditorioUpdate,
                            id_cliente: clienteUpdate,
                            id_temporada: temporadaUpdate,
                            status: this.statusEvento,
                            fecha_inicio_venta_puntos: this.fechaInicioVentaPuntos,
                            monto_minimo: this.montoMinimo
                        }
                        );
        			//console.log('estamos editando un evento');
        			$('#modalEvento').modal('hide');
        		}else{	
        			this.$store.dispatch('evento/newEvento',
                         {
                            nombre: this.nameEvento,
                            fecha_evento: $("#fechaEvento").val(),
                            hora_inicio: $("#horaInicio").val(),
                            hora_apertura: $("#horaApertura").val(),
                            hora_finalizacion: $("#horaFinalizacion").val(),
                            fecha_inicio_venta_internet: $("#fechaInicioVentaInternet").val(),
                            codigo_pulep: this.codigoPulep,
                            id_tipo_evento: this.tipoEvento,
                            domicilios: this.domiciliosEvento,
                            venta_linea: this.ventaLineaEvento,
                            id_auditorio: this.auditorio,
                            id_cliente: this.cliente,
                            id_temporada: this.temporada,
                            status: this.statusEvento,
                            fecha_inicio_venta_puntos: this.fechaInicioVentaPuntos,
                            monto_minimo: this.montoMinimo
                        });
        			//console.log('estamos guardando un evento');
        			$('#modalEvento').modal('hide');
        		}
        	}
        		
        	},
        	viewEvento(id){
        	    this.$store.commit('evento/viewEvento',id);
        	    $("#fechaEvento").val(this.evento.fecha_evento);
                $("#horaInicio").val(this.evento.hora_inicio);
                $("#horaApertura").val(this.evento.horaApertura);
                $("#horaFinalizacion").val(this.evento.hora_finalizacion);
                $("#fechaInicioVentaInternet").val(this.evento.fecha_inicio_venta_internet);
                this.nameEvento = this.evento.nombre;
                this.codigoPulep = this.evento.codigo_pulep;
                this.domiciliosEvento = this.evento.domicilios;
                this.ventaLineaEvento = this.evento.venta_linea;
                this.statusEvento = this.evento.status;
                this.fechaInicioVentaPuntos = this.evento.fecha_inicio_venta_puntos;
                this.tipoEvento = null;
                this.keyTipoEvento = null;
                if(this.evento.tipoevento != null){ 
                    this.tipoEventoActual = this.evento.tipoevento.nombre;
                }else{
                    this.tipoEventoActual = null;
                }
                this.auditorioActual = this.evento.auditorio.nombre;
                this.keyAuditorios = null;
                this.auditorio = null;
                this.clienteActual = this.evento.cliente.nombrerazon;
                this.keyClientes = null;
                this.cliente = null;
                this.temporada = null;
                this.keyTemporadas = null;
                if(this.evento.temporada != null){
                    this.temporadaActual = this.evento.temporada.nombre;
                }else{
                    this.temporadaActual = null;
                }
                this.montoMinimo = this.evento.monto_minimo;
        	    this.titleModalEvento = `Editar Evento: ${this.evento.nombre}`;
        	},
        	setNullEvento(){
        		this.$store.commit('evento/viewEvento',null);
                $("#fechaEvento").val('');
                $("#horaInicio").val('');
                $("#horaApertura").val('');
                $("#horaFinalizacion").val('');
                $("#fechaInicioVentaInternet").val('');
        		this.nameEvento = null;
                this.codigoPulep = null;
                this.domiciliosEvento = null;
                this.ventaLineaEvento = null;
                this.statusEvento = null;
                this.fechaInicioVentaPuntos = null;
                this.tipoEvento = null;
                this.keyTipoEvento = null;
                this.tipoEventoActual = null;
                this.auditorioActual = null;
                this.keyAuditorios = null;
                this.auditorio = null;
                this.clienteActual = null;
                this.keyClientes = null;
                this.cliente = null;
                this.temporada = null;
                this.keyTemporadas = null;
                this.temporadaActual = null;
                this.montoMinimo = null;
        		this.titleModalEvento = 'Nuevo Evento';
        	},
	         changePage(page){
    		  this.$store.commit('evento/changePage',page)
	          this.$store.dispatch('evento/listEventos',page);
	        },
              searchAuditorios(){
                this.$store.dispatch('auditorio/searchAuditorios', this.keyAuditorios);
            },
              searchClientes(){
                this.$store.dispatch('cliente/searchClientes', this.keyClientes);
            },
              searchTemporadas(){
                this.$store.dispatch('temporada/searchTemporadas', this.keyTemporadas);
            },
              searchTipoEventos(){
                this.$store.dispatch('tipoevento/searchTipoEventos', this.keyTipoEvento);
            },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
.modalEvento{
    max-width:1200px !important;
}
</style>