import axios from 'axios';
import {configApi} from '../../../api/configApi';
import {router} from '../../../main.js';
import {headers} from '../../../api/headers';
import {store} from '../../index';

export default {
  namespaced: true,
  state: {
    listPreventas: null,
    dataPreventaEdit: null,
  },
  actions: {
    async getListPreventa({state,getters,commit,rootState}){
      store.commit('changeLoader', true);
      await axios.get(`${configApi.apiUrl}app/v1/get_preventas`,null,{headers: headers})
      .then(response => {
        state.listPreventas = response.data
      })
      .catch(error => {
        swal("Error!", "Error al obtener las preventas!", "error");
      })
      .finally(()=> {
        store.commit('changeLoader', false);
      })
    },
    async crearPreventa({state, getters,commit,rootState}, payload){
      store.commit('changeLoader', true);
      await axios.post(`${configApi.apiUrl}app/v1/crear_preventa`,payload)
      .then(response => {
        swal("Creado", "La preventa se ha creado!", "success");
      })
      .catch(error => {
        swal("Error!", "Error al crear la preventa!", "error");
      })
      .finally(()=> {
        store.commit('changeLoader', false);
      })
    },
    async updatePreventa({state, getters,commit,rootState}, payload){
      store.commit('changeLoader', true);
      await axios.put(`${configApi.apiUrl}app/v1/update_preventa/${payload.id_preventa}`,payload)
      .then(response => {
        swal("Actualizado", "La preventa " + payload.id_preventa + " se ha actualizado!", "success");
      })
      .catch(error => {
        swal("Error!", "Error al actualizar la preventa!", "error");
      })
      .finally(()=> {
        store.commit('changeLoader', false);
      })
    },
    async getPreventaById({state,getters,commit,rootState}, payload){
      store.commit('changeLoader', true);
      await axios.get(`${configApi.apiUrl}app/v1/show_preventa/${payload}`,null,{headers: headers})
      .then(response => {
        state.dataPreventaEdit = response.data.preventa
      })
      .catch(error => {
        swal("Error!", "Error al obtener la preventa!", "error");
      })
      .finally(()=> {
        store.commit('changeLoader', false);
      })
    },
  }
}