<template>
	<div>
         <div class="row clearfix">
        <div class="col-lg-4 col-md-12">
            <div class="card">
                <div class="m-b-20">
                    <div class="contact-grid">
                        <div class="profile-header bg-dark">
                            <div class="user-name">{{ dataUser.nombre }}</div>
                            <div class="name-center">{{ dataUser.identificacion }}</div>
                        </div>
                        <img src="/images/user.png" class="user-img" alt="" :title="dataUser.nombre">
                         <div>
                            <p> <i class="fas fa-envelope"></i> {{ dataUser.email }}</p>
                        </div>
                        <p>
                            <i class="material-icons">location_on</i> {{ dataUser.direccion }}
                        </p>
                        <div>
                            <span class="phone">
                                <i class="material-icons">phone</i>{{ dataUser.telefono }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-8 col-md-12">
            <div class="card">
                <div class="profile-tab-box">
                    <div class="p-l-20">
                        <ul class="nav ">
                            <li class="nav-item tab-all">
                                <a class="nav-link active show" href="#project" data-toggle="tab">Informacion personal</a>
                            </li>
                            <li class="nav-item tab-all p-l-20">
                                <a class="nav-link" href="#usersettings" data-toggle="tab">Ajustes</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="tab-content">
                <div role="tabpanel" class="tab-pane active" id="project" aria-expanded="true">
                    <div class="row clearfix">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="card project_widget">
                                <div class="header">
                                    <h2>Informacion <strong>personal</strong></h2>
                                </div>
                                <div class="body">
                                <form @submit.prevent="">
                            <div class="form-group">
                               <label for="nombre">Nombre</label>
                                <input type="text" id="nombre" class="form-control" placeholder="Nombre" v-model="user.nombre" required="">
                            </div>
                             <div class="form-group">
                               <label for="identificacion">Identificacion</label>
                                <input type="text" id="identificacion" class="form-control" placeholder="Identificacion" v-model="user.identificacion" required="">
                            </div>
                              <div class="form-group">
                               <label for="telefono">Telefono</label>
                                <input type="text" id="telefono" class="form-control" placeholder="Telefono" v-model="user.telefono" required="">
                            </div>
                             <div class="form-group">
                               <label for="direccion">Direccion</label>
                                <input type="text" id="direccion" class="form-control" placeholder="Direccion" v-model="user.direccion" required="">
                            </div>
                              <div class="form-group">
                               <label for="departamento">Departamento</label>
                                <input type="text" id="departamento" class="form-control" placeholder="Departamento" v-model="user.departamento" required="">
                            </div>
                              <div class="form-group">
                               <label for="ciudad">Ciudad</label>
                                <input type="text" id="ciudad" class="form-control" placeholder="Ciudad" v-model="user.ciudad" required="">
                            </div>
                            <div class="text-right">
                                <button v-if="user.nombre != '' && user.identificacion != '' && user.direccion != '' && 
                                user.departamento != '' && user.ciudad != '' && user.telefono != ''" class="btn btn-primary btn-round" type="button" @click="updateUser">GUARDAR</button>
                            </div>
                        </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div role="tabpanel" class="tab-pane" id="timeline" aria-expanded="false">
                </div>
                <div role="tabpanel" class="tab-pane" id="usersettings" aria-expanded="false">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <strong>Ajustes</strong> Contraseña</h2>
                        </div>
                        <div class="body">
                                    <form @submit.prevent="">
                            <div class="form-group">
                               <label for="password">Contraseña actual</label>
                                <input type="password" id="password" class="form-control" placeholder="Contraseña actual" v-model="security.passwordActual" required="">
                            </div>
                             <div class="form-group">
                               <label for="password_new">Nueva contraseña</label>
                                <input type="password" id="password_new" class="form-control" placeholder="Nueva contraseña" v-model="security.password_new" required="">
                            </div>
                              <div class="form-group">
                               <label for="password_new_confirmed">Confirmar contraseña</label>
                                <input type="password" id="password_new_confirmed" class="form-control" placeholder="Confirmar contraseña" v-model="security.password_new_confirmed" required="">
                            </div>
                            <div class="alert alert-danger" v-if="(security.password_new != null && security.password_new_confirmed != null) && (security.password_new != security.password_new_confirmed)">
                                <strong><i class="material-icons">warning</i> Las contraseñas nuevas deben coincidir!</strong>
                            </div>
                            <div class="text-right">
                                <button v-if="(security.passwordActual != null && security.password_new != null && security.password_new_confirmed != null) && (security.password_new === security.password_new_confirmed)" class="btn btn-primary btn-round" type="button" @click="updatePassword">GUARDAR</button>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
	</div>
</template>
<script>
	
	export default{
        created(){
            document.title = 'Mi perfil - TicketShop';
            this.user.nombre = this.dataUser.nombre;
            this.user.identificacion = this.dataUser.identificacion;
            this.user.direccion = this.dataUser.direccion;
            this.user.departamento = this.dataUser.departamento;
            this.user.ciudad = this.dataUser.ciudad;
            this.user.telefono = this.dataUser.telefono;
            this.user.email = this.dataUser.email;
        },
        data(){
            return {
               user:{
                     nombre: null,
                     identificacion: null,
                     direccion: null,
                     departamento: null,
                     ciudad: null,
                     telefono: null,
                     email: null,
               },
               security: {
                    passwordActual: null,
                    password_new: null,
                    password_new_confirmed: null
               }
            }
        },
        computed:{
            dataUser(){
                return this.$store.getters['user/dataUserLogged']; 
            }
        },
        methods:{
          updateUser(){
            this.$store.dispatch("user/updateProfile",this.user);
          },
          updatePassword(){
            this.$store.dispatch('user/updatePassword',this.security).then(() => {
                this.security.passwordActual = null;
                this.security.password_new = null;
                this.security.password_new_confirmed = null;
            });
          },
        }
	}  
</script>
<style>

</style>