<template>
    <div>
         <v-app id="inspire" v-if="detallesDescuentos != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-percentage"></i><strong> Listado de</strong> Detalles de Descuento</h2>
                            <div class="text-right">
                            <button @click="setNullDetalleDescuento" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalDetalleDescuento">NUEVO REGISTRO</button>
                        </div>
                        <div>
                             <export-excel style="width: fit-content;" :data="detallesDescuentos" :fields="json_fields" worksheet="Listado de detalles de descuento" name="detallesDescuentos.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
            must-sort
            :items='detallesDescuentos'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado detalles de descuento...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td class="text-xs-center"><span v-if="props.item.descuento_evento == null">{{ props.item.id_descuento }}</span><span v-else>{{ props.item.descuento_evento.nombre }}</span></td>
      <td class="text-xs-center"><span v-if="props.item.tribuna == null">{{ props.item.id_tribuna }}</span><span v-else>{{ props.item.tribuna.nombre }}</span></td>
      <td class="text-xs-center"><span v-if="props.item.localidad == null">{{ props.item.id_localidad }}</span><span v-else>{{ props.item.localidad.nombre }}</span></td>
      <td class="text-xs-center">{{ props.item.id_boleta_evento }}</td>
      <td class="text-xs-center">{{ props.item.id_palco_evento }}</td>
      <td class="text-xs-center">{{ props.item.porcentaje }}</td>
      <td class="text-xs-center">{{ props.item.monto }}</td>
      <td class="text-xs-center">{{ props.item.moneda.codigo_moneda }} - {{ props.item.moneda.descripcion }}</td>
      <td class="text-xs-center">{{ props.item.status }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewDetalleDescuento(props.item.id)"
           data-toggle="modal" data-target="#modalDetalleDescuento">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteDetalleDescuento(props.item.id)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalDetalleDescuento" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalDetalleDescuento }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                      <div class="col-md-6 col-lg-6">
                        <label for="porcentajeDetalleDescuento">Porcentaje</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" step="any" id="porcentajeDetalleDescuento" class="form-control"
                                    placeholder="Porcentaje" required="" v-model="porcentajeDetalleDescuento">
                            </div>
                        </div>
                      </div>
                       <div class="col-md-6 col-lg-6">
                          <label for="montoDetalleDescuento">Monto</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" step="any" id="montoDetalleDescuento" class="form-control"
                                    placeholder="Monto" required="" v-model="montoDetalleDescuento">
                            </div>
                        </div>
                       </div>
                         <div class="col-md-6 col-lg-6">
                          <label for="statusDetalleDescuento">Estado</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="statusDetalleDescuento" class="form-control"
                                    placeholder="Estado" required="" v-model="statusDetalleDescuento">
                            </div>
                        </div>
                       </div>
                        <div class="col-md-6 col-lg-6">
                            <label for="descuentoActual" v-if="descuentoActual != null">Descuento</label>
                          <div class="form-group" v-if="descuentoActual != null">
                            <div class="form-line">
                                <input type="text" id="descuentoActual" class="form-control"
                                    placeholder="Descuento" :value="descuentoActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchDescuentos" class="form-control"
                                    placeholder="Buscar descuentos..." v-model="keyDescuentos" @keyup.enter="searchDescuentos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(descuentos && descuentos.length > 0)">
                        <v-select
                          :items="descuentos"
                           item-text="nombre"
                           item-value="id"
                           v-model="descuento"
                          label="Seleccione un descuento"
                        ></v-select>
                    </div>
                       </div>
                        <div class="col-md-6 col-lg-6" v-if="evento == null">
                            <label for="tribunaActual" v-if="tribunaActual != null">Tribuna</label>
                          <div class="form-group" v-if="tribunaActual != null">
                            <div class="form-line">
                                <input type="text" id="tribunaActual" class="form-control"
                                    placeholder="Tribuna" :value="tribunaActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchTribunas" class="form-control"
                                    placeholder="Buscar tribunas..." v-model="keyTribunas" @keyup.enter="searchTribunas">
                            </div>
                        </div>
                        <div class="form-group" v-if="(tribunas && tribunas.length > 0)">
                        <v-select
                          :items="tribunas"
                           item-text="nombre"
                           item-value="id"
                           v-model="tribuna"
                          label="Seleccione una tribuna"
                        ></v-select>
                    </div>
                       </div>
                          <div class="col-md-6 col-lg-6" v-if="evento == null">
                            <label for="localidadActual" v-if="localidadActual != null">Localidad</label>
                          <div class="form-group" v-if="localidadActual != null">
                            <div class="form-line">
                                <input type="text" id="localidadActual" class="form-control"
                                    placeholder="Localidad" :value="localidadActual">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchLocalidades" class="form-control"
                                    placeholder="Buscar localidades..." v-model="keyLocalidades" @keyup.enter="searchLocalidades">
                            </div>
                        </div>
                        <div class="form-group" v-if="(localidades && localidades.length > 0)">
                        <v-select
                          :items="localidades"
                           item-text="nombre"
                           item-value="id"
                           v-model="localidad"
                          label="Seleccione una localidad"
                        ></v-select>
                    </div>
                       </div>
                       <div class="col-lg-12 col-md-12" v-if="tribunaActual == null && tribuna == null">
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchEventos" class="form-control"
                                    placeholder="Buscar eventos..." v-model="keyEventos" @keyup.enter="searchEventos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(eventos && eventos.length > 0)">
                        <v-select
                          :items="eventos"
                           item-text="nombre"
                           item-value="id"
                           v-model="evento"
                          label="Seleccione un evento"
                        ></v-select>
                    </div>
                       </div>
                       <div class="col-md-6 col-lg-6" v-if="palco == null && tribunaActual == null && tribuna == null">
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="searchPuestos" class="form-control"
                                    placeholder="Buscar puestos..." v-model="keyPuestos" @keyup.enter="searchPuestos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(puestos && puestos.length > 0)">
                        <v-select
                          :items="puestos"
                           item-text="numero"
                           item-value="id"
                           v-model="puesto"
                          label="Seleccione un puesto"
                        ></v-select>
                    </div>
                       </div>
                          <div class="col-md-6 col-lg-6" v-if="puesto == null && tribunaActual == null && tribuna == null">
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchPalcos" class="form-control"
                                    placeholder="Buscar palcos..." v-model="keyPalcos" @keyup.enter="searchPalcos">
                            </div>
                        </div>
                        <div class="form-group" v-if="(palcos && palcos.length > 0)">
                        <v-select
                          :items="palcos"
                           item-text="nombre"
                           item-value="id"
                           v-model="palco"
                          label="Seleccione un palco"
                        ></v-select>
                    </div>
                    </div>
                       <div class="col-md-6 col-lg-6">
                            <label for="monedaDetalleDescuento" v-if="monedaDetalleDescuento != null">Moneda</label>
                          <div class="form-group" v-if="monedaDetalleDescuento != null">
                            <div class="form-line">
                                <input type="text" id="monedaDetalleDescuento" class="form-control"
                                    placeholder="Moneda" :value="monedaDetalleDescuento">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchMonedas" class="form-control"
                                    placeholder="Buscar monedas..." v-model="keyMonedas" @keyup.enter="searchMonedas">
                            </div>
                        </div>
                        <div class="form-group" v-if="(monedas && monedas.length > 0)">
                        <v-select
                          :items="monedas"
                           item-text="descripcion"
                           item-value="codigo_moneda"
                           v-model="moneda"
                          label="Seleccione una moneda"
                        ></v-select>
                    </div>
                       </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info waves-effect" @click="saveDetalleDescuento">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
    </div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

    export default {
        beforeCreate(){
           this.$store.dispatch('detalledescuento/listDetallesDescuentos', 1);
        },
        created(){
           document.title = 'Detalles de descuento - TicketShop';
        },
        async mounted(){
           //adminApp() 
           this.$store.dispatch('detalledescuento/listDetallesDescuentos', 1);
        },
        data(){
            return {
                titleModalDetalleDescuento: null,
                porcentajeDetalleDescuento: null,
                montoDetalleDescuento: null,
                statusDetalleDescuento: null,
                evento: null,
                keyEventos: null,
                monedaDetalleDescuento: null,
                moneda: null,
                keyMonedas: null,
                puesto: null,
                keyPuestos: null,
                palco: null,
                keyPalcos: null,
                descuentoActual: null,
                descuento: null,
                keyDescuentos: null,
                tribunaActual: null,
                tribuna: null,
                keyTribunas: null,
                localidadActual: null,
                localidad: null,
                keyLocalidades: null,
                search: '',
                headers: [
                  {
                    text: 'Id',
                    align: 'left',
                    sortable: false,
                    value: 'id'
                  },
                  {text: 'Descuento', value: 'descuento_evento.nombre',align: 'center' },
                  {text: 'Tribuna', value: 'tribuna.nombre',align: 'center' },
                  {text: 'Localidad', value: 'localidad.nombre',align: 'center' },
                  {text: 'Boleta evento', value: 'id_boleta_evento',align: 'center' },
                  {text: 'Palco evento', value: 'id_palco_evento',align: 'center' },
                  {text: 'Porcentaje', value: 'porcentaje',align: 'center' },
                  {text: 'Monto', value: 'monto',align: 'center' },
                  {text: 'Moneda', value: 'codigo_moneda',align: 'center' },
                  {text: 'Estado', value: 'status',align: 'center' },
                  {text: 'Acciones',value: '', align: 'right'}
             ],
             json_fields: {
            'Id': 'id',
            'Descuento': 'id_descuento',
            'Tribuna': 'id_tribuna',
            'Localidad': 'id_localidad',
            'Boleta evento': 'id_boleta_evento',
            'Palco evento': 'id_palco_evento',
            'Porcentaje': 'porcentaje',
            'Monto': 'monto',
            'Moneda': 'codigo_moneda',
            'Estado': 'status',

        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
            }
        },
        watch: {
            pagination: {
              async handler () {
                this.$store.dispatch('detalledescuento/listDetallesDescuentos', this.pagination.page);
              },
              deep: true
            }
          },
        computed: {
            detallesDescuentos(){
                return this.$store.getters['detalledescuento/listDetallesDescuentos'];
            },
            detalledescuento(){
                return this.$store.getters['detalledescuento/detalledescuento'];
            },
            pagination(){
                return this.$store.getters['detalledescuento/pagination'];
              },
             pages () {
                return this.$store.getters['detalledescuento/pages'];
              },
              totalItemCount(){
                return this.$store.getters['detalledescuento/totalItemCount'];
              },
              loading(){
                    return this.$store.getters['detalledescuento/loading'];
              },
               eventos(){
                return this.$store.getters['evento/listEventos'];
              },
                puestos(){
                return this.$store.getters['puesto/listPuestos'];
              },
                monedas(){
                return this.$store.getters['moneda/listMonedas'];
              },
               palcos(){
              return this.$store.getters['palco/listPalcos'];
            },
              tribunas(){
              return this.$store.getters['tribuna/listTribunas'];
            },
            localidades(){
              return this.$store.getters['localidad/listLocalidades'];
            },
            descuentos(){
              return this.$store.getters['descuentoevento/listDescuentosEventos'];
            },
        },
        methods: {
            deleteDetalleDescuento(id){
                var self = this;
                    swal({
                    title: "¿Estas seguro de eliminar el registro?",
                    text: "El registro se eliminara permanentemente!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Si, Eliminarlo!",
                    cancelButtonText: "No, cancelar!",
                    closeOnConfirm: false,
                    closeOnCancel: false
                }, function (isConfirm) {
                    if (isConfirm) {
                        self.$store.dispatch('detalledescuento/deleteDetalleDescuento',id);
                    } else {
                        swal("Cancelado", "Se ha cancelado la operacion.", "error");
                    }
                });
            },
            saveDetalleDescuento(){
                if((this.porcentajeDetalleDescuento != null) && 
                  (this.montoDetalleDescuento != null) &&
                  (this.statusDetalleDescuento != null) &&
                  (this.puesto != null || this.evento != null || this.palco != null || this.tribuna != null || this.detalledescuento) &&
                  (this.monedaDetalleDescuento != null || this.moneda != null) &&
                  (this.descuentoActual != null || this.descuento != null)){ 
                if(this.detalledescuento){
                     var descuentoUpdate = null;
                    if(this.descuento != null && this.descuento != ''){
                        descuentoUpdate = this.descuento;
                    }else{
                        descuentoUpdate = this.detalledescuento.id_descuento;
                    }
                       var tribunaUpdate = null;
                    if(this.tribuna != null && this.tribuna != ''){
                        tribunaUpdate = this.tribuna;
                    }else{
                        tribunaUpdate = this.detalledescuento.id_tribuna;
                    }
                      var localidadUpdate = null;
                    if(this.localidad != null && this.localidad != ''){
                        localidadUpdate = this.localidad;
                    }else{
                        localidadUpdate = this.detalledescuento.id_localidad;
                    }
                      var monedaUpdate = null;
                    if(this.moneda != null && this.moneda != ''){
                        monedaUpdate = this.moneda;
                    }else{
                        monedaUpdate = this.detalledescuento.moneda.codigo_moneda;
                    }
                    this.$store.dispatch('detalledescuento/updateDetalleDescuento',
                       {
                          id: this.detalledescuento.id,
                          id_descuento: descuentoUpdate,
                          id_tribuna: tribunaUpdate,
                          id_localidad: localidadUpdate,
                          id_boleta_evento: this.detalledescuento.id_boleta_evento,
                          id_palco_evento: this.detalledescuento.id_palco_evento,
                          porcentaje: this.porcentajeDetalleDescuento,
                          monto: this.montoDetalleDescuento,
                          status: this.statusDetalleDescuento,
                          codigo_moneda: monedaUpdate
                       });
                    //console.log('estamos editando un boletaEvento');
                    $('#modalDetalleDescuento').modal('hide');
                }else{  
                    this.$store.dispatch('detalledescuento/newDetalleDescuento',
                       {
                          id_descuento: this.descuento,
                          id_tribuna: this.tribuna,
                          id_localidad: this.localidad,
                          id_evento: this.evento,
                          id_puesto: this.puesto,
                          id_palco: this.palco,
                          porcentaje: this.porcentajeDetalleDescuento,
                          monto: this.montoDetalleDescuento,
                          status: this.statusDetalleDescuento,
                          codigo_moneda: this.moneda
                       });
                    //console.log('estamos guardando un detalledescuento');
                    $('#modalDetalleDescuento').modal('hide');
                }
            }
                
            },
            viewDetalleDescuento(id){
                this.$store.commit('detalledescuento/viewDetalleDescuento',id);
                this.porcentajeDetalleDescuento = this.detalledescuento.porcentaje;
                this.montoDetalleDescuento = this.detalledescuento.monto;
                this.statusDetalleDescuento = this.detalledescuento.status;
                  if(this.detalledescuento.descuento_evento != null){ 
                    this.descuentoActual = this.detalledescuento.descuento_evento.nombre;
                }else{
                    this.descuentoActual = null;
                }
                if(this.detalledescuento.tribuna != null){ 
                    this.tribunaActual = this.detalledescuento.tribuna.nombre;
                }else{
                    this.tribunaActual = null;
                }
                 if(this.detalledescuento.localidad != null){ 
                    this.localidadActual = this.detalledescuento.localidad.nombre;
                }else{
                    this.localidadActual = null;
                }
                this.evento = null;
                this.keyEventos = null;
                this.puesto = null;
                this.keyPuestos = null;
                this.palco = null;
                this.keyPalcos = null;
                this.tribuna = null;
                this.keyTribunas = null;
                this.localidad = null;
                this.keyLocalidades = null;
                this.descuento = null;
                this.keyDescuentos = null;
                if(this.detalledescuento.moneda != null){ 
                    this.monedaDetalleDescuento = `${this.detalledescuento.moneda.codigo_moneda} ${this.detalledescuento.moneda.descripcion}`;
                }else{
                    this.monedaDetalleDescuento = null;
                }
                this.moneda = null;
                this.keyMonedas = null;
                this.titleModalDetalleDescuento = `Editar Registro`;
            },
            setNullDetalleDescuento(){
                this.$store.commit('detalledescuento/viewDetalleDescuento',null);
                this.porcentajeDetalleDescuento = null;
                this.montoDetalleDescuento = null;
                this.statusDetalleDescuento = null;
                this.descuentoActual = null;
                this.tribunaActual = null;
                this.localidadActual = null;
                this.evento = null;
                this.keyEventos = null;
                this.puesto = null;
                this.keyPuestos = null;
                this.palco = null;
                this.keyPalcos = null;
                this.tribuna = null;
                this.keyTribunas = null;
                this.localidad = null;
                this.keyLocalidades = null;
                this.descuento = null;
                this.keyDescuentos = null;
                this.monedaDetalleDescuento = null;
                this.moneda = null;
                this.keyMonedas = null;
                this.titleModalDetalleDescuento = 'Nuevo Registro';
            },
             changePage(page){
              this.$store.commit('detalledescuento/changePage',page)
              this.$store.dispatch('detalledescuento/listDetallesDescuentos',page);
            },
              searchEventos(){
                this.$store.dispatch('evento/searchEventos', this.keyEventos);
            },
             searchPuestos(){
                this.$store.dispatch('puesto/searchPuestos', this.keyPuestos);
            },
             searchMonedas(){
                this.$store.dispatch('moneda/searchMonedas', this.keyMonedas);
            },
            searchPalcos(){
                this.$store.dispatch('palco/searchPalcos', this.keyPalcos);
            },
              searchTribunas(){
                this.$store.dispatch('tribuna/searchTribunas', this.keyTribunas);
            },
             searchLocalidades(){
                this.$store.dispatch('localidad/searchLocalidades', this.keyLocalidades);
            },
             searchDescuentos(){
                this.$store.dispatch('descuentoevento/searchDescuentos', this.keyDescuentos);
            },
        }
    }
</script>
<style scoped>
.changes{
    margin-right: 5px;
    margin-bottom: 12px;
}
</style>