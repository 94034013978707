<template>
	<div>
		 <v-app id="inspire" v-if="filas != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-grip-horizontal"></i><strong> Listado de</strong> Filas</h2>
                            <div class="text-right">
                            <button @click="setNullFila" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalFila">NUEVA FILA</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="filas" :fields="json_fields" worksheet="Listado de filas" name="filas.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='filas'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado filas...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td class="text-xs-center">{{ props.item.nombre }}</td>
      <td class="text-xs-center">{{ props.item.numero }}</td>
      <td class="text-xs-center">{{ props.item.localidad.nombre }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewFila(props.item.id)"
           data-toggle="modal" data-target="#modalFila">
            edit
          </v-icon>
          <v-icon
            small
            @click="deleteFila(props.item.id)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalFila" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titleModalFila }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                         <label for="nameFila">Nombre</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="nameFila" class="form-control"
                                    placeholder="Nombre de la fila" required="" v-model="nameFila">
                            </div>
                        </div>
                            <label for="numeroFila">Numero</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="number" id="numeroFila" class="form-control"
                                    placeholder="Numero de la fila" required="" v-model="numeroFila">
                            </div>
                        </div>
                      <label for="localidadFila" v-if="localidadFila != null">Localidad</label>
                          <div class="form-group" v-if="localidadFila != null">
                            <div class="form-line">
                                <input type="text" id="localidadFila" class="form-control"
                                    placeholder="Localidad de la fila" :value="localidadFila">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchLocalidades" class="form-control"
                                    placeholder="Buscar localidades..." v-model="keyLocalidades" @keyup.enter="searchLocalidades">
                            </div>
                        </div>
                        <div class="form-group" v-if="(localidades && localidades.length > 0)">
                        <v-select
                          :items="localidades"
                           item-text="nombre"
                           item-value="id"
                           v-model="localidad"
                          label="Seleccione una localidad"
                        ></v-select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button"  v-if="((nameFila != null && numeroFila != null) && (localidad != null || localidadFila != null))" class="btn btn-info waves-effect" @click="saveFila">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('fila/listFilas', 1);
        },
		created(){
           document.title = 'Filas - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('fila/listFilas', 1);
        },
        data(){
        	return {
        		titleModalFila: null,
        		nameFila: null,
                localidadFila: null,
                numeroFila: null,
                localidad: null,
                keyLocalidades: null,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id'
		          },
		          {text: 'Nombre', value: 'nombre',align: 'center' },
                  {text: 'Numero', value: 'numero',align: 'center' },
                  {text: 'Localidad', value: 'localidad.nombre',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id',
            'Nombre': 'nombre',
            'Numero': 'numero',
            'Localidad': 'localidad.nombre',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('fila/listFilas', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	filas(){
        		return this.$store.getters['fila/listFilas'];
        	},
        	fila(){
        		return this.$store.getters['fila/fila'];
        	},
		    pagination(){
        		return this.$store.getters['fila/pagination'];
		      },
		     pages () {
		        return this.$store.getters['fila/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['fila/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['fila/loading'];
		      },
               localidades(){
                return this.$store.getters['localidad/listLocalidades'];
              },
        },
        methods: {
        	deleteFila(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar la fila?",
			        text: "La fila se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarla!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('fila/deleteFila',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	saveFila(){
        		if((this.nameFila != '' || this.nameFila != null) &&
                    (this.numeroFila != '' || this.numeroFila != null) && 
                    ((this.localidad != '' || this.localidad != null) ||
                     (this.localidadFila != '' || this.localidadFila != null))){ 
        		if(this.fila){
                    var localidadUpdate = null;
                    if(this.localidad != null && this.localidad != ''){
                        localidadUpdate = this.localidad;
                    }else{
                        localidadUpdate = this.fila.id_localidad;
                    }
        			this.$store.dispatch('fila/updateFila',{id: this.fila.id,nombre: this.nameFila,numero: this.numeroFila, id_localidad: localidadUpdate})
        			//console.log('estamos editando un fila');
        			$('#modalFila').modal('hide');
        		}else{	
        			this.$store.dispatch('fila/newFila',{nombre: this.nameFila,numero: this.numeroFila, id_localidad: this.localidad});
        			//console.log('estamos guardando un fila');
        			$('#modalFila').modal('hide');
        		}
        	}
        		
        	},
        	viewFila(id){
        	    this.$store.commit('fila/viewFila',id);
        	    this.nameFila = this.fila.nombre;
                this.localidadFila = this.fila.localidad.nombre;
                this.numeroFila = this.fila.numero;
                this.keyLocalidades = null;
                this.localidad = null;
        	    this.titleModalFila = `Editar Fila: ${this.fila.nombre}`;
        	},
        	setNullFila(){
        		this.$store.commit('fila/viewFila',null);
        		this.nameFila = null;
                this.localidadFila = null;
                this.keyLocalidades = null;
                this.localidad = null;
                this.numeroFila = null;
        		this.titleModalFila = 'Nuevo Fila';
        	},
	         changePage(page){
    		  this.$store.commit('fila/changePage',page)
	          this.$store.dispatch('fila/listFilas',page);
	        },
            searchLocalidades(){
                this.$store.dispatch('localidad/searchLocalidades', this.keyLocalidades);
            },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>