import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import {mutations} from './mutations';
import {actions} from './actions';
import {getters} from './getters';
import user from  './modules/user';
import genero from  './modules/genero';
import artista from './modules/artista';
import auditorio from './modules/auditorio';
import temporada from './modules/temporada';
import tipodescuento from './modules/tipodescuento';
import tipoevento from './modules/tipoevento';
import cuponera from './modules/cuponera';
import tipocupon from './modules/tipocupon';
import puntoventa from './modules/puntoventa';
import grupovendedor from './modules/grupovendedor';
import cliente from './modules/cliente';
import tribuna from './modules/tribuna';
import localidad from './modules/localidad';
import palco from './modules/palco';
import fila from './modules/fila';
import grupovendedorpunto from './modules/grupovendedorpunto';
import imagen from './modules/imagen';
import puesto from './modules/puesto';
import puestopalco from './modules/puestopalco';
import evento from './modules/evento';
import eventocuponera from './modules/eventocuponera';
import pais from './modules/pais';
import departamento from './modules/departamento';
import ciudad from './modules/ciudad';
import condicion from './modules/condicion';
import tipocosto from './modules/tipocosto';
import preventa from './modules/preventa';
import moneda from './modules/moneda';
import tasa from './modules/tasa';
import boletaevento from './modules/boletaevento';
import condicionevento from './modules/condicionevento';
import costoevento from './modules/costoevento';
import palcoevento from './modules/palcoevento';
import descuentoevento from './modules/descuentoevento';
import puntoevento from './modules/puntoevento';
import boletapreventa from './modules/boletapreventa';
import imagenartista from './modules/imagenartista';
import imagenauditorio from './modules/imagenauditorio';
import imagenevento from './modules/imagenevento';
import palcopreventa from './modules/palcopreventa';
import detalledescuento from './modules/detalledescuento';
import puestopalcoevento from './modules/puestopalcoevento';
import informes from '@/store/modules/InformeEventos/informes'
import preventas from '@/store/modules/Preventas/preventas'
import apariencia from '@/store/modules/Apariencia/apariencia'

Vue.use(Vuex);



export const store = new Vuex.Store({
    modules:{
        user,
        genero,
        artista,
        auditorio,
        temporada,
        tipodescuento,
        tipoevento,
        cuponera,
        tipocupon,
        puntoventa,
        grupovendedor,
        cliente,
        tribuna,
        localidad,
        palco,
        fila,
        grupovendedorpunto,
        imagen,
        puesto,
        puestopalco,
        evento,
        eventocuponera,
        pais,
        departamento,
        ciudad,
        condicion,
        tipocosto,
        preventa,
        moneda,
        tasa,
        boletaevento,
        condicionevento,
        costoevento,
        palcoevento,
        descuentoevento,
        puntoevento,
        boletapreventa,
        imagenartista,
        imagenauditorio,
        imagenevento,
        palcopreventa,
        detalledescuento,
        puestopalcoevento,
        informes,
        preventas,
        apariencia,
    },
    state:{
        accessToken: null,
        loggingIn: false,
        loginError: null,
        loader: true,
        validateToken: null,
        tokenResetPassword: null,
        tokenActiveAccount: null,
        datosreporte:null,
    },
    mutations,
    actions,
    getters,
    plugins: [createPersistedState()]
});