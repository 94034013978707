import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       puntosVenta: [],
       puntoVenta: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listPuntosVenta: (state,getters,rootState) => {
          return state.puntosVenta;
    },
    puntoVenta: (state,getters,rootState) => {
        return state.puntoVenta;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listPuntosVenta: (state,puntosVenta) => {
          state.puntosVenta = puntosVenta.data;
          state.pagination.totalItems = puntosVenta.total;
          state.pagination.page = puntosVenta.current_page;
          state.pagination.rowsPerPage = puntosVenta.per_page;
       },
        listPuntosVentaSearch: (state,puntosVenta) => {
            if(puntosVenta != null){ 
                state.puntosVenta = puntosVenta;
            }else{
              state.puntosVenta = null;
            }
       },
      viewPuntoVenta(state,id){
        let auxTipoCupon = state.puntosVenta.filter((item) => item.id == id);
        state.puntoVenta = auxTipoCupon[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listPuntosVenta({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}puntoventum?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listPuntosVenta',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los puntos de venta!", "error");
        console.error(error);
      });

    },
  newPuntoVenta({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}puntoventum`,
        {
          nombre_razon: payload.namePuntoVenta,
          identificacion: payload.identificacion,
          direccion: payload.direccion,
          telefono: payload.telefono,
          tipo_identificacion: payload.tipo_identificacion,
          responsable: payload.responsable,
          zona: payload.zona,
          email: payload.email,
          id_ciudad: payload.id_ciudad
        }
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo el punto de venta correctamente!", "success");
        store.dispatch('puntoventa/listPuntosVenta',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear el punto de venta!", "error");
        console.error(error);
      });
  },
  updatePuntoVenta({state,getters,commit,rootState},payload){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}puntoventum/${payload.id}`,
        {
          nombre_razon: payload.namePuntoVenta,
          identificacion: payload.identificacion,
          direccion: payload.direccion,
          telefono: payload.telefono,
          tipo_identificacion: payload.tipo_identificacion,
          responsable: payload.responsable,
          zona: payload.zona,
          email: payload.email,
          id_ciudad: payload.id_ciudad
        },
        {headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo el punto de venta correctamente!", "success");
        store.dispatch('puntoventa/listPuntosVenta',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar el punto de venta!", "error");
        console.error(error);
      });
  },
  deletePuntoVenta({state,getters,commit,rootState},puntoVenta){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}puntoventum/${puntoVenta}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino el punto de venta correctamente!", "success");
        store.dispatch('puntoventa/listPuntosVenta',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar el punto de venta!","error");
        console.log(error);
     });
  },
    searchPuntosVenta({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarPuntoVentum?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listPuntosVentaSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener los puntos de venta!", "error");
        console.error(error);
      });

    },
  },
}