<template>
	<div>
		 <v-app id="inspire" v-if="puntosVenta != null">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="header">
                            <h2>
                                <i class="fas fa-map-marker-alt"></i><strong> Listado de</strong> Puntos de venta</h2>
                            <div class="text-right">
                            <button @click="setNullPuntoVenta" class="btn btn-primary waves-effect" data-toggle="modal" data-target="#modalPuntoVenta">NUEVO PUNTO VENTA</button>
                        </div>
                        <div>
                        	 <export-excel style="width: fit-content;" :data="puntosVenta" :fields="json_fields" worksheet="Listado de puntos de venta" name="puntosVenta.xls"><button type="button" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-file-excel"></i> EXCEL</button></export-excel>
                        </div>
                        </div>
     <v-data-table :headers='headers'
     		must-sort
            :items='puntosVenta'
             hide-actions
            :length='pages'
            :search='search'
            :total-items='totalItemCount'
            class='elevation-1'
            :loading="loading"
            :no-data-text="'No se han encontrado puntos de venta...'"
            >
    <template v-slot:items="props">
      <td>{{ props.item.id }}</td>
      <td class="text-xs-center">{{ props.item.nombre_razon }}</td>
      <td class="text-xs-center">{{ props.item.identificacion }}</td>
      <td class="text-xs-center">{{ props.item.tipo_identificacion }}</td>
      <td class="text-xs-center">{{ props.item.direccion }}</td>
      <td class="text-xs-center">{{ props.item.telefono }}</td>
      <td class="text-xs-center">{{ props.item.responsable }}</td>
      <td class="text-xs-center">{{ props.item.zona }}</td>
      <td class="text-xs-center">{{ props.item.email }}</td>
      <td class="text-xs-center">{{ props.item.ciudades.descripcion }}</td>
    <td class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="viewPuntoVenta(props.item.id)"
           data-toggle="modal" data-target="#modalPuntoVenta">
            edit
          </v-icon>
          <v-icon
            small
            @click="deletePuntoVenta(props.item.id)"
          >
            delete
          </v-icon>
        </td>
    </template>
  </v-data-table>
  <div class="text-xs-center pt-2">
      <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
    </div>
     </div>
           </div>
            </div>
                <div class="modal fade" id="modalPuntoVenta" tabindex="-1" role="dialog" aria-labelledby="formModal" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <form action="#" method="POST" @submit.prevent="">
                <div class="modal-header">
                    <h5 class="modal-title" id="formModal">{{ titlemodalPuntoVenta }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body row">
                        <div class="col-md-6 col-lg-6">
                        <label for="namePuntoVenta">Nombre razon</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="namePuntoVenta" class="form-control"
                                    placeholder="Nombre del punto de venta" required="" v-model="namePuntoVenta">
                            </div>
                        </div>
                    </div>
                        <div class="col-md-6 col-lg-6">
                         <label for="identificacionPuntoVenta">Identificacion</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="identificacionPuntoVenta" class="form-control"
                                    placeholder="Identificacion del punto de venta" required="" v-model="identificacionPuntoVenta">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                          <label for="telefonoPuntoVenta">Telefono</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="tel" id="telefonoPuntoVenta" class="form-control"
                                    placeholder="Telefono del punto de venta" required="" v-model="telefonoPuntoVenta">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                          <label for="direccionPuntoVenta">Direccion</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="direccionPuntoVenta" class="form-control"
                                    placeholder="Direccion del punto de venta" required="" v-model="direccionPuntoVenta">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                           <label for="tipoIdentificacionPuntoVenta">Tipo identificacion</label>
                        <div class="form-group">
                        <br>
                        <div class="switch">
                                    <label>Inactivo
                                        <input id="tipoIdentificacionPuntoVenta" type="checkbox" v-model="tipoIdentificacionPuntoVenta">
                                        <span class="lever"></span>Activo</label>
                                </div>
                         </div>
                     </div>
                    <div class="col-md-4 col-lg-4">
                          <label for="responsablePuntoVenta">Responsable</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="responsablePuntoVenta" class="form-control"
                                    placeholder="Responsable del punto de venta" required="" v-model="responsablePuntoVenta">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                          <label for="zonaPuntoVenta">Zona</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="zonaPuntoVenta" class="form-control"
                                    placeholder="Zona del punto de venta" required="" v-model="zonaPuntoVenta">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                          <label for="emailPuntoVenta">Email</label>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="email" id="emailPuntoVenta" class="form-control"
                                    placeholder="Email del punto de venta" required="" v-model="emailPuntoVenta">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-12">
                  <label for="ciudadPuntoVenta" v-if="ciudadPuntoVenta != null">Ciudad</label>
                          <div class="form-group" v-if="ciudadPuntoVenta != null">
                            <div class="form-line">
                                <input type="text" id="ciudadPuntoVenta" class="form-control"
                                    placeholder="Ciudad del punto de venta" :value="ciudadPuntoVenta">
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id="searchCiudades" class="form-control"
                                    placeholder="Buscar ciudades..." v-model="keyCiudades" @keyup.enter="searchCiudades">
                            </div>
                        </div>
                        <div class="form-group" v-if="(ciudades && ciudades.length > 0)">
                        <v-select
                          :items="ciudades"
                           item-text="descripcion"
                           item-value="id"
                           v-model="ciudad"
                          label="Seleccione una ciudad"
                        ></v-select>
                    </div>
                </div>
                </div>
                <div class="modal-footer">
                    <button type="button" @click="savePuntoVenta" class="btn btn-info waves-effect">Guardar</button>
                    <button type="button" class="btn btn-danger waves-effect"
                        data-dismiss="modal">Cancelar</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</v-app>
	</div>
</template>
<script>

//import {adminApp} from '../assets/assets/js/admin.js';

	export default {
        beforeCreate(){
           this.$store.dispatch('puntoventa/listPuntosVenta', 1);
        },
		created(){
           document.title = 'Puntos de venta - TicketShop';
        },
        async mounted(){
           //adminApp() 
      	   this.$store.dispatch('puntoventa/listPuntosVenta', 1);
        },
        data(){
        	return {
        		titlemodalPuntoVenta: null,
        		namePuntoVenta: null,
                telefonoPuntoVenta: null,
                direccionPuntoVenta: null,
                tipoIdentificacionPuntoVenta: false,
                identificacionPuntoVenta: null,
                responsablePuntoVenta: null,
                zonaPuntoVenta: null,
                emailPuntoVenta: null,
                ciudadPuntoVenta: null,
                ciudad: null,
                keyCiudades: null,
        		search: '',
        		headers: [
		          {
		            text: 'Id',
		            align: 'left',
		            sortable: false,
		            value: 'id'
		          },
		          {text: 'Nombre razon', value: 'nombre_razon',align: 'center' },
                  {text: 'Identificacion', value: 'identificacion',align: 'center' },
                  {text: 'Tipo Identificacion', value: 'tipo_identificacion',align: 'center' },
                  {text: 'Telefono', value: 'telefono',align: 'center' },
                  {text: 'Direccion', value: 'direccion',align: 'center' },
                  {text: 'Responsable', value: 'responsable',align: 'center' },
                  {text: 'Zona', value: 'zona',align: 'center' },
                  {text: 'Email', value: 'email',align: 'center' },
                  {text: 'Ciudad', value: 'ciudades.descripcion',align: 'center' },
		          {text: 'Acciones',value: '', align: 'right'}
       		 ],
       		 json_fields: {
            'Id': 'id',
            'Nombre razon': 'nombre_razon',
            'Identificacion': 'identificacion',
            'Tipo Identificacion': 'tipo_identificacion',
            'Telefono': 'telefono',
            'Direccion': 'direccion',
            'Responsable': 'responsable',
            'Zona': 'zona',
            'Email': 'email',
            'Ciudad': 'ciudades.descripcion',
        },
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        	}
        },
        watch: {
		    pagination: {
		      async handler () {
      	   		this.$store.dispatch('puntoventa/listPuntosVenta', this.pagination.page);
		      },
		      deep: true
		    }
		  },
        computed: {
        	puntosVenta(){
        		return this.$store.getters['puntoventa/listPuntosVenta'];
        	},
        	puntoVenta(){
        		return this.$store.getters['puntoventa/puntoVenta'];
        	},
		    pagination(){
        		return this.$store.getters['puntoventa/pagination'];
		      },
		     pages () {
		        return this.$store.getters['puntoventa/pages'];
		      },
		      totalItemCount(){
		      	return this.$store.getters['puntoventa/totalItemCount'];
		      },
		      loading(){
		      		return this.$store.getters['puntoventa/loading'];
		      },
              ciudades(){
                return this.$store.getters['ciudad/listCiudades'];
              },
        },
        methods: {
        	deletePuntoVenta(id){
        		var self = this;
        		    swal({
			        title: "¿Estas seguro de eliminar el punto de venta?",
			        text: "El punto de venta se eliminara permanentemente!",
			        type: "warning",
			        showCancelButton: true,
			        confirmButtonColor: "#DD6B55",
			        confirmButtonText: "Si, Eliminarlo!",
			        cancelButtonText: "No, cancelar!",
			        closeOnConfirm: false,
			        closeOnCancel: false
			    }, function (isConfirm) {
			        if (isConfirm) {
			            self.$store.dispatch('puntoventa/deletePuntoVenta',id);
			        } else {
			            swal("Cancelado", "Se ha cancelado la operacion.", "error");
			        }
    			});
        	},
        	savePuntoVenta(){
        		if(this.namePuntoVenta != '' || this.namePuntoVenta != null &&
                    this.identificacionPuntoVenta != '' || this.identificacionPuntoVenta != null &&
                    this.tipoIdentificacionPuntoVenta != '' || this.tipoIdentificacionPuntoVenta != null &&
                    this.telefonoPuntoVenta != '' || this.telefonoPuntoVenta != null &&
                    this.direccionPuntoVenta != '' || this.direccionPuntoVenta != null &&
                    this.responsablePuntoVenta != '' || this.responsablePuntoVenta != null &&
                    this.zonaPuntoVenta != '' || this.zonaPuntoVenta != null &&
                    this.emailPuntoVenta != '' || this.emailPuntoVenta != null &&
                    ((this.ciudad != null || this.ciudadPuntoVenta != null) && 
                        (this.ciudad != '' || this.ciudadPuntoVenta != ''))){ 
        		if(this.puntoVenta){
                    var ciudadUpdate = null;
                    if(this.ciudad != null && this.ciudad != ''){
                        ciudadUpdate = this.ciudad;
                    }else{
                        ciudadUpdate = this.puntoVenta.id_ciudad;
                    }
        			this.$store.dispatch('puntoventa/updatePuntoVenta',{id: this.puntoVenta.id,namePuntoVenta: this.namePuntoVenta,tipo_identificacion: this.tipoIdentificacionPuntoVenta, identificacion: this.identificacionPuntoVenta, telefono: this.telefonoPuntoVenta, direccion: this.direccionPuntoVenta,
                        responsable: this.responsablePuntoVenta,zona: this.zonaPuntoVenta,email: this.emailPuntoVenta,
                        id_ciudad: ciudadUpdate})
        			//console.log('estamos editando un punto de venta');
        			$('#modalPuntoVenta').modal('hide');
        		}else{	
        			this.$store.dispatch('puntoventa/newPuntoVenta',{namePuntoVenta: this.namePuntoVenta,tipo_identificacion: this.tipoIdentificacionPuntoVenta, identificacion: this.identificacionPuntoVenta, telefono: this.telefonoPuntoVenta, direccion: this.direccionPuntoVenta,responsable: this.responsablePuntoVenta,zona: this.zonaPuntoVenta,email: this.emailPuntoVenta,id_ciudad: this.ciudad});
        			//console.log('estamos guardando un punto de venta');
        			$('#modalPuntoVenta').modal('hide');
        		}
        	}
        		
        	},
        	viewPuntoVenta(id){
        	    this.$store.commit('puntoventa/viewPuntoVenta',id);
        	    this.namePuntoVenta = this.puntoVenta.nombre_razon;
                this.identificacionPuntoVenta = this.puntoVenta.identificacion;
                this.tipoIdentificacionPuntoVenta = this.puntoVenta.tipo_identificacion;
                this.telefonoPuntoVenta = this.puntoVenta.telefono;
                this.direccionPuntoVenta = this.puntoVenta.direccion;
                this.responsablePuntoVenta = this.puntoVenta.responsable;
                this.zonaPuntoVenta = this.puntoVenta.zona;
                this.emailPuntoVenta = this.puntoVenta.email;
                this.ciudadPuntoVenta = this.puntoVenta.ciudades.descripcion;
                this.ciudad = null;
                this.keyCiudades = null;
        	    this.titlemodalPuntoVenta = `Editar Punto Venta: ${this.puntoVenta.nombre_razon}`;
        	},
        	setNullPuntoVenta(){
        		this.$store.commit('puntoventa/viewPuntoVenta',null);
        		this.namePuntoVenta = null;
                this.identificacionPuntoVenta = null;
                this.tipoIdentificacionPuntoVenta = false;
                this.telefonoPuntoVenta = null;
                this.direccionPuntoVenta = null;
                this.responsablePuntoVenta = null;
                this.zonaPuntoVenta = null;
                this.emailPuntoVenta = null;
                this.ciudadPuntoVenta = null;
                this.ciudad = null;
                this.keyCiudades = null;
        		this.titlemodalPuntoVenta = 'Nuevo Punto Venta';
        	},
	         changePage(page){
    		  this.$store.commit('puntoventa/changePage',page)
	          this.$store.dispatch('puntoventa/listPuntosVenta',page);
	        },
             searchCiudades(){
                this.$store.dispatch('ciudad/searchCiudades', this.keyCiudades);
            },
        }
	}
</script>
<style scoped>
.changes{
	margin-right: 5px;
    margin-bottom: 12px;
}
</style>