import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       tiposCosto: [],
       tipocosto: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listTiposCosto: (state,getters,rootState) => {
          return state.tiposCosto;
    },
    tipocosto: (state,getters,rootState) => {
        return state.tipocosto;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listTiposCosto: (state,tiposCosto) => {
          state.tiposCosto = tiposCosto.data;
          state.pagination.totalItems = tiposCosto.total;
          state.pagination.page = tiposCosto.current_page;
          state.pagination.rowsPerPage = tiposCosto.per_page;
       },
      listTiposCostoSearch: (state,tiposCosto) => {
            if(tiposCosto != null){ 
                state.tiposCosto = tiposCosto;
            }else{
              state.tiposCosto = null;
            }
       },
      viewTipoCosto(state,id){
        let auxTipoCosto = state.tiposCosto.filter((item) => item.id == id);
        state.tipocosto = auxTipoCosto[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listTiposCosto({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}tipocosto?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listTiposCosto',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener los tipos de costo!", "error");
        console.error(error);
      });

    },
  newTipoCosto({state,getters,commit,rootState},descripcion){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}tipocosto`,
        {descripcion: descripcion}
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo el tipo de costo correctamente!", "success");
        store.dispatch('tipocosto/listTiposCosto',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear el tipo de costo!", "error");
        console.error(error);
      });
  },
  updateTipoCosto({state,getters,commit,rootState},tipocosto){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}tipocosto/${tipocosto.id}`,
        {descripcion: tipocosto.descripcion},
        {headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo el tipo de costo correctamente!", "success");
        store.dispatch('tipocosto/listTiposCosto',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar el tipo de costo!", "error");
        console.error(error);
      });
  },
  deleteTipoCosto({state,getters,commit,rootState},tipocosto){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}tipocosto/${tipocosto}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino el tipo de costo correctamente!", "success");
        store.dispatch('tipocosto/listTiposCosto',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar el tipo de costo!","error");
        console.log(error);
     });
  },
    searchTiposCosto({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarTipoCosto?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listTiposCostoSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener los tipos de costo!", "error");
        console.error(error);
      });

    },
  },
}