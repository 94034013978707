import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       condiciones: [],
       condicion: null,
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
  },
  getters: {
    listCondiciones: (state,getters,rootState) => {
          return state.condiciones;
    },
    condicion: (state,getters,rootState) => {
        return state.condicion;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
  },
  mutations: {
        listCondiciones: (state,condiciones) => {
          state.condiciones = condiciones.data;
          state.pagination.totalItems = condiciones.total;
          state.pagination.page = condiciones.current_page;
          state.pagination.rowsPerPage = condiciones.per_page;
       },
      listCondicionesSearch: (state,condiciones) => {
            if(condiciones != null){ 
                state.condiciones = condiciones;
            }else{
              state.condiciones = null;
            }
       },
      viewCondicion(state,id){
        let auxCondicion = state.condiciones.filter((item) => item.id == id);
        state.condicion = auxCondicion[0];
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
  },
  actions: {
    listCondiciones({state,getters,commit,rootState},page){
      store.dispatch('validateToken');
      state.loading = true;
      axios.get(`${configApi.apiUrl}condicion?page=${page}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listCondiciones',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener las condiciones!", "error");
        console.error(error);
      });

    },
  newCondicion({state,getters,commit,rootState},descripcion){
      store.dispatch('validateToken');
      axios.post(`${configApi.apiUrl}condicion`,
        {descripcion: descripcion}
        ,{headers: headers})
      .then(response => {
        swal("Exito!", "Se creo la condicion correctamente!", "success");
        store.dispatch('condicion/listCondiciones',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al crear la condicion!", "error");
        console.error(error);
      });
  },
  updateCondicion({state,getters,commit,rootState},condicion){
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}condicion/${condicion.id}`,
        {descripcion: condicion.descripcion},
        {headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo la condicion correctamente!", "success");
        store.dispatch('condicion/listCondiciones',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar la condicion!", "error");
        console.error(error);
      });
  },
  deleteCondicion({state,getters,commit,rootState},condicion){
    store.dispatch('validateToken');
    axios.delete(`${configApi.apiUrl}condicion/${condicion}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino la condicion correctamente!", "success");
        store.dispatch('condicion/listCondiciones',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Error al eliminar la condicion!","error");
        console.log(error);
     });
  },
    searchCondiciones({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarCondicion?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listCondicionesSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener las condiciones!", "error");
        console.error(error);
      });

    },
  },
}